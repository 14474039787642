import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import FileSaver from 'file-saver';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
} from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import DownloadItem from './components/DownloadItem';
import Header from '../../components/Header';
import { fetchDownloadList } from '../../redux/actions';
import ImageView from '../../components/ImageView';

class DownloadPage extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    downloads: PropTypes.array,
  }

  static defaultProps = {
    dispatch: () => {},
    downloads: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      openDownload: false,
      url: null,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchDownloadList());
  }

  onClickItem = (url, name) => {
    FileSaver.saveAs(url, name);
  };

  toView = (url) => {
    const { openDownload } = this.state;
    this.setState({
      openDownload: !openDownload,
      url,
    });
  };

  handleView = () => {
    const { openDownload } = this.state;
    this.setState({ openDownload: !openDownload });
  };

  render() {
    const { downloads } = this.props;
    const { url, openDownload } = this.state;
    return (
      <div className={isMobile ? 'vw-100' : 'maxWidth500px'}>
        <Header title="Mis documentos" />
        {downloads.length > 0 ? (
          <List>
            {downloads.map(d => (
              <DownloadItem
                key={`download${d.title}`}
                item={d}
                toView={this.toView}
                onClick={this.onClickItem}
              />
            ))}
          </List>
        ) : (
          <List>
            <ListItem>
              <ListItemIcon>
                <Warning />
              </ListItemIcon>
              <ListItemText
                primary="Por el momento no cuenta con documentos"
                secondary="Inténtalo más tarde"
              />
            </ListItem>
          </List>
        )}
        <ImageView
          isOpen={openDownload}
          toggle={this.handleView}
          enableDownload
          urlSrc={[url]}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ download }) => {
  const { downloads } = download;
  return {
    downloads,
  };
};

export default connect(mapStateToProps)(DownloadPage);

/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import TabItem from '../TabItem';
import TabItemEdit from '../TabItemEdit';
import ImgThumbnail from '../../../../components/ImgThumbnail';

const Tab1 = ({ userType, profile, editProfile }) => {
  const {
    ownership,
    cf_1130,
    cf_1132,
    phone,
    profesion,
    fecha_nacimiento,
    pais_nacimiento,
    entidad_nacimiento,
    cf_1368,
    cf_1372,
    cf_1370,
    cf_1382,
    cf_1384,
    cf_1386,
    accountname,
    id_representante_legal,
  } = profile;
  switch (userType) {
    case 'Nacional Física':
    case 'Extranjera Física':
      return (
        <div className="p-2 minHe70vh">
          <TabItem title="Nombre" label={ownership} />
          <TabItem title="Apellido Paterno" label={cf_1130} />
          <TabItem title="Apellido Materno" label={cf_1132} />
          <TabItemEdit
            title="Teléfono"
            value={phone}
            inputType="phone"
            field="phone"
            onSubmit={editProfile}
          />
          <TabItem title="Ocupación o profesión" label={profesion} />
          <TabItem title="Fecha de nacimiento" label={fecha_nacimiento} />
          <TabItem title="País de nacimiento" label={pais_nacimiento} />
          <TabItem title="Entidad federativa de nacimiento" label={entidad_nacimiento} />
          <TabItem title="Lugar de nacimiento" label={cf_1368} />
          <TabItem title="Nacionalidad" label={cf_1372} />
          <TabItem title="Género" label={cf_1370} />
          <TabItem title="Estado civil" label={cf_1382} />
          <TabItem title="Régimen conyugal" label={cf_1384} />
          <TabItem title="Conyuge" label={cf_1386} />
        </div>
      );
    case 'Nacional Moral':
      return (
        <div className="p-2">
          <TabItem title="Nombre del representante" label={accountname} />
          <TabItem title="Apellido Paterno" label={cf_1130} />
          <TabItem title="Apellido Materno" label={cf_1132} />
          <div className="border-bottom py-1">
            <div className="font-gray font-size-12">Identificacion oficial</div>
            <div className="font-size-16 font-weight-600 pl-1">
              <ImgThumbnail
                imgSrc={id_representante_legal}
                title="Identificación oficial"
                desc="Identificación unica de la persona en cuestión"
                emptyPrimary="Identificación no disponible"
                emptySecondary="Puede que la identificación no haya sido subida aún al sistema, si es así favor de completar su registro."
                enableDownload
              />
            </div>
          </div>
        </div>
      );
    default:
      return null;
  }
};

Tab1.propTypes = {
  profile: PropTypes.object,
  userType: PropTypes.string,
  editProfile: PropTypes.func,
};

Tab1.defaultProps = {
  profile: {},
  userType: '',
  editProfile: () => {},
};

export default Tab1;

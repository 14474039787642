import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Collapse,
  LinearProgress,
} from '@material-ui/core';
import { Fade } from 'react-reveal';
import { toolsHelper } from '../../helpers';
/* Actions */
import {
  fetchInvestmentList,
  fetchOpportunityList,
  fetchParticipationList,
  dispatchInvestmentDetail,
} from '../../redux/actions';
/* Components */
import InfoCard from './components/InforCard';
import ShotcutButton from './components/ShotcutButton';
import ScrollTopButton from '../../components/ScrollTopButton';

const HomePage = ({
  abiertas,
  dispatch,
  history,
  loading,
  participations,
  opportunities,
}) => {
  const initPage = async () => {
    dispatch(fetchInvestmentList());
    dispatch(fetchParticipationList());
    dispatch(fetchOpportunityList());
  };

  React.useEffect(() => {
    initPage();
    // eslint-disable-next-line
  }, []);

  const onClickItem = async (id, type, item) => {
    if (type === 'investment') await dispatch(dispatchInvestmentDetail(item));
    await setTimeout(() => history.push(`${type}/${id}`), 250);
  };

  return (
    <div className="w-100 home">
      {/* Relevant Section */}
     {/*  {!loading && (
        <section className="home_relevant">
          <ShotcutButton
            title="Oportunidades"
            icon="piggy-bank"
            delay={50}
            link="/app/opportunity-investment"
            history={history}
          />
          <ShotcutButton
            title="Apartadas"
            icon="pen-alt"
            link="/app/apartadas"
            history={history}
          />
          <ShotcutButton
            delay={100}
            title="Inversiones"
            icon="file-invoice-dollar"
            link="/app/investment"
            history={history}
          />
        </section>
      )} */}
      {/* Apartadas e Inversiones */}
      <div className="home_info_container">
        <Collapse in={loading}>
          <LinearProgress />
        </Collapse>
        <Collapse
          className="w-100"
          in={!loading && (participations.length > 0 || abiertas.length > 0 || opportunities.length > 0)}
        >
          <section className="home_info">
            {abiertas.map(item => (
              <InfoCard
                body={item.paid === 0
                  ? (`$ ${toolsHelper.formatMoney(item.purchased)}`)
                  : (`$ ${toolsHelper.formatMoney(item.paid)}`)}
                chip="Inversión"
                color="primary"
                date={item.proyecto.sales_end_date}
                id={item.proyecto.crmid}
                type="investment"
                item={item}
                img={(item.proyecto.images && item.proyecto.images[0] ? item.proyecto.images[0] : '')}
                key={`key_card_inversion_${item.productid}`}
                select={onClickItem}
                subTitle={item.paid === 0 ? ('Por pagar: ') : ('Pagado: ')}
                title={item.proyecto.productname.replace(/[0-9]/g, '')}
              />
            ))}
            {participations.map(item => (
              <InfoCard
                body={item.estatus}
                chip="Apartada"
                color="secondary"
                date={item.proyecto.sales_end_date}
                id={item.proyecto.crmid}
                type="apartadas"
                item={item}
                img={(item.proyecto.images && item.proyecto.images[0] ? item.proyecto.images[0] : '')}
                key={`key_card_participacion_${item.productid}`}
                select={onClickItem}
                subTitle="Actual:"
                title={item.proyecto.productname.replace(/[0-9]/g, '')}
              />
            ))}
            {!loading && (participations.length === 0 && abiertas.length === 0) && (
              <div className="w-100 h-auto">
                {opportunities.map(item => (
                  <InfoCard
                    body={item.cf_1220}
                    chip="Oportunidad"
                    color="secondary"
                    date={item.sales_end_date}
                    id={item.crmid}
                    type="opportunity-investment"
                    item={item}
                    img={(item.images && item.images[0] ? item.images[0] : '')}
                    key={`key_card_oportunidad_${item.crmid}`}
                    select={onClickItem}
                    subTitle="Actual:"
                    title={item.productname}
                  />
                ))}
              </div>
            )}
          </section>
        </Collapse>
        {!loading && participations.length === 0 && (
          <Fade delay={1500}>
            <div className="home_empty">
              Por el momento no cuentas con Oportunidades de Inversión Apartadas, una vez apartadas aparecerán aquí.
            </div>
          </Fade>
        )}
        {!loading && abiertas.length === 0 && (
          <Fade delay={1500}>
            <div className="home_empty">
              Una vez realizada una inversión, aparecerá aquí.
            </div>
          </Fade>
        )}
      </div>
      {/* Shortcuts Section */}
      <ScrollTopButton />
    </div>
  );
};

HomePage.propTypes = {
  abiertas: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  opportunities: PropTypes.array.isRequired,
  participations: PropTypes.array.isRequired,
};

HomePage.defaultProps = {};

const mapStateToProps = ({
  loads,
  investment,
  participation,
  opportunity,
}) => {
  const { loading } = loads;
  const { participations } = participation;
  const { abiertas } = investment;
  const { opportunities } = opportunity;
  return {
    loading,
    abiertas,
    participations,
    opportunities,
  };
};
export default connect(mapStateToProps)(HomePage);

/* eslint-disable camelcase */
/* eslint-disable no-case-declarations */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import {
  Tab, Tabs, withStyles, AppBar,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import Header from '../../components/Header';
import Tab1 from './components/Tab1/index';
import Tab2 from './components/Tab2/index';
import Tab3 from './components/Tab3';
import Tab5 from './components/Tab5';
import Tab4 from './components/Tab4/index';
import Tab6 from './components/Tab6';
import {
  changeProfilePassword,
  downloadContractAction,
  editProfile,
  fetchProfileDetail,
  getContractContentAction,
  submitContractAction,
  validateStatus,
} from '../../redux/actions';
import WarningRegisterDialog from '../../components/WarningRegisterDialog';

const titles = [
  'Datos Generales',
  'Documentos y datos',
  'Dirección',
  'Cuenta bancaria',
  'Beneficiarios',
  'Preferencias de Usuario',
];
class ProfilePage extends Component {
  static propTypes = {
    access: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    contract_content: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    msg: PropTypes.string,
    profile: PropTypes.object,
    profile_status: PropTypes.string,
    user_id: PropTypes.string,
  };

  static defaultProps = {
    profile: {},
    profile_status: '',
    user_id: '',
    msg: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
  }

  async componentDidMount() {
    const {
      dispatch,
      profile_status,
    } = this.props;
    await dispatch(fetchProfileDetail());
    await dispatch(validateStatus());
    if (profile_status === 'must_sign_contract') {
      await dispatch(getContractContentAction());
    }
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  onDownload = () => {
    const { dispatch } = this.props;
    dispatch(downloadContractAction());
  };

  onSubmitContract = (p) => {
    const { dispatch } = this.props;
    dispatch(submitContractAction(p));
  };

  redirectRegister = () => {
    const { history } = this.props;
    history.push('/sign-up-step');
  };

  changePassword = (data) => {
    const { dispatch } = this.props;
    dispatch(changeProfilePassword(data));
  };

  editProfileAction = (data) => {
    const { dispatch } = this.props;
    dispatch(editProfile(data));
  };

  render() {
    const { value } = this.state;
    const {
      access,
      msg,
      user_id,
      contract_content,
      profile,
      classes,
      loading,
      profile_status,
    } = this.props;
    const { tipo_de_persona } = profile;
    return (
      <div
        className={
          isMobile
            ? 'vw-100 d-flex flex-column'
            : 'maxWidth500px d-flex flex-column'
        }
      >
        <div className="d-flex flex-column p-0 m-0">
          <AppBar position="sticky" className="appBar_Navegation">
            <Tabs
              value={value}
              onChange={this.handleChange}
              variant="fullWidth"
              classes={{
                indicator: classes.indicator,
              }}
              className="position-sticky"
            >
              <Tab
                className="tab"
                style={{ color: value === 0 ? '#662D91' : '#CCCCCC' }}
                icon={<FontAwesomeIcon className="font-size-18" icon="user" />}
              />
              <Tab
                className="tab tab-left-border"
                style={{ color: value === 1 ? '#662D91' : '#CCCCCC' }}
                icon={
                  <FontAwesomeIcon className="font-size-18" icon="receipt" />
                }
              />
              <Tab
                className="tab tab-left-border"
                style={{ color: value === 2 ? '#662D91' : '#CCCCCC' }}
                icon={
                  <FontAwesomeIcon className="font-size-18" icon="map-marker-alt" />
                }
              />
              <Tab
                className="tab tab-left-border"
                style={{ color: value === 3 ? '#662D91' : '#CCCCCC' }}
                icon={
                  <FontAwesomeIcon className="font-size-18" icon="piggy-bank" />
                }
              />
              <Tab
                className="tab tab-left-border"
                style={{ color: value === 4 ? '#662D91' : '#CCCCCC' }}
                icon={(
                  <FontAwesomeIcon
                    className="font-size-18"
                    icon="user-friends"
                  />
                )}
              />
              <Tab
                className="tab tab-left-border"
                style={{ color: value === 5 ? '#662D91' : '#CCCCCC' }}
                icon={(
                  <FontAwesomeIcon
                    className="font-size-18"
                    icon="user-cog"
                  />
                )}
              />
            </Tabs>
          </AppBar>
          <Header title={titles[value]} />
          <SwipeableViews
            axis="x"
            resistance
            index={value}
            animateHeight={value > 0}
            enableMouseEvents
            onChangeIndex={index => this.setState({ value: index })}
          >
            <Tab1
              userType={tipo_de_persona}
              profile={profile}
              editProfile={this.editProfileAction}
            />
            <Tab2 userType={tipo_de_persona} profile={profile} />
            <Tab3 userType={tipo_de_persona} profile={profile} />
            <Tab4
              userType={tipo_de_persona}
              profile={profile}
              editProfile={this.editProfileAction}
            />
            <Tab5 userType={tipo_de_persona} profile={profile} />
            <Tab6 changePassword={this.changePassword} />
          </SwipeableViews>
        </div>
        {!loading && (profile_status !== 'approved') && (
          <WarningRegisterDialog
            msg={msg}
            open={profile_status !== 'approved'}
            user_id={user_id}
            content={contract_content}
            onDownload={this.onDownload}
            access={access}
            profile_status={profile_status}
            onHandleModal={() => {}}
            onSubmitContract={this.onSubmitContract}
            redirectRegister={this.redirectRegister}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = ({
  auth,
  loads,
  profileReducer,
}) => {
  const {
    contract_content,
    user: {
      profile_status,
      token,
      token_type,
      user_id,
    },
  } = auth;
  const { profile, msg } = profileReducer;
  const { loading } = loads;
  return {
    contract_content,
    loading,
    profile,
    profile_status,
    access: {
      token,
      token_type,
    },
    user_id,
    msg,
  };
};
// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  indicator: {
    backgroundColor: '#662D91',
  },
});

export default connect(mapStateToProps)(withStyles(styles)(ProfilePage));

/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader } from 'reactstrap';
import { Button, CircularProgress, Collapse } from '@material-ui/core';

const ChangePassword = ({
  auth,
  logo,
  isLoading,
  onSubmit,
}) => {
  const rePass = React.useRef(null);
  const [password, setPassword] = React.useState('');
  const [rePassword, setRePassword] = React.useState('');
  const [passErr, setPassErr] = React.useState(false);
  const tokenIsEmpty = Boolean(auth.reset_password_key === '');
  const handleChange = (e) => {
    const { value, name } = e.target;
    if (name === 'password') {
      setPassword(value);
    } else if (name === 're-password') {
      setRePassword(value);
    }
  };
  const submit = () => {
    if (password.length > 7 && password === rePassword) {
      onSubmit(password);
    } else {
      setPassErr(true);
    }
  };
  return (
    <Modal isOpen>
      <ModalHeader className="modal-box-header bg-purple text-white text-center">
        <div className="w-75 m-auto">
          <img src={logo} alt="" />
        </div>
      </ModalHeader>
      <div className="modal-box-content">
        <div className="w-100 h-auto">
          <Collapse in={isLoading}>
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <CircularProgress color="primary" />
            </div>
          </Collapse>
          <Collapse in={!isLoading}>
            {tokenIsEmpty ? (
              <div className="w-100 h-auto">
                <div className="w-100 text-center font-size-28">
                  Una disculpa ...
                </div>
                <div className="w-100 font-size-16 mt-1">
                  Parece que existen discrepancias para restaurar tu contraseña,
                  esto puede deberse a diversos factores, entre ellos:
                  <div className="w-100 d-flex flex-column mt-1">
                    <div className="w-100 d-flex font-size-16 font-weight-600 mb-1">
                      <div
                        className="rounded-circle bg-dark"
                        style={{
                          width: '8px',
                          height: '8px',
                          marginTop: '9px',
                        }}
                      />
                      <div className="f-1 ml-2">
                        Se ha excedido el número permitido de intentos de
                        restablecer la contraseña.
                      </div>
                    </div>
                  </div>
                  <div className="w-100 d-flex flex-column mt-1">
                    <div className="w-100 d-flex font-size-16 font-weight-600 mb-1">
                      <div
                        className="rounded-circle bg-dark"
                        style={{
                          width: '8px',
                          height: '8px',
                          marginTop: '9px',
                        }}
                      />
                      <div className="f-1 ml-2">
                        Tu link de acceso esta deshabilitado o inactivo.
                      </div>
                    </div>
                  </div>
                  <div className="w-100 mt-2">
                    Mas allá de estos casos, verifica tu conexión a internet y
                    solicita otro correo para volver a establecer tu contraseña,
                    si este fallo persiste contacta a uno de nuestros asesores.
                  </div>
                </div>
                <div className="w-100 mt-2 login_buttons">
                  <div className="login_buttons w-100">
                    <Button
                      href="/sign-in"
                      className="login_submit block-outline"
                      variant="contained"
                    >
                      Ir a login
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-100 h-auto">
                <div className="w-100 text-center font-size-24">
                  Ingresa tu nueva contraseña
                </div>
                <div className="w-100 font-size-16 mt-1">
                  <input
                    className="form-control  form-control-lg"
                    value={password}
                    type="password"
                    name="password"
                    placeholder="Contraseña"
                    onChange={handleChange}
                    onKeyDown={(e) => {
                      const { keyCode } = e;
                      if (keyCode === 13) {
                        if (rePass) rePass.current.focus();
                      }
                    }}
                  />
                </div>
                <div className="form-group w-100 px-1">
                  Nota: La contraseña debe ser de por lo menos 7 caracteres de
                  largo. Para hacerla más segura, utiliza mayúsculas, minúsculas,
                  numeros y simbolos ! “ ? $ % * &
                </div>
                <Collapse in={passErr}>
                  <div className="form-group w-100 px-1 invalid-text">
                    * Contraseña no valida
                  </div>
                </Collapse>
                <Collapse in={password.length > 0} className="w-100">
                  <div className="w-100 font-size-16 mt-1">
                    <input
                      id="re-password"
                      ref={rePass}
                      className="form-control  form-control-lg"
                      value={rePassword}
                      type="password"
                      name="re-password"
                      autoComplete={false}
                      placeholder="Confirma contraseña"
                      onChange={handleChange}
                    />
                  </div>
                </Collapse>
                <Collapse
                  in={password.length > 0
                    && rePassword.length > 0
                    && (password === rePassword)}
                  className="mt-2"
                >
                  <div className="login_buttons w-100">
                    <Button
                      onClick={() => submit()}
                      variant="contained"
                      className="login_submit block-outline"
                    >
                      Restaurar Contraseña
                    </Button>
                  </div>
                </Collapse>
                <Collapse
                  in={password.length > 0
                    && rePassword.length > 0
                    && (password !== rePassword)}
                >
                  <div className="w-100 font-size-16 mt-1 invalid-text">
                    * Contraseña y confirmación deben ser iguales
                  </div>
                </Collapse>
              </div>
            )}
          </Collapse>
        </div>
      </div>
    </Modal>
  );
};

ChangePassword.propTypes = {
  logo: PropTypes.string,
  auth: PropTypes.object,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
};

ChangePassword.defaultProps = {
  logo: `${process.env.PUBLIC_URL}/img/logo-inverspot-white.png`,
  auth: {},
  isLoading: false,
  onSubmit: () => {},
};

export default ChangePassword;

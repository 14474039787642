/* eslint-disable camelcase */
import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { Fab, ButtonBase, Divider } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from 'react-device-detect';

class WarningRegisterDialog extends React.Component {
  state = {
    password: '',
  };

  render() {
    const {
      msg,
      open,
      onHandleModal,
      profile_status,
      content,
      access,
      onDownload,
      onSubmitContract,
      redirectRegister,
      user_id,
    } = this.props;
    const { password } = this.state;
    const logo = `${process.env.PUBLIC_URL}/img/logo-inverspot-white.png`;
    if (profile_status === 'must_sign_contract') {
      return (
        <Modal toggle={onHandleModal} isOpen={open}>
          <ModalHeader className="modal-box-header bg-purple text-white text-center">
            <div className="w-75 m-auto">
              <img src={logo} alt="" />
            </div>
          </ModalHeader>
          <div className="modal-box-content">
            <div className="w-100">
              <div className="row mb-2">
                <div className="col-12 mb-4 text-center font-size-22 font-weight-600">
                  Contrato
                </div>
                <Divider />
                <div className="w-100 position-relative mb-2">
                  <div
                    className="contract-html-container"
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                  <div className="button_container_contract">
                    {isMobile ? (
                      <Fab
                        color="primary"
                        href={`pdf://send?token=${access.token}&type=${access.token_type}&id=contract&url=https://crm.inverspot.mx/api/profile/contract/pdf`}
                      >
                        <FontAwesomeIcon className="font-size-18 text-light" icon="download" />
                      </Fab>
                    ) : (
                      <Fab
                        color="primary"
                        onClick={() => onDownload()}
                      >
                        <FontAwesomeIcon className="font-size-18 text-light" icon="download" />
                      </Fab>
                    )}
                  </div>
                </div>
                <div className="col-12 mb-2 text-center font-size-20 font-weight-600">
                  {user_id}
                </div>
                <div className="col-12 mb-2 text-center font-size-20 font-weight-600">
                  <input
                    className="form-control form-control-lg"
                    type="password"
                    value={password}
                    onChange={e => this.setState({ password: e.target.value })}
                    placeholder="Confirma tu contrasena"
                  />
                </div>
                <div className="col-12 d-flex aling-items-center justify-content-center">
                  <ButtonBase
                    className="w-75 confirm-origin-button mx-auto mt-2"
                    disabled={password.length === 0}
                    onClick={() => onSubmitContract(password)}
                  >
                    <span className="pr-2">Firmar tu contrato</span>
                    <FontAwesomeIcon icon="pen-alt" />
                  </ButtonBase>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    const message = msg !== '' ? String(msg).split(',') : '';
    return (
      <Modal toggle={onHandleModal} isOpen={open}>
        <ModalHeader className="modal-box-header bg-purple text-white text-center">
          <div className="w-75 m-auto">
            <img src={logo} alt="" />
          </div>
        </ModalHeader>
        <div className="modal-box-content">
          <div className="w-100">
            <div className="row mb-2">
              <div className="col-12 mb-4 text-center font-size-22 font-weight-600">
                {profile_status === 'must_fill_profile'
                  ? 'Termina tu registro para apartar tus participaciones'
                  : 'Estamos revisando tu registro.'}
              </div>
              {message !== '' && (
                <div className="w-100 mb-4 text-left font-size-22 font-weight-600">
                  {message.map(x => (
                    <p key={`key_needs_${Math.random()}`}>{x}</p>
                  ))}
                </div>
              )}
              {profile_status === 'verifying' && (
                <div className="col-12 mt-2 mb-4 text-center font-size-16">
                  Cuando tu perfil sea verificado Inverspot te notificara por
                  correo
                </div>
              )}
              {profile_status === 'must_fill_profile' && (
                <div className="col-12 d-flex align-items-center justify-content-center">
                  <ButtonBase
                    onClick={() => redirectRegister()}
                    className="w-75 confirm-origin-button mx-auto mt-2"
                  >
                    Continuar registro
                  </ButtonBase>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default WarningRegisterDialog;

/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import '../scss/app.scss';
// import { isMobile } from 'react-device-detect';
import ScrollToTop from '../components/ScrollToTop';
import LoadComponent from '../components/Load/LoadComponent';
import MainApp from '../views/index';
import LogIn from './LogIn';
import LogOut from './LogOut';
import Register from './Register';
import ResetPassword from './ResetPassword';
import RegisterStep from './Register/RegisterStep';
import NotFound404 from '../views/Errors/NotFound404';
import { setInitUrl } from '../redux/actions/authUserActions';
// This is because tag manager need jquery.
import './loader';
import LogInWeb from './LogInWeb';

// import AppInvitation from '../components/AppInvitation';

library.add(fab, fas);

const RestrictedRoute = ({
  redirect,
  component: Component,
  authUser,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (authUser.token ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/sign-in',
          state: { from: props.location },
        }}
      />
    ))
      }
  />
);

RestrictedRoute.propTypes = {
  redirect: PropTypes.bool.isRequired,
  authUser: PropTypes.object.isRequired,
  component: PropTypes.func.isRequired,
};

const inverspotTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#ff5600',
      light: '#70bbfd',
      dark: '#009cff',
      contrastText: '#fff',
    },
    secondary: {
      main: '#60269E',
      light: '#7f50a0',
      dark: '#8152a0',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiInputBase: {
      root: {
        '&$focused': {
          backgroundColor: '#fff',
        },
      },
    },
  },
});

class App extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    initURL: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    setInitUrl: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    const { initURL, history } = this.props;
    if (initURL === '') {
      const {
        location: { pathname },
      } = history;
      this.props.setInitUrl(pathname);
    }
  }

  componentDidMount() {
    window.addEventListener('load', () => {
      this.setState({ loaded: true });
    });
  }

  render() {
    const { loaded } = this.state;
    const {
      match, location, user, initURL, loading, redirect,
    } = this.props;
    const { pathname } = location;
    if (pathname === '/') {
      if (!user.token) {
        if ( window.location.origin === 'https://inverspot.mx') {
          return <Redirect to="/sign-in-web" />;
        } else {
          return <Redirect to="/sign-in" />;
        }
      }
      if (
        initURL === ''
        || initURL === '/'
        || initURL === '/sign-in'
        || initURL === '/sign-in-web'
        || initURL === '/sign-up'
      ) {
        return <Redirect to="/app/home-page" />;
        // return (isMobile ? (
        //   <Redirect to={`/app/home-page/${user.token}`} />
        // ) : (
        //   <Redirect to="/app/home-page" />
        // ));
      }
      return <Redirect to={initURL} />;
    }
    return (
      <MuiThemeProvider theme={inverspotTheme}>
        <ScrollToTop>
          <>
            {(loading || !loaded) && <LoadComponent />}
            <Switch>
              <RestrictedRoute
                redirect={redirect}
                authUser={user}
                component={MainApp}
                path={`${match.url}app`}
              />
              <Route path="/sign-in" component={LogIn} />
              <Route path="/sign-in-web" component={LogInWeb} />
              <Route path="/sign-out" component={LogOut} />
              <Route path="/sign-up" component={Register} />
              <Route path="/sign-up-step" component={RegisterStep} />
              <Route path="/reset-password/:token" component={ResetPassword} />
              <Route component={NotFound404} />
            </Switch>
            {/* {!isMobile && (
              <AppInvitation />
            )} */}
            <ToastContainer
              position="top-right"
              // autoClose={2500}
              hideProgressBar={false}
              newestOnTop={false}
              rtl={false}
              closeOnClick
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
          </>
        </ScrollToTop>
      </MuiThemeProvider>
    );
  }
}
const mapStateToProps = ({ auth, loads, profileReducer }) => {
  const { user, initURL } = auth;
  const { loading } = loads;
  const { redirect } = profileReducer;
  return {
    user, initURL, loading, redirect,
  };
};
export default connect(
  mapStateToProps,
  { setInitUrl },
)(App);

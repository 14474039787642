import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ title }) => (
  <div className="header-bg font-white font-size-20 font-weight-600 py-2 pl-4">
    {title.toUpperCase()}
  </div>
);

Header.propTypes = {
  title: PropTypes.string,
};

Header.defaultProps = {
  title: '',
};

export default Header;

/* eslint-disable radix */
/* eslint-disable camelcase */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPercentage,
  faDollarSign,
  faCalendarMinus,
} from '@fortawesome/free-solid-svg-icons';
import { Divider } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import HeaderProgress from '../../../components/HeaderProgress';
import { toolsHelper } from '../../../helpers';

const OpportunityInvestmentItem = ({ item, onClick }) => {
  const { cf_1015, unit_price, qtyinstock, cf_1214 } = item;
  const total = Math.round(cf_1015 / unit_price);
  const faltan = Number.parseInt(total - qtyinstock);
  return (
    <>
      <ListItem
        button
        onClick={() => onClick(item.crmid)}
        className="d-flex flex-column"
      >
        <div className="flex-row d-flex w-100">
          <Avatar
            className="size-70"
            style={{ backgroundColor: '#d0d0ca' }}
            imgProps={{
              style: {
                transform: 'scale(1.8)',
              },
            }}
            src={item.images && item.images[0] ? item.images[0] : ''}
          />
          <div className="d-flex justify-content-between f-1">
            <div className="ml-2 position-relative">
              <div className="align-center position-relative">
                <div className="opportunity_title">
                  {item.productname.toUpperCase()}
                </div>
                <div className="d-flex justify-content-between font-size-14">
                  <div className="font-gray mr-2">
                    <FontAwesomeIcon icon={faCalendarMinus} fixedWidth />
                    <span>{`${item.cf_1400} meses`}</span>
                  </div>
                  <div className="font-gray ml-2">
                    <span>{`${item.cf_1402}`}</span>
                    <FontAwesomeIcon icon={faPercentage} fixedWidth />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-end">
              <div className="opportunity_unitprice" style={{ lineHeight: 'normal' }}>
                <FontAwesomeIcon
                  className="font-size-14"
                  icon={faDollarSign}
                  fixedWidth
                />
                <span>{toolsHelper.formatMoney(item.unit_price, 0)}</span>
              </div>
              <div className="font-size-14 font-weight-500" style={{ lineHeight: 'normal' }}>
                <span className="text-secondary">{faltan}</span>
                /
                <span>{total}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row d-flex w-100">
          <HeaderProgress
            total={total}
            faltan={faltan}
            fondeo={Number.parseInt(cf_1015)}
            vendido={Number.parseInt(cf_1214)}
            withPart={false}
            small
          />
        </div>
      </ListItem>
      <Divider />
    </>
  );
};

export default OpportunityInvestmentItem;

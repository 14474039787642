/* eslint-disable radix */
/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Tab,
  Tabs,
  AppBar,
  withStyles,
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from 'react-device-detect';
import TabDetail from '../../../components/Tabs/TabDetails';
import TabFin from '../../../components/Tabs/TabFin';
import TabAdvance from '../../../components/Tabs/TabAdvance';
import TabInvestments from '../../../components/Tabs/TabInvestments';
import TabPaidInvestments from '../../../components/Tabs/TabPaidInvestments';
import TabLiquidations from '../../../components/Tabs/TabLiquidations';
import TabPaidLiquidations from '../../../components/Tabs/TabPaidLiquidations';
import HeaderTop from '../../../components/HeaderTop';
import HeaderOverView from '../../../components/HeaderOverView';
import BottomFloatButton from '../../../components/BottomFloatButton';
import InvestmentDetailDialog from '../../../components/InvestmentDetailDialog';

import {
  requestDownloadPDFInvoice,
} from '../../../redux/actions';

const InvestmentDetail = ({
  access,
  history,
  classes,
  loading,
  dispatch,
  investmentDetail,
}) => {
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [item, setItem] = React.useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onHandleModal = () => {
    setOpen(!open);
  };

  const onClickInvoiceFloating = () => {
    setValue(4);
  };

  const viewInvoiceDetail = (newItem) => {
    setOpen(true);
    setItem(newItem);
  };

  const downloadRequest = (id, url) => {
    dispatch(requestDownloadPDFInvoice(id, url));
  };

  if (Object.keys(investmentDetail).length === 0) {
    return (
      <div className="h-100 d-flex align-items-center justify-content-center">
        {'No contamos con datos de su inversión, intente acceder mas tarde.'}
      </div>
    );
  }
  const {
    orders,
    proyecto,
    purchased,
    balance,
  } = investmentDetail;

  const existLiquidations = Boolean(investmentDetail.orders && orders.length > 0 && orders.some(x => x.setype === 'TreebesROIC'));

  return (
    <div className={isMobile ? 'vw-100 d-flex flex-column pb-4' : 'maxWidth500px d-flex flex-column pb-4'}>
      <HeaderTop
        history={history}
        title={proyecto.productname || ''}
        opportunityDetail={proyecto}
      />
      <HeaderOverView
        image={proyecto.images && proyecto.images[0] ? proyecto.images[0] : ''}
        row1={parseFloat(purchased)}
        row2={proyecto.cf_1402 ? proyecto.cf_1402 : '0'}
        row3={proyecto.cf_1400 ? proyecto.cf_1400 : parseInt(proyecto.cf_985)}
        row4={null}
        row5={null}
        row6={null}
        imageFloatButton={value !== 0}
        imageFloatButtonIcon="file-invoice-dollar"
        onHandleModal={onClickInvoiceFloating}
      />
      <div className="d-flex flex-column p-0 mb-4">
        <AppBar position="sticky" className="appBar_Navegation">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            classes={{
              indicator: classes.indicator,
            }}
          >
            <Tab
              className="tab"
              style={{ color: value === 0 ? '#662D91' : '#CCCCCC' }}
              icon={
                <FontAwesomeIcon className="font-size-18" icon="info-circle" />
              }
            />
            <Tab
              className="tab tab-left-border"
              style={{ color: value === 1 ? '#662D91' : '#CCCCCC' }}
              icon={
                <FontAwesomeIcon className="font-size-18" icon="chart-line" />
              }
            />
            <Tab
              className="tab tab-left-border"
              style={{ color: value === 2 ? '#662D91' : '#CCCCCC' }}
              icon={<FontAwesomeIcon className="font-size-18" icon="hard-hat" />}
            />
            <Tab
              className="tab tab-left-border"
              style={{ color: value === 3 ? '#662D91' : '#CCCCCC' }}
              icon={
                <FontAwesomeIcon className="font-size-18" icon="file-signature" />
              }
            />
            <Tab
              className="tab tab-left-border"
              style={{ color: value === 4 ? '#662D91' : '#CCCCCC' }}
              icon={<FontAwesomeIcon className="font-size-18" icon="receipt" />}
            />
            {existLiquidations && (
              <Tab
                className="tab tab-left-border"
                style={{ color: value === 5 ? '#662D91' : '#CCCCCC' }}
                icon={
                  <FontAwesomeIcon className="font-size-18" icon="piggy-bank" />
                }
              />
            )}
            {existLiquidations && (
              <Tab
                className="tab tab-left-border"
                style={{ color: value === 6 ? '#662D91' : '#CCCCCC' }}
                icon={(
                  <FontAwesomeIcon
                    className="font-size-18"
                    icon="hand-holding-usd"
                  />
                )}
              />
            )}
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis="x"
          resistance
          index={value}
          animateHeight={value > 0}
          enableMouseEvents
          onChangeIndex={index => setValue(index)}
        >
          <TabDetail data={proyecto} />
          <TabFin data={proyecto} />
          <TabAdvance data={proyecto} />
          <TabInvestments
            data={orders.filter(x => x.setype === 'Invoice')}
            viewInvoiceDetail={viewInvoiceDetail}
            access={access}
            downloadRequest={downloadRequest}
          />
          <TabPaidInvestments
            data={orders.filter(x => x.setype === 'Invoice')}
            access={access}
            downloadRequest={downloadRequest}
          />
          {existLiquidations && (
            <TabLiquidations
              data={orders.filter(x => x.setype === 'TreebesROIC')}
              access={access}
              downloadRequest={downloadRequest}
            />
          )}
          {existLiquidations && (
            <TabPaidLiquidations
              data={orders.filter(x => x.setype === 'TreebesROIC')}
              access={access}
              downloadRequest={downloadRequest}
            />
          )}
        </SwipeableViews>
      </div>
      {open && (
        <InvestmentDetailDialog
          open={open}
          data={item}
          onHandleModal={onHandleModal}
        />
      )}
      <BottomFloatButton
        label={balance > 0 ? 'Paga tu Inversión' : 'Ahora puedes descargar tu comprobante de pago'}
        icon="file-invoice-dollar"
        onHandleModal={onClickInvoiceFloating}
        enable={value === 0 && !loading}
      />
    </div>
  );
};

InvestmentDetail.propTypes = {
  investmentDetail: PropTypes.object,
  access: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

InvestmentDetail.defaultProps = {
  investmentDetail: {},
};

const styles = () => ({
  indicator: {
    backgroundColor: '#662D91',
  },
});
const mapStateToProps = ({ investment, auth, loads }) => {
  const { investmentDetail } = investment;
  const { user: { token, token_type } } = auth;
  const { loading } = loads;
  return {
    investmentDetail,
    access: {
      token,
      token_type,
    },
    loading,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(InvestmentDetail));

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, CircularProgress } from '@material-ui/core';
import { Fade } from 'react-reveal';
import OpportunityInvestmentItem from './components/OpportunityInvestmentItem';
import { fetchOpportunityList } from '../../redux/actions';
import Header from '../../components/Header';

class OpportunityInvestmentPage extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    opportunities: PropTypes.array,
  };

  static defaultProps = {
    loading: false,
    opportunities: [],
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchOpportunityList());
  }

  onClickItem = (id) => {
    const { history } = this.props;
    history.push(`opportunity-investment/${id}`);
  };

  render() {
    const { opportunities, loading } = this.props;
    return (
      <>
        <Header title="Proyectos disponibles" />
        {!loading ? (
          <div className="container">
            {opportunities.length > 0 ? (
              <List className="minHe50vh">
                {opportunities.map(item => (
                  <Fade key={`key_opportunities_ ${item.crmid}`} bottom>
                    <OpportunityInvestmentItem
                      item={item}
                      onClick={this.onClickItem}
                    />
                  </Fade>
                ))}
              </List>
            ) : (
              <List className="minHe50vh">
                <div className="p-4 w-100 h-100 d-flex align-items-center justify-content-center">
                  <h4>
                    Una disculpa, por el momento no tenemos oportunidades de
                    inversión disponibles, visitenos mas tarde.
                  </h4>
                </div>
              </List>
            )}
          </div>
        ) : (
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <CircularProgress color="primary" />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ opportunity, loads }) => {
  const { opportunities } = opportunity;
  const { loading } = loads;
  return {
    opportunities,
    loading,
  };
};
export default connect(mapStateToProps)(OpportunityInvestmentPage);

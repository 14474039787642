/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Bounce } from 'react-reveal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonBase } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { toolsHelper } from '../../../helpers';

const InvestmentItem = ({
  access,
  downloadRequest,
  item,
  viewInvoiceDetail,
}) => {
  const {
    crmid,
    pdf,
    payment_status,
    balance,
    paid,
    quantity,
    date,
    total,
  } = item;
  return (
    <Bounce>
      <div className="referral-item flex-row w-100 border-bottom">
        <div
          className="commission-icon-bg border-bottom"
          style={{
            backgroundColor:
              payment_status === 'paid'
                ? '#662D91'
                : payment_status === 'unpaid'
                  ? ''
                  : '#FBB03B',
          }}
        >
          <div className="referral-item-number">
            <FontAwesomeIcon
              className="font-white font-size-24"
              icon={
                payment_status === 'paid'
                  ? 'check'
                  : payment_status === 'unpaid'
                    ? 'circle'
                    : 'clock'
              }
            />
          </div>
        </div>
        <div className="referral-item-number-bg">
          <div className="referral-item-number">{quantity}</div>
        </div>
        <div className="d-flex justify-content-between f-1">
          <div className="ml-2 position-relative">
            <div className="align-center position-relative">
              <div className="font-size-15 font-weight-600">
                {`$${toolsHelper.formatMoney(total)}`}
              </div>
              <div className="font-size-12">{date}</div>
            </div>
          </div>
          <div className="mr-2 position-relative">
            <div className="align-center position-relative">
              <div className="font-size-15 font-red text-right">
                {`$${toolsHelper.formatMoney(parseFloat(paid))}`}
              </div>
              <div className="font-size-12 text-right">
                {`-$ ${toolsHelper.formatMoney(parseFloat(balance) * -1)}`}
              </div>
            </div>
          </div>
        </div>
        <div className={payment_status !== 'paid' ? ('confirm-icon-bg-badge border-bottom') : ('confirm-icon-bg border-bottom')}>
          {payment_status === 'paid' ? (
            <div className="w-100 h-100">
              {isMobile ? (
                <ButtonBase
                  className="w-100 h-100"
                  href={`pdf://send?token=${access.token}&type=${access.token_type}&id=${crmid}&url=${pdf}`}
                >
                  <FontAwesomeIcon
                    className="font-size-24 font_inver_red"
                    icon={
                      payment_status === 'paid' ? 'download' : 'file-invoice-dollar'
                    }
                  />
                </ButtonBase>
              ) : (
                <ButtonBase
                  className="w-100 h-100"
                  onClick={() => downloadRequest(crmid, pdf)}
                >
                  <FontAwesomeIcon
                    className="font-size-24 font_inver_red"
                    icon={
                      payment_status === 'paid' ? 'download' : 'file-invoice-dollar'
                    }
                  />
                </ButtonBase>
              )}
            </div>
          ) : (
            <ButtonBase
              className="w-100 h-100"
              onClick={() => viewInvoiceDetail(item)}
            >
              <FontAwesomeIcon
                className="font-size-24 font_inver_white"
                icon={
                  payment_status === 'paid' ? 'download' : 'file-invoice-dollar'
                }
              />
            </ButtonBase>
          )}
        </div>
      </div>
    </Bounce>
  );
};

InvestmentItem.propTypes = {
  access: PropTypes.object.isRequired,
  downloadRequest: PropTypes.func,
  item: PropTypes.object.isRequired,
  viewInvoiceDetail: PropTypes.func.isRequired,
};

InvestmentItem.defaultProps = {
  downloadRequest: () => {},
};

export default InvestmentItem;

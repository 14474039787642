/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import { loading, loaded } from './loadActions';
import {
  GET_REFERRAL_SUCCESS,
  SET_REFERRAL_SUCCESS,
} from '../actionTypes';
import { unauthenticate } from './authUserActions';
import ReferralAPI from '../../api/ReferralsAPI';
import ProfileAPI from '../../api/ProfileAPI';

export function fetchReferralList() {
  return async (dispatch) => {
    const referralAPI = new ReferralAPI();
    dispatch(loading(''));
    try {
      const response = await referralAPI.getReferrals();
      console.log({ response });
      const { success, message, result } = response.data;
      if (success) {
        dispatch({
          type: GET_REFERRAL_SUCCESS,
          payload: result,
        });
      }
      else {
        toast.error(message);
      }
    }

    catch (e) {
      if (e.response && e.response.status === 401) {
        toast.error('Una disculpa, se necesita iniciar sesión de nuevo');
        dispatch(unauthenticate());
        return;
      }
      toast.error('Error en la API');
    }

    finally {
      dispatch(loaded('authUser'));
    }
  };
}

export function setReferral(referrer_code) {
  return async (dispatch) => {
    const profileApi = new ProfileAPI();
    dispatch(loading(''));
    try {
      const params = { referrer_code };
      await profileApi.setReferrerCode(params).then((response) => {
        const { success, message } = response.data;
        if (success) {
          toast.success(message.success);
          dispatch({ type: SET_REFERRAL_SUCCESS, referrer_code });
        }
        else {
          toast.error(message);
        }
      }).catch((err) => {
        if (err.status === 401) {
          toast.error('Una disculpa, se necesita iniciar sesión de nuevo');
          dispatch(unauthenticate());
          return;
        }
        toast.error(err.data.message.error || err.data.message.warning);
      });
    }
    catch (e) {
      toast.error('Error en la API');
    }
    finally {
      dispatch(loaded('authUser'));
    }
  };
}

/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import TabItem from '../TabItem';
import ImgThumbnail from '../../../../components/ImgThumbnail';

const Tab3 = ({ userType, profile }) => {
  const {
    bill_street,
    bill_num_ext,
    bill_num_int,
    bill_pobox,
    bill_city,
    bill_state,
    bill_country,
    bill_code,
    comprobante_domicilio,
  } = profile;
  return (
    <div className="p-2 minHe70vh">
      <TabItem title="Calle" label={bill_street} />
      <TabItem title="Num. Exterior" label={bill_num_ext} />
      <TabItem title="Num. Interior" label={bill_num_int} />
      <TabItem title="Colonia" label={bill_pobox} />
      <TabItem title="Delegación o Municipio" label={bill_city} />
      <TabItem title="Estado" label={bill_state} />
      <TabItem title="País" label={bill_country} />
      <TabItem title="Código Postal" label={bill_code} />
      <div className="border-bottom pb-1">
        <div className="font-gray font-size-12">
          Comprobante de domicilio Actual
        </div>
        <div className="font-size-16 font-weight-600 pl-1">
          <ImgThumbnail
            imgSrc={comprobante_domicilio}
            title="Comprobante de domicilio Actual"
            desc="Comprobante de domicilio Actual de la persona en cuestión"
            emptyPrimary="Comprobante de domicilio Actual no disponible"
            emptySecondary="Puede que la Comprobante de domicilio Actual no haya sido subida aún al sistema, si es así favor de completar su registro."
            enableDownload
          />
        </div>
      </div>
    </div>
  );
};

Tab3.propTypes = {
  userType: PropTypes.string,
  profile: PropTypes.object,
};

Tab3.defaultProps = {
  userType: '',
  profile: {},
};

export default Tab3;

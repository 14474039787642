import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isIOS, isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AppBar,
  Tab,
  Tabs,
  withStyles,
  Fab,
  ButtonBase,
  Collapse,
} from '@material-ui/core';
import { Share } from '@material-ui/icons';

import { fetchReferralList, setReferral } from '../../redux/actions';
import { Tab2 } from './components/Tab2';
import { Tab1 } from './components/Tab1';
import Header from '../../components/Header';

class ReferralPage extends Component {
  static propTypes = {
    url: PropTypes.string,
    code: PropTypes.string,
    referidos: PropTypes.array,
    comisiones: PropTypes.array,
    classes: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    url: '',
    code: '',
    referidos: [],
    comisiones: [],
    classes: {},
  };

  constructor(props) {
    super(props);
    const { code } = this.props;
    this.state = {
      value: 0,
      cliCode: code,
      err: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchReferralList());
  }

  componentWillReceiveProps(next) {
    const { cliCode } = this.state;
    if (cliCode !== next.code) {
      this.setState({ cliCode: next.code });
    }
  }

  onClickItem = () => {
    console.log('click item');
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  saveReferralCode = () => {
    const { cliCode } = this.state;
    const { code, dispatch } = this.props;
    if (cliCode !== code) {
      if (cliCode.length > 5
        && cliCode.length < 13
        && cliCode.match(/^[A-Za-z0-9.]{6,12}$/)) {
        dispatch(setReferral(cliCode));
      } else {
        this.setState({ err: true });
      }
    }
  }

  handleCode = (e) => {
    const { value } = e.target;
    this.setState({
      cliCode: value,
      err: false,
    });
  }

  render() {
    const { value } = this.state;
    const {
      url, classes, referidos, comisiones, code,
    } = this.props;
    const { cliCode, err } = this.state;
    const text = `Hola, te hago llegar una invitación para participar en propuestas de inversión con Inverspot, mi codigo de referido es: ${cliCode}`;
    return (
      <div className={isMobile ? 'vw-100' : 'maxWidth500px'}>
        <Header title="Mis referidos" />
        <div className="p-3">
          <div className="font-size-14">
            <strong>Refiere a tus amigos, familiares o conocidos a invertir con nosotros y gana $500 MXN por cada uno.</strong>
            {' '}
            <br />
            Importante: Para ser acreedor del bono, tu referido deberá haber concluido su inversión. (No será válido si sólo se registra como usuario)
          </div>
          <div className="w-100 d-flex justify-content-around my-3 px-4">
            {!isIOS && (
            <Fab
              color="secondary"
              href={`mailto:?subject=Inverspot - Invitación&body=${text}`}
            >
              <FontAwesomeIcon
                className="font-size-20"
                icon={['fas', 'envelope']}
              />
            </Fab>
            )}
            {!isIOS && (
            <Fab color="secondary" href={`whatsapp://send?text=${text}`}>
              <FontAwesomeIcon
                className="font-size-20"
                icon={['fab', 'whatsapp']}
              />
            </Fab>
            )}
            <Fab color="secondary" href={`shareWith://send?text=${text}`}>
              <Share />
            </Fab>
          </div>

          <div className="font-size-20 font-weight-600 text-center">
            Tu código de Referido
          </div>
          <div className="w-100 d-flex justify-content-center my-1">
            <div className="w-100 d-flex justify-content-between px-2">
              <input
                id="input_cli"
                value={cliCode || ''}
                onChange={this.handleCode}
                pattern="^[A-Za-z0-9\.]{6,12}$"
                className="form-control form-control w-90"
              />
              {code !== cliCode && (
                <ButtonBase
                  onClick={() => this.saveReferralCode()}
                  style={{
                    width: '20vw',
                  }}
                >
                  <FontAwesomeIcon
                    className="font-size-20 font-gray"
                    icon="save"
                  />
                </ButtonBase>
              )}
              <ButtonBase
                href={`clipboard://send?text=${cliCode}`}
                style={{
                  width: '20vw',
                }}
              >
                <FontAwesomeIcon
                  className="font-size-20 font-gray"
                  icon="copy"
                />
              </ButtonBase>
            </div>
          </div>
          <Collapse in={err}>
            <span className="w-100 font-size-10">
            No cumple con el formato, deben ser 6 a 12 carácteres, iniciando con 2 letras mayusculas ex. CL0000.
            </span>
          </Collapse>
          <div className="font-size-20 font-weight-600 text-center">
            Comparte el enlace de Inverspot.mx
          </div>
          <div className="w-100 d-flex justify-content-center mb-2">
            <div className="w-100 following-link mx-2 d-flex  px-2">
              <div className="f-1 position-relative">
                <div
                  className="following-link-text overflow-hidden text-nowrap"
                  style={{ width: '90%' }}
                >
                  {url}
                </div>
              </div>
              <div className="h-100 position-relative mr-2">
                <div className="align-center position-absolute">
                  <ButtonBase
                    href={`clipboard://send?text=${url}`}
                    style={{
                      width: '16vw',
                    }}
                  >
                    <FontAwesomeIcon
                      className="font-size-20 font-gray"
                      icon="copy"
                    />
                  </ButtonBase>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column p-0 mb-4">
          <AppBar position="sticky" className="appBar_Navegation">
            <Tabs
              value={value}
              onChange={this.handleChange}
              variant="fullWidth"
              classes={{
                indicator: classes.indicator,
              }}
            >
              <Tab
                className="tab f-1"
                style={{ color: value === 0 ? '#662D91' : '#CCCCCC' }}
                icon={(
                  <FontAwesomeIcon
                    className="font-size-18"
                    icon="user-friends"
                  />
                )}
                label="Mis referidos"
              />
              <Tab
                className="tab f-1 tab-left-border"
                style={{ color: value === 1 ? '#662D91' : '#CCCCCC' }}
                icon={(
                  <FontAwesomeIcon
                    className="font-size-18"
                    icon="hand-holding-usd"
                  />
                )}
                label="Mis comisiones"
              />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis="x"
            resistance
            index={value}
            animateHeight={value > 0}
            enableMouseEvents
            onChangeIndex={index => this.setState({ value: index })}
          >
            <Tab1 data={referidos} />
            <Tab2 data={comisiones} />
          </SwipeableViews>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ referral }) => {
  const {
    comisiones, referidos, url, code,
  } = referral;
  return {
    comisiones,
    referidos,
    url,
    code,
  };
};
const styles = () => ({
  indicator: {
    backgroundColor: '#662D91',
  },
});

export default connect(mapStateToProps)(withStyles(styles)(ReferralPage));

/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Fab,
  Zoom,
  ButtonBase,
} from '@material-ui/core';
import { CloudDownload, Close } from '@material-ui/icons';
import { Modal } from 'reactstrap';

import ImageCarousel from '../ImageCarrousel';

const ImageView = ({
  urlSrc,
  isOpen,
  toggle,
  desc,
  enableDownload,
  title,
}) => {
  const link = (`image://send?text=${urlSrc}`);
  console.log('urlSrc: ', urlSrc);
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="imageview_modal" id="imageView_modal">
      <div className="imageview_desc">
        <div className="imageview_header">
          <div className="imageview_header_card">
            <ButtonBase
              onClick={() => toggle()}
            >
              <Close />
            </ButtonBase>
            <span className="ml-2">{title}</span>
          </div>
        </div>
        {urlSrc && urlSrc.length === 1 && (
          <img src={urlSrc[0]} alt="download-image" className="imageview_img" />
        )}
        {urlSrc && urlSrc.length > 1 && (
          <ImageCarousel images={urlSrc} />
        )}
        <div className="imageview_desc_cont">
          {desc !== '' && (
          <div className="imageview_desc_cont_card">
            {desc}
          </div>
          )}
        </div>
      </div>
      <Zoom in={enableDownload}>
        <Fab
          color="primary"
          className="imageview_download"
          href={link}
        >
          <CloudDownload />
        </Fab>
      </Zoom>
    </Modal>
  );
};

ImageView.propTypes = {
  urlSrc: PropTypes.array,
  desc: PropTypes.string,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  enableDownload: PropTypes.bool,
};

ImageView.defaultProps = {
  urlSrc:
    ['https://allstore1.zendesk.com/hc/es/article_attachments/205164909/1.jpg'],
  isOpen: false,
  toggle: () => {},
  enableDownload: false,
  desc: '',
  title: 'Visualizar imagen ...',
};

export default ImageView;

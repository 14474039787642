/* eslint-disable radix */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Fade } from 'react-reveal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Badge,
  ListItem,
} from '@material-ui/core';
import { toolsHelper } from '../../../helpers';


const InvestmentItem = ({ item, onClick }) => {
  const { cf_1015, unit_price, qtyinstock } = item.proyecto;
  const total = Math.round(cf_1015 / unit_price);
  const faltan = Number.parseInt(total - qtyinstock);
  const qty = parseInt(item.cantidad);
  return (
    <Fade>
      <ListItem button onClick={() => onClick(item.productid, item)}>
        <div className="flex-row d-flex w-100">
          <Badge
            color="primary"
            badgeContent={qty}
          >
            <Avatar
              className="size-70"
              style={{ backgroundColor: '#d0d0ca' }}
              src={
              item.proyecto.images && item.proyecto.images[0]
                ? item.proyecto.images[0]
                : ''
            }
            />
          </Badge>
          <div className="d-flex justify-content-between f-1">
            <div className="ml-2 position-relative w-70">
              <div className="align-center position-relative">
                <div className="opportunity_title">
                  {item.productname.toUpperCase()}
                </div>
                <div className="d-flex justify-content-between pr-4 font-size-14">
                  <div className="font-gray w-100">
                    <FontAwesomeIcon icon="calendar-minus" fixedWidth />
                    <span>{item.proyecto.cf_1400 || parseInt(item.proyecto.cf_985)}</span>
                  </div>
                  {item.proyecto.cf_1402 !== '' && (
                    <div className="font-gray w-100 d-flex justify-content-end align-items-center border-left">
                      <span>{item.proyecto.cf_1402}</span>
                      <FontAwesomeIcon icon="percentage" fixedWidth />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-end">
              <div className="opportunity_unitprice d-flex align-items-baseline">
                <FontAwesomeIcon className="font-size-14" icon="dollar-sign" fixedWidth />
                <span>{toolsHelper.formatMoney(item.purchased, 0)}</span>
              </div>
              <div className="font-size-14 font-weight-500">
                <span className="text-secondary">{faltan}</span>
                  /
                <span>{total}</span>
              </div>
            </div>
          </div>
        </div>
      </ListItem>
    </Fade>
  );
};

InvestmentItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default InvestmentItem;

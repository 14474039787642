import React from 'react';
import PropTypes from 'prop-types';
import { ButtonBase } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Zoom } from 'react-reveal';

const ShotcutButton = ({
  link,
  icon,
  title,
  delay,
  history,
}) => (
  <Zoom
    top
    delay={delay}
  >
    <div className="home_short">
      <ButtonBase
        className="home_short_btn"
        onClick={() => {
          setTimeout(() => {
            history.push(link);
          }, 250);
        }}
      >
        <FontAwesomeIcon icon={icon} />
      </ButtonBase>
      <div className="home_short_ttl">
        {title}
      </div>
    </div>
  </Zoom>
);

ShotcutButton.propTypes = {
  delay: PropTypes.number,
  link: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

ShotcutButton.defaultProps = {
  delay: 0,
};

export default ShotcutButton;

/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import TimelineItem from './TimelineItem';

import ImageView from '../ImageView';

const Timeline = ({ timelineData }) => {
  const [open, setOpen] = React.useState(false);
  const [desc, setDesc] = React.useState('');
  const [image, setImages] = React.useState([]);
  const onClickItem = (label, description, images) => {
    setDesc(`${label} - ${description}`);
    let arr = [];
    if (images.length > 0) arr = images.map(x => x.url);
    setImages(arr);
    setOpen(!open);
  };

  return (
    <div className="timeline-container">
      {timelineData.map((data, idx) => (
        <TimelineItem data={data} key={idx} onClick={onClickItem} />
      ))}
      <ImageView
        isOpen={open}
        toggle={() => setOpen(!open)}
        desc={desc}
        urlSrc={image}
      />
    </div>
  );
};

Timeline.propTypes = {
  timelineData: PropTypes.array,
};

Timeline.defaultProps = {
  timelineData: [],
};

export default Timeline;

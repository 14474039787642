import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Fade } from 'react-reveal';
import { LinearProgress, List } from '@material-ui/core';
import {
  fetchFondeosList,
} from '../../redux/actions';

import Header from '../../components/Header';
import FondeoItem from './components/FondeoItem';

const FondeadosPage = ({
  fondeos,
  loading,
  dispatch,
  history,
}) => {
  React.useEffect(() => {
    dispatch(fetchFondeosList());
    // eslint-disable-next-line
  }, []);

  const onClickItem = id => history.push(`fondeos/${id}`);

  return (
    <>
      <Header title="Proyectos Fondeados" />
      {loading ? (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <LinearProgress color="primary" />
        </div>
      ) : (
        <div className="container">
          {fondeos.length > 0 ? (
            <List className="minHe50vh">
              {fondeos.map(item => (
                <Fade key={`key_fondeos_ ${item.crmid}`} bottom>
                  <FondeoItem
                    item={item}
                    onClick={onClickItem}
                  />
                </Fade>
              ))}
            </List>
          ) : (
            <List className="minHe50vh">
              <div className="p-4 w-100 h-100 d-flex align-items-center justify-content-center">
                <h4>
                  Una disculpa, por el momento no tenemos proyectos fondeados para mostrar,
                  visitenos mas tarde.
                </h4>
              </div>
            </List>
          )}
        </div>
      )}
    </>
  );
};

FondeadosPage.propTypes = {
  fondeos: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = ({ opportunity, loads }) => {
  const { fondeos } = opportunity;
  const { loading } = loads;
  return {
    fondeos,
    loading,
  };
};

export default connect(mapStateToProps)(FondeadosPage);

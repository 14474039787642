import React from 'react';
import PropTypes from 'prop-types';
import ConfirmInvestmentItem from './components/ConfirmInvestmentItem';

const TabParticipationsDone = ({
  data,
  access,
  downloadRequest,
}) => (
  <div className="minHe50vh">
    <div className="w-100 font-size-24 font-weight-600 text-center">
      {'Participaciones Apartadas'}
    </div>
    <div className="border_bottom_purple" />
    {data.map(item => (
      <ConfirmInvestmentItem
        key={item.invoiceid}
        item={item}
        access={access}
        downloadRequest={downloadRequest}
      />
    ))}
  </div>
);

TabParticipationsDone.propTypes = {
  data: PropTypes.object.isRequired,
  access: PropTypes.object.isRequired,
  downloadRequest: PropTypes.func.isRequired,
};

export default TabParticipationsDone;

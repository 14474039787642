/* eslint-disable camelcase */
/* eslint-disable object-curly-newline */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './login.scss'
import {
  Button,
  Checkbox,
  FormControl,
  Grow,
  ButtonBase,
} from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import {
  authenticate,
  requestPasswordReset,
} from '../../redux/actions';
import ResetPassModal from '../../components/ResetPassModal';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class LogIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      username_error: false,
      password_error: false,
      isRemember: false,
      resetPass: false,
      open: true,
    };
  }

  componentDidUpdate() {
    if (this.props.user.token !== null && this.props.user.token !== undefined) {
      this.props.history.push('/');
    }
  }

  handleChangeCheck = name => (event, checked) => {
    this.setState({ [name]: checked });
  };

  handleChange = name => (event) => {
    const name_error = `${name}_error`;
    this.setState({
      [name]: event.target.value,
      [name_error]: event.target.value === '',
    });
  };

  toggleResetPassModal = () => {
    const { resetPass } = this.state;
    this.setState({ resetPass: !resetPass });
  }

  onRequestPassword = (email) => {
    const { dispatch } = this.props;
    if (email !== '') dispatch(requestPasswordReset(email));
    this.setState({ resetPass: false });
  }

  onSubmit = () => {
    const { username, password, isRemember } = this.state;
    if (username === '' || password === '') {
      this.setState({ username_error: username === '' });
      this.setState({ password_error: password === '' });
      return;
    }
    const token = btoa(`${username}:${password}`);
    this.props.dispatch(authenticate(token, isRemember, username));
  };

  handleClickOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  render() {
    const {
      username,
      password,
      isRemember,
      resetPass,
      username_error,
      password_error,
      open,
    } = this.state;
    const { location } = this.props;
    const logo = `${process.env.PUBLIC_URL}/img/logo-inverspot-white.png`;
    return (
      <div className="root-container login-panel-background">
        <div className="account-panel-width align-center position-absolute">
          <form noValidate autoComplete="off">
            <div className="form-group w-75 text-center m-auto">
              <img src={logo} alt="" />
              <small style={{color:'white', fontSize: 14}}>“Si tienes proyectos activos, ingresa aquí”</small>
            </div>
            <div className="form-group w-100 mt-4">
              <input
                className="form-control form-control-lg"
                value={username}
                type="email"
                placeholder="Usuario"
                onChange={this.handleChange('username')}
              />
              <div
                className={`invalid-text ${username_error ? '' : 'invalid-text-invisible'}`}
              >
                {'* Username is required'}
              </div>
            </div>
            <div className="form-group w-100">
              <input
                className="form-control  form-control-lg"
                value={password}
                type="password"
                placeholder="Contraseña"
                onChange={this.handleChange('password')}
                onKeyDown={(e) => {
                  const { keyCode } = e;
                  if (keyCode === 13) {
                    this.onSubmit();
                  }
                }}
              />
              <div
                className={`invalid-text ${
                  password_error ? '' : 'invalid-text-invisible'
                }`}
              >
                {'* Contraseña requerida'}
              </div>

              <div className="w-100 mt-1 d-flex justify-content-end">
                <ButtonBase
                  onClick={() => setTimeout(() => this.toggleResetPassModal(), 250)}
                  className="w-40 py-3 px-1 text-red d-flex justify-content-end block-outline"
                >
                  Olvidé mi Contraseña
                </ButtonBase>
              </div>
            </div>
            <div className="form-group w-100">
              <FormControl
                control={(
                  <Checkbox
                    style={{
                      color: 'white',
                    }}
                    checked={isRemember}
                    onChange={this.handleChangeCheck('isRemember')}
                  />
                )}
                label={<span className="text-white">Recuerdame</span>}
              />
            </div>

            <div className="form-group d-flex flex-column justify-content-center align-items-center">
              <Grow className="w-100 login_buttons" in>
                <div className="login_buttons w-100">
                  <Button
                    onClick={() => this.onSubmit()}
                    variant="contained"
                    className="login_submit block-outline"
                  >
                    Ingresar
                  </Button>
                </div>
              </Grow>
              <div>
              <div className="reader-mobile">
                <p className="text-justify">
                  DESARROLLO COLECTIVO INMOBILIARIO, S.A.P.I. DE C.V. (“Inverspot”) ha decidido transformarse y no continuar con el proceso ante la Comisión Nacional Bancaria y de 
                  Valores (“CNBV”) para organizarse y operar como Institución de Financiamiento Colectivo.
                </p>
                <p className="text-justify">
                  En consecuencia, en estricto cumplimiento de la Ley para Regular las Instituciones de Tecnología Financiera (“LRITF”) hemos detenido la generación de nuevas 
                  operaciones y hemos iniciado exclusivamente todos los actos tendientes a concluir las operaciones existentes que se habían realizado bajo el amparo del artículo 
                  Octavo Transitorio de la LRITF.
                  En caso de tener cualquier duda o comentario, favor enviar un correo electrónico a la dirección hola@inverspot.mx.
                </p>
              </div>
                 {/*  
                  <Link to={`/sign-up${location.search}`} title="Register">
                 <span className="login-register-text">
                    Regístrate
                  </span> 
                </Link>
                  */}
              </div>
            </div>
          </form>
          <ResetPassModal
            open={resetPass}
            toggle={this.toggleResetPassModal}
            submit={this.onRequestPassword}
          />
        </div>
        <Dialog
        open={open}
        onClose={() => this.handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Estimados Usuarios, Clientes y Amigos:"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¡Inverspot se reinventa!<br />

            Como lo hemos demostrado durante los últimos años, en Inverspot estamos comprometidos en continuar fortaleciendo el sector inmobiliario y el ecosistema fintech en México. Por ello, nos es muy grato  anunciarles que hemos decidido transformarnos hacia un modelo disruptivo que aportará gran valor a los diversos sectores de nuestro país, el cual,  será dado a conocer en los próximos días.
            <br />

            La decisión de transformar nuestro modelo de negocio y de no continuar con el proceso de autorización ante la CNBV representa un nuevo desafío y estamos convencidos en que fue la decisión acertada para seguir transformando la realidad del sector inmobiliario y financiero de nuestro país.
            <br />
            En consecuencia, en estricto apego a las leyes de nuestro país y en cumplimiento de lo dispuesto por la Ley para Regular las Instituciones de Tecnología Financiera hemos detenido la generación de nuevas operaciones en nuestra plataforma.
            <br />
            Respecto a las operaciones activas, Inverspot te acompañará en todo momento hasta su conclusión. El correo hola@inverspot.mx está a tu disposición para continuar en contacto.
            <br />
            Agradecemos como siempre su confianza y comprensión; reafirmando nuestro compromiso para hacer de la transformación de Inverspot un cambio que trascienda en nuestro país.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose()} color="primary" autoFocus>
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
      <div className="reader">
        <p className="text-justify">
          DESARROLLO COLECTIVO INMOBILIARIO, S.A.P.I. DE C.V. (“Inverspot”) ha decidido transformarse y no continuar con el proceso ante la Comisión Nacional Bancaria y de 
          Valores (“CNBV”) para organizarse y operar como Institución de Financiamiento Colectivo.
        </p>
        <p className="text-justify">
          En consecuencia, en estricto cumplimiento de la Ley para Regular las Instituciones de Tecnología Financiera (“LRITF”) hemos detenido la generación de nuevas 
          operaciones y hemos iniciado exclusivamente todos los actos tendientes a concluir las operaciones existentes que se habían realizado bajo el amparo del artículo 
          Octavo Transitorio de la LRITF.
          En caso de tener cualquier duda o comentario, favor enviar un correo electrónico a la dirección hola@inverspot.mx.
        </p>
      </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loads: state.loads,
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps)(LogIn));

/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import { loading, loaded } from './loadActions';
import {
  GET_INVESTMENT_DETAIL_SUCCESS,
  GET_INVESTMENT_SUCCESS,
} from '../actionTypes';
import { unauthenticate } from './authUserActions';
import InvestmentAPI from '../../api/InvestmentAPI';

export function fetchInvestmentList() {
  return async (dispatch) => {
    const investmentAPI = new InvestmentAPI();
    dispatch(loading('investment'));
    try {
      const response = await investmentAPI.getInvestments();
      const { success, message, result } = response.data;
      if (success) {
        dispatch({
          type: GET_INVESTMENT_SUCCESS,
          payload: result,
        });
        dispatch(loaded('investment'));
      }
      else {
        toast.error(message);
        dispatch(loaded('investment'));
      }
    }
    catch (e) {
      console.log({ fetchOpportunityListError: e });
      if (e.response && e.response.status === 401) {
        toast.error('Una disculpa necesita ingresar usuario y contraseña nuevamente');
        dispatch(unauthenticate());
        dispatch(loaded('investment'));
        return;
      }
      toast.error('Parece haber un error inesperado, intentelo mas tarde');
      dispatch(loaded('investment'));
    }
  };
}

export function fetchInvestmentDetail(id) {
  return async (dispatch) => {
    const investmentAPI = new InvestmentAPI();
    dispatch(loading(''));
    try {
      const response = await investmentAPI.investmentDetail(id);
      console.log({ response });
      const { success, message, result } = response.data;
      if (success) {
        dispatch({
          type: GET_INVESTMENT_DETAIL_SUCCESS,
          payload: result,
        });
      }
      else {
        toast.error(message);
      }
    }

    catch (e) {
      console.log({ fetchOpportunityDetail: e });
      if (e.response && e.response.status === 401) {
        toast.error('Una disculpa, necesita ingresar de nuevo');
        dispatch(unauthenticate());
        return;
      }
      toast.error('Error en la API');
    }

    finally {
      dispatch(loaded('authUser'));
    }
  };
}
export function dispatchInvestmentDetail(item) {
  return async (dispatch) => {
    dispatch({
      type: GET_INVESTMENT_DETAIL_SUCCESS,
      payload: item,
    });
  };
}

/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
import axios from 'axios';
import ConfigurationAPI from './ConfigurationAPI';

class UserAPI extends ConfigurationAPI {
  logIn = (token) => {
    this.headers.headers.Authorization = `Basic ${token}`;
    return new Promise((resolve, reject) => axios.get(`${this.url}/login`, this.headers)
      .then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error.response);
      }));
  };

  logOut = () => (new Promise((resolve, reject) => (
    axios.get(`${this.url}/logout`, this.headers)
      .then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error.response);
      })
  )));

  getAsesorItems = () => new Promise((resolve, reject) => axios.get(`${this.url}/list/asesores`)
    .then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error.response);
    }));

  registerWithEmailPass = body => new Promise((resolve, reject) => axios.post(`${this.url}/register`, body)
    .then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error.response);
    }));

  getTermsConditionContent = () => new Promise((resolve, reject) => axios.get(`${this.url}/terminos-y-condiciones`)
    .then((res) => {
      resolve(res);
    }).catch((error) => {
      reject(error);
    }));

  getPrivacyPolicyContent = () => new Promise((resolve, reject) => axios.get(`${this.url}/aviso-de-privacidad`)
    .then((res) => {
      resolve(res);
    }).catch((error) => {
      reject(error);
    }))

  getContractContent = () => new Promise((resolve, reject) => axios.get(`${this.url}/profile/contract/html`, this.headers)
    .then((res) => {
      resolve(res);
    }).catch((error) => {
      reject(error);
    }));

  submitContract = body => new Promise((resolve, reject) => axios.put(`${this.url}/profile/firmar`, body, this.headers)
    .then((res) => {
      resolve(res);
    }).catch((error) => {
      reject(error);
    }))

  /* Password */
  requestPassword = body => new Promise((resolve, reject) => axios.post(`${this.url}/password/reset`, body)
    .then(res => resolve(res))
    .catch(err => reject(err)));

  validatePassword = key => new Promise((resolve, reject) => axios.get(`${this.url}/password/reset/?key=${key}`)
    .then(res => resolve(res))
    .catch(err => reject(err)));

  changePassword = body => new Promise((resolve, reject) => axios.put(`${this.url}/password/reset`, body)
    .then(res => resolve(res))
    .catch(err => reject(err)));
}

export default UserAPI;

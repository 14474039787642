import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Card,
  Divider,
  Chip,
  CardMedia,
  CardActionArea,
} from '@material-ui/core';
import { Fade } from 'react-reveal';

const InfoCard = ({
  body,
  chip,
  color,
  date,
  id,
  img,
  item,
  select,
  subTitle,
  title,
  type,
}) => {
  const still = moment(date).fromNow();
  return (
    <Fade bottom>
      <Card className="home_card">
        <CardActionArea
          className="home_card_detail"
          onClick={() => select(id, type, item)}
        >
          <div className="home_card_header">
            <div className="font-size-18 font-weight-600">{title}</div>
            <div className="home_card_subheader">
              <Chip color={color} label={chip} />
              <span>{still}</span>
            </div>
          </div>
          <Divider className="mx-1" />
          <div className="home_card_ref">
            <div className="font-size-14">{subTitle}</div>
            <div className="home_card_body font-size-18 font-weight-600 pl-2">
              {body}
            </div>
          </div>
        </CardActionArea>
        <CardActionArea
          className="home_card_action"
          onClick={() => select(id, type, item)}
        >
          <CardMedia
            image={img}
            title={title}
          />
        </CardActionArea>
      </Card>
    </Fade>
  );
};
InfoCard.propTypes = {
  body: PropTypes.string.isRequired,
  chip: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  select: PropTypes.func.isRequired,
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default InfoCard;

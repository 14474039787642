/* eslint-disable object-curly-newline */

import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

import { NavLink } from 'react-router-dom';

const LayoutMenu = ({
  classes,
  user,
  dashboardMenuIsDisplayed,
  closeDashboardMenu,
  toggleCommoModal,
}) => {
  const categoryClass = () => {
    let clasname = '';
    switch (user.cf_1811) {
      case 'Bronze':
        clasname = 'category-bronce';
        break;
      case 'Silver':
        clasname = 'category-silver';
        break;
      case 'Gold':
        clasname = 'category-gold';
        break;
      case 'Platinum':
        clasname = 'category-platinum';
        break;
      default:
        break;
    }
    return clasname;
  };
  return (
    <Drawer
      className={classes.drawer}
      anchor="left"
      open={dashboardMenuIsDisplayed}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={closeDashboardMenu}
    >
      <div className={`${classes.drawer} ${categoryClass()}`}>
        <div className="w-100">
          <div className="menu-header-title py-2">
            {
            user.cf_1811
              ? (
                <>
                  <img
                    src={`${process.env.PUBLIC_URL}/img/logo_head-01.png`}
                    alt=""
                  />
                  <span className="text-white">{user.cf_1811}</span>
                </>
              )
              : (
                <img
                  src={`${process.env.PUBLIC_URL}/img/logo-inverspot.png`}
                  alt=""
                />
              )
          }

          </div>
        </div>
      </div>
      <List className="menu_navList">
        <ListItem dense>
          <ListItemText
            primary={
            (user.ownership !== '' || user.cf_1130 !== '' || user.cf_1132 !== '')
              ? (`${user.ownership || ''} ${user.cf_1130 || ''} ${user.cf_1132 || ''}`)
              : user.user_id
          }
          />
        </ListItem>
        {/* <NavLink
          to="/app/home-page"
          onClick={() => {
            closeDashboardMenu();
          }}
        >
          <ListItem button>
            <div className={classes.menuItem}>Inicio</div>
          </ListItem>
          <Divider className={classes.divider} />
        </NavLink> */}
        {/* <NavLink
          to="/app/investment-rewards"
          onClick={() => {
            closeDashboardMenu();
          }}
        >
          <ListItem button>
            <div className={classes.menuItem}>Investment rewards</div>
          </ListItem>
          <Divider className={classes.divider} />
        </NavLink> */}
        {/* <NavLink
          to="/app/opportunity-investment"
          onClick={() => {
            closeDashboardMenu();
          }}
        >
          <ListItem button>
            <div className={classes.menuItem}>Oportunidades</div>
          </ListItem>
          <Divider className={classes.divider} />
        </NavLink> */}
       {/*  <NavLink
          to="/app/fondeos"
          onClick={() => {
            closeDashboardMenu();
          }}
        >
          <ListItem button>
            <div className={classes.menuItem}>Proyectos Fondeados</div>
          </ListItem>
          <Divider className={classes.divider} />
        </NavLink> */}
        {/* <NavLink
          to="/app/exitos"
          onClick={() => {
            closeDashboardMenu();
          }}
        >
          <ListItem button>
            <div className={classes.menuItem}>Concluidos</div>
          </ListItem>
          <Divider className={classes.divider} />
        </NavLink> */}
        {/* <NavLink
          to="/app/apartadas"
          onClick={() => {
            closeDashboardMenu();
          }}
        >
          <ListItem button>
            <div className={classes.menuItem}>Mis Apartadas</div>
          </ListItem>
          <Divider className={classes.divider} />
        </NavLink> */}

        <NavLink
          to="/app/investment"
          onClick={() => {
            closeDashboardMenu();
          }}
        >
          <ListItem button>
            <div className={classes.menuItem}>Mis Inversiones</div>
          </ListItem>
          <Divider className={classes.divider} />
        </NavLink>

       {/*  <NavLink
          to="/app/referrals"
          onClick={() => {
            closeDashboardMenu();
          }}
        >
          <ListItem button>
            <div className={classes.menuItem}>Mis Referidos</div>
          </ListItem>
          <Divider className={classes.divider} />
        </NavLink> */}

        <NavLink
          to="/app/downloads"
          onClick={() => {
            closeDashboardMenu();
          }}
        >
          <ListItem button>
            <div className={classes.menuItem}>Mis Descargas</div>
          </ListItem>
          <Divider className={classes.divider} />
        </NavLink>

        <NavLink
          to="/app/profile"
          onClick={() => {
            closeDashboardMenu();
          }}
        >
          <ListItem button>
            <div className={classes.menuItem}>Mi perfil</div>
          </ListItem>
          <Divider className={classes.divider} />
        </NavLink>

        {/* <NavLink
          to="#"
          onClick={() => {
            toggleCommoModal();
            closeDashboardMenu();
          }}
        >
          <ListItem button>
            <div className={classes.menuItem}>¿Cómo invertir?</div>
          </ListItem>
        </NavLink> */}

        {/* <NavLink
          to="#"
          style={{backgroundColor: '#ff5600', color: '#fff'}}
          onClick={() => {
            toggleCommoModal();
            window.open('https://inverspot.mx/wp-content/uploads/ebook%20inversiones.pdf', '_blank');
          }}
        >
          <ListItem button>
            <div className={classes.menuItem} style={{color: '#fff'}}>Recursos</div>
          </ListItem>
        </NavLink> */}

        <NavLink
          to="/sign-out"
          onClick={() => {
            closeDashboardMenu();
          }}
        >
          <ListItem button>
            <div className={classes.menuItem}>Cerrar sesión</div>
          </ListItem>
          <Divider className={classes.divider} />
        </NavLink>
      </List>
      <div className="text-center" style={{ padding: 20 }}>
        <div>
          <span>
            {user.last_login !== undefined && (`${user.last_login}`)}
          </span>
        </div>
        <div><span>Ultima sesión</span></div>
      </div>
    </Drawer>
  ); };

LayoutMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  dashboardMenuIsDisplayed: PropTypes.bool.isRequired,
  closeDashboardMenu: PropTypes.func.isRequired,
  toggleCommoModal: PropTypes.func,
};

LayoutMenu.defaultProps = {
  toggleCommoModal: () => {},
};

export default LayoutMenu;

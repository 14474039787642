/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import PaidLiquidationItem from './components/PaidLiquidationItem';

const TabPaidLiquidations = ({
  access,
  data,
  downloadRequest,
}) => (
  <div className="minHe50vh">
    <div className="w-100 font-size-24 font-weight-600 text-center">
      {'Pago de Liquidaciones'}
    </div>
    <div className="border_bottom_purple" />
    {data.map(liquidation => (
      liquidation.cash_flow.map(item => (
        <PaidLiquidationItem
          key={item.crmid}
          item={item}
          access={access}
          downloadRequest={downloadRequest}
        />
      ))
    ))}
  </div>
);

TabPaidLiquidations.propTypes = {
  access: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  downloadRequest: PropTypes.func,
};

TabPaidLiquidations.defaultProps = {
  downloadRequest: () => {},
};

export default TabPaidLiquidations;

/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import Timeline from '../Timeline';

const TabAdvance = ({ data }) => {
  const {
    avances_de_obra,
    cf_1398,
    cf_1552,
  } = data;
  return (
    <div className="px-4 pb-4 minHe50vh font_inver_deepGray">
      <div className="w-100 font-size-24 font-weight-600 text-center">
        {'Avance de Obra'}
      </div>
      <div className="border_bottom_purple" />
      <div className="process-item justify-content-between d-flex">
        <div className="font-size-14 font-weight-600">Plusvalía estimada en la zona</div>
        <div className="font-size-14 font-weight-600">{`${cf_1552 ? cf_1552 : '0'} %`}</div>
      </div>
      <div className="process-item-none-bg justify-content-between d-flex">
        <div className="font-size-14 font-weight-600">Meses de atraso estimado</div>
        <div className="font-size-14 font-weight-600">{cf_1398 ? `${cf_1398} meses` : '0'}</div>
      </div>
      {avances_de_obra && avances_de_obra.length > 0 && (
        <Timeline timelineData={avances_de_obra.reverse() || []} />
      )}
    </div>
  );
};

TabAdvance.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TabAdvance;

/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import TabItemEdit from '../TabItemEdit';


const Tab4 = ({ userType, profile, editProfile }) => {
  const {
    cf_1352, cf_1354, cf_1348, cf_1350,
  } = profile;
  return (
    <div className="p-2 minHe70vh">
      <TabItemEdit
        title="Número de cuenta para depósitos"
        value={cf_1348}
        inputType="text"
        field="cf_1348"
        onSubmit={editProfile}
      />
      <TabItemEdit
        title="Banco"
        value={cf_1352}
        inputType="text"
        field="cf_1352"
        onSubmit={editProfile}
      />
      <TabItemEdit
        title="Nombre de titular de la cuenta"
        value={cf_1354}
        inputType="text"
        field="cf_1354"
        onSubmit={editProfile}
      />
      <TabItemEdit
        title="CLABE Interbancaria"
        value={cf_1350}
        inputType="text"
        field="cf_1350"
        onSubmit={editProfile}
      />
    </div>
  );
};

Tab4.propTypes = {
  userType: PropTypes.string,
  profile: PropTypes.object,
  editProfile: PropTypes.func,
};

Tab4.defaultProps = {
  userType: '',
  profile: {},
  editProfile: () => {},
};

export default Tab4;

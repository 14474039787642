/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Bounce } from 'react-reveal';
import { toolsHelper } from '../../helpers';

const TabFin = ({ data }) => {
  const {
    cf_1013,
    cf_1015,
    cf_1208,
    cf_1226,
    cf_1230,
    cf_979,
  } = data;
  return (
    <div className="px-4 pb-4 font_inver_deepGray">
      <div className="mb-4">
        <div className="w-100 font-size-24 font-weight-600 text-center">
          {'Corrida Financiera'}
        </div>
        <div className="border_bottom_purple" />
        <div className="font-size-20 font-weight-600 font-purple">
          Proceso de fondeo
        </div>
        <div className="process-item justify-content-between d-flex">
          <div className="font-size-14 font-weight-600">Fondeo</div>
          <div className="font-size-14 font-weight-600">
            {`$${toolsHelper.formatMoney(cf_1015 || 0)}`}
          </div>
        </div>
        <div className="process-item-none-bg justify-content-between d-flex">
          <div className="font-size-14 font-weight-600">Pago contra venta</div>
          <div className="font-size-14 font-weight-600">
            {`$${toolsHelper.formatMoney(cf_1208 || 0)}`}
          </div>
        </div>
        <div className="process-item justify-content-between d-flex">
          <div className="font-size-14 font-weight-600">Costo total</div>
          <div className="font-size-14 font-weight-600">
            {`$${toolsHelper.formatMoney(
              Number.parseFloat(cf_1208) + Number.parseFloat(cf_1015) || 0,
            )}`}
          </div>
        </div>
      </div>
      <div className="mb-2">
        <div className="font-size-20 font-weight-600  font-purple">
          Proceso de venta (desarrollador)
        </div>
        <div className="process-item justify-content-between d-flex">
          <div className="font-size-14 font-weight-600">Estimado de venta</div>
          <div className="font-size-14 font-weight-600">
            {`$${toolsHelper.formatMoney(cf_1226 || 0)}`}
          </div>
        </div>
        <div className="process-item-none-bg justify-content-between d-flex">
          <div className="font-size-14 font-weight-600">Comisión de venta</div>
          <div className="font-size-14 font-weight-600">
            {`$${toolsHelper.formatMoney(cf_1230 || 0)}`}
          </div>
        </div>
        <div className="process-item justify-content-between d-flex">
          <div className="font-size-14 font-weight-600">Costo total</div>
          <div className="font-size-14 font-weight-600">
            {`$${toolsHelper.formatMoney(
              Number.parseFloat(cf_1208) + Number.parseFloat(cf_1015) || 0,
            )}`}
          </div>
        </div>
        <div className="process-item-none-bg justify-content-between d-flex">
          <div className="font-size-14 font-weight-600">
            Utilidad neta estimada
          </div>
          <div className="font-size-14 font-weight-600">
            {`$${toolsHelper.formatMoney(cf_1013 || 0)}`}
          </div>
        </div>
        <div className="process-item justify-content-between d-flex">
          <div className="font-size-14 font-weight-600">Plazo estimado</div>
          <div className="font-size-14 font-weight-600">
            {`${cf_979} meses`}
          </div>
        </div>
      </div>
      <Bounce cascade>
        <div className="w-100 d-flex flex-column aling-items-center justify-content-center mt-2">
          <span className="font-purple font-size-26 font-weight-700 text-center">
            {`$${toolsHelper.formatMoney(cf_1015 || 0)}`}
          </span>
          <span className="text-center text-gray font-weight-700">
            Objetivo de captación
          </span>
        </div>
      </Bounce>
    </div>
  );
};

TabFin.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TabFin;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Fab, Zoom } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toolsHelper } from '../../helpers';

const HeaderOverView = ({
  row1,
  row2,
  row3,
  row4,
  row5,
  row6,
  image,
  onHandleModal,
  imageFloatButton,
  imageFloatButtonIcon,
}) => (
  <div className="w-100">
    <div className="w-100 position-relative text-center">
      {image && (
        <div className="opportunity-investment-detail-img-container">
          <div className="button_container">
            <Zoom in={imageFloatButton}>
              <Fab
                color="primary"
                onClick={() => onHandleModal()}
              >
                <FontAwesomeIcon
                  className="font-size-18"
                  icon={imageFloatButtonIcon}
                />
              </Fab>
            </Zoom>
          </div>
          <img
            className="opportunity-investment-detail-img-bg"
            alt=""
            src={image}
          />
        </div>
      )}
    </div>
    <div className="opportunity-investment-detail-header-gradient">
      <div className="opportunity-investment-detail-header-overview">
        <div className="row d-flex mx-0 justify-content-center">
          <div className="col-4 px-0 d-flex justify-content-center flex-column align-items-center">
            <span className="detail-info">
              {`$ ${toolsHelper.formatMoney(row1)}`}
            </span>
            <span className="text-black-50">Inversión</span>
          </div>
          <div className="col-4 px-0 d-flex justify-content-center flex-column align-items-center">
            <span className="detail-info">{`${row2} %`}</span>
            <span className="text-black-50">Rendimiento</span>
          </div>
          {row3 && (
            <div className="col-4 px-0 d-flex justify-content-center flex-column align-items-center">
              <span className="detail-info">{`${row3} meses`}</span>
              <span className="text-black-50">Plazo</span>
            </div>
          )}
          {row4 && (
            <div className="col-4 px-0 d-flex justify-content-center flex-column align-items-center">
              <span className="detail-info">{row4}</span>
              <span className="text-black-50">Avance de la obra</span>
            </div>
          )}
          {row5 && (
            <div className="col-4 px-0 d-flex justify-content-center flex-column align-items-center">
              <span className="detail-info">{`${row5} %`}</span>
              <span className="text-black-50">Participaciones</span>
            </div>
          )}
          {row6 && (
            <div className="col-4 px-0 d-flex justify-content-center flex-column align-items-center">
              <span className="detail-info">{row6}</span>
              <span className="text-black-50">Inicio</span>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

HeaderOverView.propTypes = {
  row1: PropTypes.number,
  row2: PropTypes.string,
  row3: PropTypes.string,
  row4: PropTypes.string,
  row5: PropTypes.string,
  row6: PropTypes.string,
  image: PropTypes.string,
  imageFloatButton: PropTypes.bool,
  onHandleModal: PropTypes.func.isRequired,
  imageFloatButtonIcon: PropTypes.string.isRequired,
};

HeaderOverView.defaultProps = {
  row1: '',
  row2: '',
  row3: '',
  row4: '',
  row5: '',
  row6: '',
  image: '',
  imageFloatButton: false,
};

export default connect()(HeaderOverView);

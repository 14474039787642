/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import PaidInvestmentItem from './components/PaidInvestmentItem';

export const TabPaidInvestments = ({
  access,
  data,
  downloadRequest,
}) => (
  <div className="minHe50vh">
    <div className="w-100 font-size-24 font-weight-600 text-center">
      {'Pago de Inversión'}
    </div>
    <div className="border_bottom_purple" />
    {data.some(x => x.cash_flow.length > 0) ? (
      <div className="w-100">
        {data.map(investment => (
          investment.cash_flow.map(item => (
            <PaidInvestmentItem
              key={item.crmid}
              item={item}
              access={access}
              downloadRequest={downloadRequest}
            />
          ))
        ))}
      </div>
    ) : (
      <div className="w-100 p-3">
        {'Una disculpa, aun no se realizan pagos por las inversiones.'}
      </div>
    )}
  </div>
);

TabPaidInvestments.propTypes = {
  access: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  downloadRequest: PropTypes.func,
};

TabPaidInvestments.defaultProps = {
  downloadRequest: () => {},
};

export default TabPaidInvestments;

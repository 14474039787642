import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isIOS } from 'react-device-detect';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ButtonBase,
} from '@material-ui/core';

const DownloadItem = ({ item, onClick, toView }) => {
  const date = moment(item.createdtime).fromNow();
  // const link = (`image://send?text=${item.url}`);
  if (isIOS) {
    return (
      <ButtonBase
        href={item.url}
        className="w-100 d-flex justify-content-center align-items-center"
      >
        <ListItem>
          <ListItemIcon>
            <FontAwesomeIcon
              icon={item.type.startsWith('image') ? 'file-image' : 'file-pdf'}
              style={{ fontSize: '1.4rem' }}
            />
          </ListItemIcon>
          <ListItemText
            primary={`${item.title}.${item.type.split('/')[1]}`}
            secondary={date}
          />
        </ListItem>
      </ButtonBase>
    );
  }
  return (
    <ListItem
      button
      onClick={() => setTimeout(() => {
        if (item.url.endsWith('.png') || item.url.endsWith('.jpeg')) {
          toView(item.url);
        } else {
          onClick(item.url, `${item.title}.${item.type.split('/')[1]}`);
        }
      }, 500)}
    >
      <ListItemIcon className="font-size-24">
        <FontAwesomeIcon
          icon={item.type.startsWith('image') ? 'file-image' : 'file-pdf'}
        />
      </ListItemIcon>
      <ListItemText
        primary={`${item.title}.${item.type.split('/')[1]}`}
        secondary={date}
      />
    </ListItem>
  );
};

DownloadItem.propTypes = {
  item: PropTypes.object.isRequired,
  toView: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DownloadItem;

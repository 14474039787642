/* eslint-disable radix */
/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import {
  Tab,
  Tabs,
  Divider,
  Collapse,
  withStyles,
  AppBar,
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from 'react-device-detect';
import HeaderOverView from '../../../components/HeaderOverView';
import TabDetail from '../../../components/Tabs/TabDetails';
import TabFin from '../../../components/Tabs/TabFin';
import TabAdvance from '../../../components/Tabs/TabAdvance';
import {
  validateStatus,
  submitApartarRequest,
  submitContractAction,
  fetchOpportunityDetail,
  downloadContractAction,
  getContractContentAction,
} from '../../../redux/actions';
import HeaderTop from '../../../components/HeaderTop';
import HeaderProgress from '../../../components/HeaderProgress';
import BottomFloatButton from '../../../components/BottomFloatButton';
import PutInvestmentDialog from '../../../components/PutInvestmentDialog';
import WarningRegisterDialog from '../../../components/WarningRegisterDialog';

const OpportunityInvestmentDetail = ({
  msg,
  match,
  history,
  classes,
  user_id,
  dispatch,
  loading,
  access,
  profile_status,
  contract_content,
  opportunityDetail,
}) => {
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const fetchInfo = () => {
    const { id } = match.params;
    dispatch(fetchOpportunityDetail(id));
    if (profile_status === 'must_sign_contract') {
      dispatch(getContractContentAction());
    }
  };

  React.useEffect(() => {
    fetchInfo();
    // eslint-disable-next-line
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onHandleModal = async () => {
    if (!open) await dispatch(validateStatus());
    await setOpen(!open);
  };

  const redirectRegister = () => {
    if (open) history.push('/sign-up-step');
  };

  const onSubmitForm = (quantity) => {
    setOpen(false);
    const { id } = match.params;
    dispatch(submitApartarRequest(id, quantity, history));
  };

  const onDownload = () => {
    dispatch(downloadContractAction());
    setOpen(false);
  };

  const onSubmitContract = (p) => {
    dispatch(submitContractAction(p));
    setOpen(false);
  };

  const {
    cf_1015, unit_price, qtyinstock, cf_1214,
  } = opportunityDetail;

  const total = Math.round(cf_1015 / unit_price);
  const faltan = Number.parseInt(total - qtyinstock);

  return (
    <div className={isMobile ? 'vw-100 d-flex flex-column' : 'maxWidth500px d-flex flex-column'}>
      <HeaderTop history={history} opportunityDetail={opportunityDetail} />
      <HeaderOverView
        image={
          opportunityDetail.images && opportunityDetail.images[0]
            ? opportunityDetail.images[0]
            : ''
        }
        row1={parseFloat(
          opportunityDetail.unit_price ? opportunityDetail.unit_price : 0,
        )}
        row2={opportunityDetail.cf_1402 ? opportunityDetail.cf_1402 : '0'}
        row3={opportunityDetail.cf_1400 ? opportunityDetail.cf_1400 : ''}
        row4={null}
        row5={null}
        row6={null}
        imageFloatButton={value !== 0}
        imageFloatButtonIcon="plus"
        onHandleModal={onHandleModal}
      />
      <Divider className="my-1 mx-2" />
      <Collapse in={!loading || false}>
        <HeaderProgress
          total={total}
          faltan={faltan}
          fondeo={Number.parseInt(cf_1015)}
          vendido={Number.parseInt(cf_1214)}
        />
      </Collapse>
      <Divider className="my-1 mx-2" />
      <div className="d-flex flex-column p-0 mb-4">
        <AppBar
          position="sticky"
          className="appBar_Navegation"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            classes={{
              indicator: classes.indicator,
            }}
            className="position-sticky"
          >
            <Tab
              className="tab"
              style={{ color: value === 0 ? '#662D91' : '#CCCCCC' }}
              icon={
                <FontAwesomeIcon className="font-size-18" icon="info-circle" />
              }
            />
            <Tab
              className="tab tab-left-border"
              style={{ color: value === 1 ? '#662D91' : '#CCCCCC' }}
              icon={
                <FontAwesomeIcon className="font-size-18" icon="chart-line" />
              }
            />
            <Tab
              className="tab tab-left-border"
              style={{ color: value === 2 ? '#662D91' : '#CCCCCC' }}
              icon={
                <FontAwesomeIcon className="font-size-18" icon="hard-hat" />
              }
            />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis="x"
          resistance
          index={value}
          animateHeight={value > 0}
          enableMouseEvents
          onChangeIndex={index => setValue(index)}
        >
          <TabDetail data={opportunityDetail} />
          <TabFin data={opportunityDetail} />
          <TabAdvance data={opportunityDetail} />
        </SwipeableViews>
      </div>
      {open
        && (profile_status === 'approved' ? (
          <PutInvestmentDialog
            open={open}
            image={
              opportunityDetail.images && opportunityDetail.images[0]
                ? opportunityDetail.images[0]
                : ''
            }
            title={opportunityDetail.productname}
            onHandleModal={onHandleModal}
            onSubmitForm={onSubmitForm}
            unit_price={unit_price}
            total={total}
            faltan={faltan}
            fondeo={cf_1015}
          />
        ) : (
          <WarningRegisterDialog
            msg={msg}
            open={open}
            user_id={user_id}
            content={contract_content}
            onDownload={onDownload}
            access={access}
            profile_status={profile_status}
            onHandleModal={onHandleModal}
            onSubmitContract={onSubmitContract}
            redirectRegister={redirectRegister}
          />
        ))}
      <BottomFloatButton
        label="Aparta tu participación"
        icon="piggy-bank"
        onHandleModal={onHandleModal}
        enable={value === 0 && !loading}
      />
    </div>
  );
};

const mapStateToProps = ({
  opportunity,
  auth,
  loads,
  profileReducer,
}) => {
  const { opportunityDetail } = opportunity;
  const {
 profile_status, user_id, token, token_type 
} = auth.user;
  const { contract_content } = auth;
  const { loading } = loads;
  const { msg } = profileReducer;
  return {
    opportunityDetail,
    profile_status,
    contract_content,
    user_id,
    loading,
    access: {
      token,
      token_type,
    },
    msg,
  };
};
const styles = theme => ({
  indicator: {
    backgroundColor: '#662D91',
  },
});

export default connect(mapStateToProps)(
  withStyles(styles)(OpportunityInvestmentDetail),
);

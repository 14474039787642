import { userHelper } from '../helpers';

class ConfigurationAPI {
  constructor() {
    const authUser = userHelper.getStorage();
    this.token = authUser.isAuth ? authUser.token : '';
    this.domain = 'crm.inverspot.mx/api';
    // this.domain = process.env.production ? ('crm.inverspot.mx/api') : ('pruebasinverspot.pruebastreebes.com/api');
    this.protocol = 'https';
    this.headers = {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    };
    this.url = `${this.protocol}://${this.domain}`;
  }
}

export default ConfigurationAPI;

import React from 'react';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import {
  Card,
  CardMedia,
  CardContent,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  CardActionArea,
} from '@material-ui/core';
import { CropOriginal } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImageView from '../ImageView';

const ImgThumbnail = ({
  imgSrc,
  title,
  emptyPrimary,
  emptySecondary,
  enableDownload,
  desc,
}) => {
  const [open, setOpen] = React.useState(false);
  const onDownloadPdf = (url, name) => {
    FileSaver.saveAs(url, name);
  };
  const renderMedia = (_isImg) => {
    if (_isImg) {
      return (
        <CardActionArea
          onClick={() => setOpen(!open)}
        >
          <CardMedia
            style={{ height: '20vh' }}
            image={imgSrc}
            title={title}
          />
          <CardContent>
            <Typography gutterBottom variant="h4" component="h2">
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {desc}
            </Typography>
          </CardContent>
          <ImageView
            urlSrc={[imgSrc]}
            isOpen={open}
            toggle={() => setOpen(!open)}
            enableDownload={enableDownload}
          />
        </CardActionArea>
      );
    }
    return (
      <CardActionArea
        onClick={() => onDownloadPdf(imgSrc, title)}
      >
        <ListItem>
          <ListItemIcon>
            <FontAwesomeIcon className="font-size-20" icon="file-pdf" />
          </ListItemIcon>
          <ListItemText primary={title} secondary={desc} />
        </ListItem>
      </CardActionArea>
    );
  };
  const isImg = Boolean(imgSrc !== null && (imgSrc.endsWith('.png')
  || imgSrc.endsWith('.jpg')
  || imgSrc.endsWith('.jpeg')));
  return (
    <Card className="imgThumnbnail_card">
      {imgSrc !== null ? (
        renderMedia(isImg)
      ) : (
        <ListItem>
          <ListItemIcon>
            <CropOriginal />
          </ListItemIcon>
          <ListItemText primary={emptyPrimary} secondary={emptySecondary} />
        </ListItem>
      )}
    </Card>
  );
};

ImgThumbnail.propTypes = {
  imgSrc: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  emptyPrimary: PropTypes.string,
  enableDownload: PropTypes.bool,
  emptySecondary: PropTypes.string,
};

ImgThumbnail.defaultProps = {
  imgSrc: null,
  enableDownload: false,
  title: 'imagen',
  desc: 'Preview de la imagen',
  emptyPrimary: 'Sin imagen',
  emptySecondary: 'Parece que la imagen no fue proveída o no existe.',
};

export default ImgThumbnail;

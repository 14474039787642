import React from 'react';
import PropTypes from 'prop-types';
import { Edit } from '@material-ui/icons';
import { Modal, ModalHeader } from 'reactstrap';
import { ButtonBase, Button, Collapse } from '@material-ui/core';

const TabItemEdit = ({
  inputType,
  logo,
  onSubmit,
  title,
  value,
  field,
}) => {
  const inputRef = React.useRef(null);
  const inputPass = React.useRef(null);
  const [modalEdit, setModalEdit] = React.useState(false);
  const [edit, setEdit] = React.useState(value);
  const [pass, setPass] = React.useState('');

  const onKeyInput = (e) => {
    const { keyCode } = e;
    if (keyCode === 13 && inputPass) if (inputPass) inputPass.current.select();
  };

  const changeInput = (e) => {
    const { target } = e;
    setEdit(target.value);
  };

  const changePass = (e) => {
    const { target } = e;
    setPass(target.value);
  };

  const submit = () => {
    onSubmit({ password: pass, [field]: edit });
    setModalEdit(false);
  };

  React.useEffect(() => {
    if (modalEdit) {
      if (inputRef) inputRef.current.select();
    }
    // eslint-disable-next-line
  }, [modalEdit]);
  return (
    <div className="border-bottom py-1 w-100 d-flex justify-content-between">
      <div className="d-flex flex-column">
        <div className="font-gray font-size-12">{title}</div>
        <div className="font-size-16 font-weight-600 pl-1">
          {value || '-'}
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <ButtonBase
          style={{ width: '48px', height: '48px' }}
          onClick={() => setModalEdit(!modalEdit)}
        >
          <Edit />
        </ButtonBase>
      </div>
      <Modal isOpen={modalEdit} toggle={() => setModalEdit(!modalEdit)}>
        <ModalHeader className="modal-box-header bg-purple text-white text-center">
          <div className="w-75 m-auto">
            <img src={logo} alt="" />
          </div>
        </ModalHeader>
        <div className="modal-box-content">
          <div className="w-100 h-auto">
            <div className="w-100 text-center font-size-18 mt-1">
              {`Editar: ${title}`}
            </div>
            <div className="w-100 my-2">
              <input
                id={`id_input_change_${Math.random()}`}
                name={`input_change_${Math.random()}`}
                className="form-control form-control-lg"
                placeholder={title}
                ref={inputRef}
                type={inputType}
                value={edit}
                onChange={changeInput}
                onFocus={() => {
                  setEdit(value);
                  if (inputRef) inputRef.current.select();
                }}
                onKeyDown={onKeyInput}
                autoComplete="new-password"
              />
            </div>
            <div className="w-100 my-2">
              <input
                id={`id_input_changeWith_${Math.random()}`}
                name={`input_changeWith_${Math.random()}`}
                className="form-control form-control-lg"
                placeholder="Contraseña"
                ref={inputPass}
                type="password"
                value={pass}
                onChange={changePass}
                onFocus={() => {
                  if (inputPass) inputPass.current.select();
                }}
                autoComplete="new-password"
              />
            </div>
            <Collapse in={edit !== '' && pass !== ''}>
              <div className="w-100 login_buttons">
                <Button
                  className="login_submit block-outline"
                  variant="contained"
                  disabled={edit === value}
                  onClick={submit}
                >
                Guardar
                </Button>
              </div>
            </Collapse>
          </div>
        </div>
      </Modal>
    </div>
  );
};

TabItemEdit.propTypes = {
  inputType: PropTypes.string,
  logo: PropTypes.string,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.string,
  field: PropTypes.string.isRequired,
};

TabItemEdit.defaultProps = {
  inputType: 'text',
  logo: `${process.env.PUBLIC_URL}/img/logo-inverspot-white.png`,
  onSubmit: () => {},
  title: '',
  value: '',
};

export default TabItemEdit;

/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader } from 'reactstrap';
import { Button, CircularProgress, Collapse } from '@material-ui/core';

const ChangePasswordDialog = ({
  logo,
  isOpen,
  isLoading,
  onSubmit,
  toggle,
}) => {
  const pass = React.useRef(null);
  const rePass = React.useRef(null);
  const [actual, setActual] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [rePassword, setRePassword] = React.useState('');
  const [passErr, setPassErr] = React.useState(false);
  const handleChange = (e) => {
    const { value, name } = e.target;
    if (name === 'actual') {
      setActual(value);
    } else if (name === 'password') {
      setPassword(value);
    } else if (name === 're-password') {
      setRePassword(value);
    }
  };
  const submit = () => {
    if (password.length > 7 && password !== actual && password === rePassword) {
      onSubmit({ password, password_current: actual });
    } else {
      setPassErr(true);
    }
  };
  return (
    <Modal isOpen={isOpen} toggle={() => toggle()}>
      <ModalHeader className="modal-box-header bg-purple text-white text-center">
        <div className="w-75 m-auto">
          <img src={logo} alt="" />
        </div>
      </ModalHeader>
      <div className="modal-box-content">
        <div className="w-100 h-auto">
          <Collapse in={isLoading}>
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <CircularProgress color="primary" />
            </div>
          </Collapse>
          <div className="w-100 text-center font-size-24">
              Cambio de contraseña
          </div>
          <div className="w-100 h-auto">
            <div className="w-100">
                Contraseña actual
            </div>
            <div className="w-100 font-size-16 mt-1">
              <input
                className="form-control  form-control-lg"
                value={actual}
                type="password"
                name="actual"
                placeholder="Contraseña actual"
                onChange={handleChange}
                onKeyDown={(e) => {
                  const { keyCode } = e;
                  if (keyCode === 13) {
                    if (pass) pass.current.focus();
                  }
                }}
              />
            </div>
          </div>
          <Collapse in={!isLoading && actual.length > 0}>
            <div className="w-100 h-auto">
              <div className="w-100">
                  Ingresa tu nueva contraseña
              </div>
              <div className="w-100 font-size-16 mt-1">
                <input
                  ref={pass}
                  className="form-control  form-control-lg"
                  value={password}
                  type="password"
                  name="password"
                  placeholder="Contraseña"
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    const { keyCode } = e;
                    if (keyCode === 13) {
                      if (rePass) rePass.current.focus();
                    }
                  }}
                />
              </div>
              <div className="form-group w-100 px-1">
                  Nota: La contraseña debe ser de por lo menos 7 caracteres de
                  largo. Para hacerla más segura, utiliza mayúsculas, minúsculas,
                  numeros y simbolos ! “ ? $ % * &
              </div>
              <Collapse in={passErr}>
                <div className="form-group w-100 px-1 invalid-text">
                    * Contraseña no valida
                </div>
              </Collapse>
              <Collapse in={password.length > 0} className="w-100">
                <div className="w-100 font-size-16 mt-1">
                  <input
                    id="re-password"
                    ref={rePass}
                    className="form-control  form-control-lg"
                    value={rePassword}
                    type="password"
                    name="re-password"
                    autoComplete={false}
                    placeholder="Confirma contraseña"
                    onChange={handleChange}
                  />
                </div>
              </Collapse>
              <Collapse
                in={password.length > 0
                    && rePassword.length > 0
                    && (password === rePassword)}
                className="mt-2"
              >
                <div className="login_buttons w-100">
                  <Button
                    onClick={() => submit()}
                    variant="contained"
                    className="login_submit block-outline"
                  >
                      Restaurar Contraseña
                  </Button>
                </div>
              </Collapse>
              <Collapse
                in={password.length > 0
                    && rePassword.length > 0
                    && (password !== rePassword)}
              >
                <div className="w-100 font-size-16 mt-1 invalid-text">
                    * Contraseña y confirmación deben ser iguales
                </div>
              </Collapse>
            </div>
          </Collapse>
        </div>
      </div>
    </Modal>
  );
};

ChangePasswordDialog.propTypes = {
  logo: PropTypes.string,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  toggle: PropTypes.func,
};

ChangePasswordDialog.defaultProps = {
  logo: `${process.env.PUBLIC_URL}/img/logo-inverspot-white.png`,
  isLoading: false,
  isOpen: false,
  onSubmit: () => {},
  toggle: () => {},
};

export default ChangePasswordDialog;

/* eslint-disable camelcase */
/* eslint-disable radix */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SwipeableViews from 'react-swipeable-views';
import {
  AppBar,
  Collapse,
  Divider,
  Tab,
  Tabs,
  withStyles,
} from '@material-ui/core';
import { fetchOpportunityDetail } from '../../../redux/actions';
import HeaderTop from '../../../components/HeaderTop';
import HeaderOverView from '../../../components/HeaderOverView';
import HeaderProgress from '../../../components/HeaderProgress';
import TabDetail from '../../../components/Tabs/TabDetails';
import TabFin from '../../../components/Tabs/TabFin';
import TabAdvance from '../../../components/Tabs/TabAdvance';

const FondeoDetail = ({
  classes,
  dispatch,
  history,
  loading,
  match,
  opportunityDetail,
}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    const {
      params: { id },
    } = match;
    dispatch(fetchOpportunityDetail(id));
    // eslint-disable-next-line
  }, []);
  const { cf_1015, unit_price } = opportunityDetail;
  const total = Math.round(cf_1015 / unit_price);
  return (
    <div
      className={
        isMobile
          ? 'vw-100 d-flex flex-column'
          : 'maxWidth500px d-flex flex-column'
      }
    >
      <HeaderTop history={history} opportunityDetail={opportunityDetail} />
      <HeaderOverView
        image={
          opportunityDetail.images && opportunityDetail.images[0]
            ? opportunityDetail.images[0]
            : ''
        }
        row1={parseFloat(
          opportunityDetail.unit_price ? opportunityDetail.unit_price : 0,
        )}
        row2={opportunityDetail.cf_1402 ? opportunityDetail.cf_1402 : '0'}
        row3={opportunityDetail.cf_1400 ? opportunityDetail.cf_1400 : ''}
        row4={null}
        row5={null}
        row6={null}
        imageFloatButton={false}
        imageFloatButtonIcon="plus"
        onHandleModal={() => {}}
      />
      <Divider className="my-1 mx-2" />
      <Collapse in={!loading || false}>
        <HeaderProgress
          total={total}
          faltan={total}
          fondeo={Number.parseInt(cf_1015)}
          vendido={Number.parseInt(cf_1015)}
        />
      </Collapse>
      <Divider className="my-1 mx-2" />
      <div className="d-flex flex-column p-0 mb-4">
        <AppBar
          position="sticky"
          className="appBar_Navegation"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            classes={{
              indicator: classes.indicator,
            }}
            className="position-sticky"
          >
            <Tab
              className="tab"
              style={{ color: value === 0 ? '#662D91' : '#CCCCCC' }}
              icon={
                <FontAwesomeIcon className="font-size-18" icon="info-circle" />
              }
            />
            <Tab
              className="tab tab-left-border"
              style={{ color: value === 1 ? '#662D91' : '#CCCCCC' }}
              icon={
                <FontAwesomeIcon className="font-size-18" icon="chart-line" />
              }
            />
            <Tab
              className="tab tab-left-border"
              style={{ color: value === 2 ? '#662D91' : '#CCCCCC' }}
              icon={
                <FontAwesomeIcon className="font-size-18" icon="hard-hat" />
              }
            />
          </Tabs>
          <SwipeableViews
            axis="x"
            resistance
            index={value}
            animateHeight={value > 0}
            enableMouseEvents
            onChangeIndex={index => setValue(index)}
          >
            <TabDetail data={opportunityDetail} />
            <TabFin data={opportunityDetail} />
            <TabAdvance data={opportunityDetail} />
          </SwipeableViews>
        </AppBar>
      </div>
    </div>
  );
};

FondeoDetail.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  opportunityDetail: PropTypes.object.isRequired,
};

const mapStateToProps = ({ opportunity, loads }) => {
  const { opportunityDetail } = opportunity;
  const { loading } = loads;
  return {
    opportunityDetail,
    loading,
  };
};

const styles = () => ({
  indicator: {
    backgroundColor: '#662D91',
  },
});

export default connect(mapStateToProps)(withStyles(styles)(FondeoDetail));

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

const TabItemButton = ({
  label,
  title,
  onSubmit,
}) => (
  <div className="border-bottom py-1">
    <div className="font-gray font-size-12">{title}</div>
    <div className="font-size-13 font-weight-600 pl-1 login_buttons">
      <Button
        variant="contained"
        className="login_submit block-outline"
        onClick={() => onSubmit()}
      >
        {label}
      </Button>
    </div>
  </div>
);

TabItemButton.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
};

TabItemButton.defaultProps = {
  label: '-',
  title: '',
  onSubmit: () => {},
};

export default TabItemButton;

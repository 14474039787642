/* eslint-disable camelcase */
import {
  GET_REFERRAL_SUCCESS,
  SET_REFERRAL_SUCCESS,
} from '../actionTypes';

const defaultState = () => ({
  referidos: [],
  comisiones: [],
  code: '',
  url: '',
});

const initialState = { ...defaultState() };

export default function (state = initialState, action) {
  const { type } = action;
  switch (type) {
    case GET_REFERRAL_SUCCESS:
      return {
        ...state,
        referidos: action.payload.referidos,
        comisiones: action.payload.comisiones,
        code: action.payload.referrer_code,
        url: action.payload.referrer_url,
      };
    case SET_REFERRAL_SUCCESS:
      return {
        ...state,
        code: action.referrer_code,
      };
    default:
      return state;
  }
}

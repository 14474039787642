// Auth
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const UNAUTHENTICATE_USER = 'UNAUTHENTICATE_USER';
export const GET_ASESORES_SUCCESS = 'GET_ASESORES_SUCCESS';
export const REGISTER_EMAIL_PASS_SUCCESS = 'REGISTER_EMAIL_PASS_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const GET_TERMS_CONDITION_SUCCESS = 'GET_TERMS_CONDITION_SUCCESS';
export const GET_PRIVACY_POLICY_SUCCESS = 'GET_PRIVACY_POLICY_SUCCESS';
export const GET_CONTRACT_CONTENT_SUCCESS = 'GET_CONTRACT_CONTENT_SUCCESS';
export const SET_PROFILE_STATUS_SUCCESS = 'SET_PROFILE_STATUS_SUCCESS';
export const SET_RESET_PASSWORD_KEY = 'SET_RESET_PASSWORD_KEY';

// Keyboard
export const ADD_KEY_BOARD_ITEM = 'ADD_KEY_BOARD_ITEM';
export const EMPTY_KEY_BOARD_ITEMS = 'EMPTY_KEY_BOARD_ITEMS';


// Load spinner
export const LOADING = 'LOADING';
export const LOADED = 'LOADED';
export const TOGGLE_LOAD = 'TOGGLE_LOAD';

// Display
export const SHOW_ELEMENT = 'SHOW_ELEMENT';
export const HIDE_ELEMENT = 'HIDE_ELEMENT';
export const TOGGLE_ELEMENT = 'TOGGLE_ELEMENT';

// Commo Modal
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';

// Download
export const GET_DOWNLOAD_SUCCESS = 'GET_DOWNLOAD_SUCCESS';

// Opportunity
export const GET_OPPORTUNITY_SUCCESS = 'GET_OPPORTUNITY_SUCCESS';
export const GET_FONDEADOS_SUCCESS = 'GET_FONDEADOS_SUCCESS';
export const GET_EXITOS_SUCCESS = 'GET_EXITOS_SUCCESS';
export const GET_OPPORTUNITY_DETAIL_SUCCESS = 'GET_OPPORTUNITY_DETAIL_SUCCESS';

// Participation
export const GET_PARTICIPATION_SUCCESS = 'GET_PARTICIPATION_SUCCESS';
export const GET_PARTICIPATION_DETAIL_SUCCESS = 'GET_PARTICIPATION_DETAIL_SUCCESS';

// Investment
export const GET_INVESTMENT_SUCCESS = 'GET_INVESTMENT_SUCCESS';
export const GET_INVESTMENT_DETAIL_SUCCESS = 'GET_INVESTMENT_DETAIL_SUCCESS';

// Referral
export const GET_REFERRAL_SUCCESS = 'GET_REFERRAL_SUCCESS';
export const SET_REFERRAL_SUCCESS = 'SET_REFERRAL_SUCCESS';

// Investment
export const RESET_PROFILE = 'RESET_PROFILE';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_SCHEMA_SUCCESS = 'GET_PROFILE_SCHEMA_SUCCESS';

// PROFILE
export const SET_PROFILE_MSG_SUCCESS = 'SET_PROFILE_MSG_SUCCESS';

import React from 'react';
import PropTypes from 'prop-types';
import InvestmentItem from './components/InvestmentItem';

export const TabInvestments = ({
  access,
  data,
  downloadRequest,
  viewInvoiceDetail,
}) => (
  <div className="minHe50vh">
    <div className="w-100 font-size-24 font-weight-600 text-center">
      {'Inversiones'}
    </div>
    <div className="border_bottom_purple" />
    {data.map(item => (
      <InvestmentItem
        key={item.crmid}
        item={item}
        access={access}
        viewInvoiceDetail={viewInvoiceDetail}
        downloadRequest={downloadRequest}
      />
    ))}
  </div>
);

TabInvestments.propTypes = {
  access: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  downloadRequest: PropTypes.func,
  viewInvoiceDetail: PropTypes.func.isRequired,
};

TabInvestments.defaultProps = {
  downloadRequest: () => {},
};

export default TabInvestments;

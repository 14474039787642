import React from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, Zoom } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BottomFloatButton = ({
  label,
  icon,
  onHandleModal,
  enable,
}) => (
  <div className="sub-header_container">
    <Zoom in={enable}>
      <ButtonBase
        disabled={!enable}
        className="w-75 sub-header pt-2 pb-2"
        onClick={() => onHandleModal()}
      >
        <span className="pr-2">{label}</span>
        <FontAwesomeIcon icon={icon} />
      </ButtonBase>
    </Zoom>
  </div>
);

BottomFloatButton.propTypes = {
  enable: PropTypes.bool,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onHandleModal: PropTypes.func.isRequired,
};

BottomFloatButton.defaultProps = {
  enable: false,
};

export default BottomFloatButton;

/* eslint-disable camelcase */
/* eslint-disable radix */
import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Divider, ListItem } from '@material-ui/core';
import {
  faDollarSign,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toolsHelper } from '../../../helpers';

import HeaderProgress from '../../../components/HeaderProgress';

const FondeoItem = ({ item, onClick }) => {
  const {
    cf_1015,
    unit_price,
    qtyinstock,
  } = item;
  const total = Math.round(cf_1015 / unit_price);
  // @ts-ignore
  const faltan = Number.parseInt(total - qtyinstock);
  return (
    <>
      <ListItem
        button
        onClick={() => onClick(item.crmid)}
        className="d-flex flex-column"
      >
        <div className="flex-row d-flex w-100">
          <Avatar
            className="size-70"
            style={{ backgroundColor: '#d0d0ca' }}
            imgProps={{
              style: {
                transform: 'scale(1.8)',
              },
            }}
            src={item.images && item.images[0] ? item.images[0] : ''}
          />
          <div className="d-flex justify-content-between f-1">
            <div className="ml-2 position-relative">
              <div className="align-center position-relative">
                <div className="opportunity_title">
                  {item.productname.toUpperCase()}
                </div>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-end">
              <div
                className="opportunity_unitprice"
                style={{ lineHeight: 'normal' }}
              >
                <FontAwesomeIcon
                  className="font-size-14"
                  icon={faDollarSign}
                  fixedWidth
                />
                <span>{toolsHelper.formatMoney(item.unit_price, 0)}</span>
              </div>
              <div
                className="font-size-14 font-weight-500"
                style={{ lineHeight: 'normal' }}
              >
                <span className="text-secondary">{faltan}</span>
                /
                <span>{total}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row d-flex w-100">
          <HeaderProgress
            total={total}
            faltan={total}
            fondeo={Number.parseInt(cf_1015)}
            vendido={Number.parseInt(cf_1015)}
            withPart={false}
            small
          />
        </div>
      </ListItem>
      <Divider />
    </>
  );
};

FondeoItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default FondeoItem;

/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
import axios from 'axios';
import ConfigurationAPI from './ConfigurationAPI';

class DownloadAPI extends ConfigurationAPI {
  getDownloads = () => new Promise((resolve, reject) => axios
    .get(`${this.url}/list/downloads`, this.headers)
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    }));

  downloadPDF = downloadUrl => new Promise((resolve, reject) => axios
    .get(`${downloadUrl}`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/pdf',
        Authorization: `Bearer ${this.token}`,
      },
    })
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    }));
}

export default DownloadAPI;

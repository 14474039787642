/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TabDetail = ({ data }) => {
  const {
    cf_1025,
    cf_1027,
    cf_1029,
    cf_977,
    cf_1043,
    cf_989,
  } = data;
  let location = '';
  if (cf_977) {
    location = cf_977.replace(' ', '+').replace(', ', ',+').replace('#', '');
    location = location.replace(', ', ',+');
    location = location.replace('#', '');
    location = (`https://maps.google.it/maps?q=${location}&output=embed`);
  }
  return (
    <div className="px-4 pb-4 font_inver_deepGray">
      <div className="">
        <div className="w-100 font-size-24 font-weight-600 text-center">
          {'Detalles'}
        </div>
        <div className="border_bottom_purple" />
        <div className="font-size-24 font-weight-600 font-purple">
          <FontAwesomeIcon icon="building" />
          {' '}
          <span className="font-size-18">Adquisicion</span>
        </div>
        <div className="font-size-16 px-2 text-justify">{cf_1025}</div>
      </div>
      <div className="my-2">
        <div className="font-size-24 font-weight-600 font-purple">
          <FontAwesomeIcon icon="child" />
          {' '}
          <span className="font-size-18">Amenidades</span>
        </div>
        <div className="font-size-16 px-2 text-justify">{cf_1027}</div>
      </div>
      <div className="my-2">
        <div className="font-size-24 font-weight-600 font-purple">
          <FontAwesomeIcon icon="plug" />
          {' '}
          <span className="font-size-18">Servicios y areas recreativas</span>
        </div>
        <div className="font-size-16 px-2 text-justify">{cf_1029}</div>
      </div>
      <Divider />
      <div className="text-center pt-3 my-2">
        <div className="font-size-20 font-weight-700 font-purple">
          Paticipación en el Desarrollo
        </div>
        <div className="font-size-70 font-red">
          <FontAwesomeIcon icon="building" />
        </div>
      </div>
      <div className="d-flex mx-2 my-2">
        <div className="f-1 text-center font-weight-700">
          <div className="font-size-24 font-purple">
            {`${parseFloat(cf_989 || 0)}mt²`}
          </div>
          <span>{cf_1043}</span>
        </div>
      </div>
      <div className="maps-container my-2">
        {location !== '' && (
          <iframe title="gMap" name="gMap" src={location} />
        )}
      </div>
    </div>
  );
};

TabDetail.propTypes = {
  data: PropTypes.object,
};

TabDetail.defaultProps = {
  data: {},
};

export default TabDetail;

import React from 'react';
import PropTypes from 'prop-types';

const GoogleMapsSearchBox = ({ id, setRef, onChange, placeholder, value, required }) => (
  <div>
    <input
      id="id"
      type="text"
      name="searchBox"
      className="form-control form-control"
      ref={
        node => setRef(node)
      }
      onChange={onChange}
      required={required}
      defaultValue={value}
      placeholder={placeholder}
    />
  </div>
);

GoogleMapsSearchBox.propTypes = {
  required: PropTypes.bool,
  id: PropTypes.string.isRequired,
  setRef: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

GoogleMapsSearchBox.defaultProps = {
  required: false,
};

export default GoogleMapsSearchBox;

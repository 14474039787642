import React from 'react';
import { render } from 'react-dom';
import TagManager from 'react-gtm-module';
import * as serviceWorker from './serviceWorker';
import Root from './container/app';
import 'moment/locale/es';

const tagManagerArgs = {
  gtmId: 'GTM-MJ7XW8S',
};

TagManager.initialize(tagManagerArgs);


render(
  <Root />,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import {
  Tab,
  Tabs,
  AppBar,
  withStyles,
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderOverView from '../../../components/HeaderOverView';
import TabDetail from '../../../components/Tabs/TabDetails';
import TabFin from '../../../components/Tabs/TabFin';
import TabAdvance from '../../../components/Tabs/TabAdvance';
import TabParticipationsDone from '../../../components/Tabs/TabParticipationsDone';
import HeaderTop from '../../../components/HeaderTop';
import SubmitInvestmentDialog from '../../../components/SubmitInvestmentDialog';
import {
  validateStatus,
  submitContractAction,
  downloadContractAction,
  submitConfirmInvestment,
  getContractContentAction,
  fetchParticipationDetail,
  requestDownloadPDFInvoice,
} from '../../../redux/actions';
import WarningRegisterDialog from '../../../components/WarningRegisterDialog';
import BottomFloatButton from '../../../components/BottomFloatButton';

const ApartadasDetail = ({
  msg,
  access,
  classes,
  dispatch,
  history,
  match,
  participationDetail,
  profile_status,
  user_id,
  loading,
  contract_content,
}) => {
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const {
      params: { id },
    } = match;
    dispatch(fetchParticipationDetail(id));
    if (profile_status === 'must_sign_contract') {
      dispatch(getContractContentAction());
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onHandleModal = async () => {
    if (!open) await dispatch(validateStatus());
    await setOpen(!open);
  };

  const onSubmitForm = (data) => {
    dispatch(submitConfirmInvestment(data.checked, data.password, history));
    setOpen(false);
  };

  const redirectRegister = () => {
    if (open) history.push('/sign-up-step');
  };

  const downloadRequest = (id, url) => {
    dispatch(requestDownloadPDFInvoice(id, url));
  };

  const onDownload = () => {
    dispatch(downloadContractAction());
    setOpen(false);
  };

  const onSubmitContract = (p) => {
    dispatch(submitContractAction(p));
    setOpen(false);
  };

  if (!participationDetail) {
    return (
      <div className="h-100 d-flex align-items-center justify-content-center">
        {'No hay datos de la participación'}
      </div>
    );
  }
  const {
    proyecto,
    orders,
    listprice,
    estatus,
    qtyinstock,
  } = participationDetail;
  return (
    <div className={isMobile ? 'vw-100 d-flex flex-column' : 'maxWidth500px d-flex flex-column'}>
      <HeaderTop
        history={history}
        title={proyecto.productname}
        opportunityDetail={proyecto}
      />
      <HeaderOverView
        image={proyecto.images && proyecto.images[0] ? proyecto.images[0] : ''}
        row1={parseFloat(listprice || 0)}
        row2={proyecto.cf_1402 ? proyecto.cf_1402 : '0'}
        row3={proyecto.cf_1400 ? proyecto.cf_1400 : '0'}
        row4={estatus || '0'}
        row5={qtyinstock || '0'}
        row6={
          proyecto.sales_start_date ? proyecto.sales_start_date : '00/00/0000'
        }
        imageFloatButton={value !== 0}
        imageFloatButtonIcon="pen-alt"
        onHandleModal={onHandleModal}
      />
      <div className="d-flex flex-column p-0">
        <AppBar position="sticky" className="appBar_Navegation">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            classes={{
              indicator: classes.indicator,
            }}
          >
            <Tab
              className="tab"
              style={{ color: value === 0 ? '#662D91' : '#CCCCCC' }}
              icon={
                <FontAwesomeIcon className="font-size-18" icon="info-circle" />
              }
            />
            <Tab
              className="tab tab-left-border"
              style={{ color: value === 1 ? '#662D91' : '#CCCCCC' }}
              icon={
                <FontAwesomeIcon className="font-size-18" icon="chart-line" />
              }
            />
            <Tab
              className="tab tab-left-border"
              style={{ color: value === 2 ? '#662D91' : '#CCCCCC' }}
              icon={
                <FontAwesomeIcon className="font-size-18" icon="hard-hat" />
              }
            />
            <Tab
              className="tab tab-left-border"
              style={{ color: value === 3 ? '#662D91' : '#CCCCCC' }}
              icon={(
                <FontAwesomeIcon
                  className="font-size-18"
                  icon="file-signature"
                />
              )}
            />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis="x"
          resistance
          index={value}
          animateHeight={value > 0}
          enableMouseEvents
          onChangeIndex={index => setValue(index)}
        >
          <TabDetail data={proyecto} />
          <TabFin data={proyecto} />
          <TabAdvance data={proyecto} />
          <TabParticipationsDone
            access={access}
            data={orders}
            downloadRequest={downloadRequest}
          />
        </SwipeableViews>
      </div>
      {open
        && (profile_status === 'approved' ? (
          <SubmitInvestmentDialog
            open={open}
            data={orders}
            onHandleModal={onHandleModal}
            onSubmitForm={onSubmitForm}
            user_id={user_id}
          />
        ) : (
          <WarningRegisterDialog
            msg={msg}
            open={open}
            user_id={user_id}
            content={contract_content}
            onDownload={onDownload}
            access={access}
            profile_status={profile_status}
            onHandleModal={onHandleModal}
            onSubmitContract={onSubmitContract}
            redirectRegister={redirectRegister}
          />
        ))}
      <BottomFloatButton
        label="Confirma tu inversión"
        icon="pen-alt"
        onHandleModal={onHandleModal}
        enable={value === 0 && !loading}
      />
    </div>
  );
};
const styles = theme => ({
  indicator: {
    backgroundColor: '#662D91',
  },
});
const mapStateToProps = ({
  participation,
  auth,
  loads,
  profileReducer,
}) => {
  const { participationDetail } = participation;
  const {
    profile_status,
    user_id,
    token,
    token_type,
  } = auth.user;
  const { contract_content } = auth;
  const { loading } = loads;
  const { msg } = profileReducer;
  return {
    participationDetail,
    profile_status,
    user_id,
    loading,
    contract_content,
    access: { token, token_type },
    msg,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(ApartadasDetail));

/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import TabItem from '../TabItem';
import ImgThumbnail from '../../../../components/ImgThumbnail';

const Tab2 = ({ userType, profile }) => {
  const {
    razon_social,
    siccode,
    cf_1374,
    cf_1380,
    id_representante_legal,
    cf_1378,
    profesion,
    ns_fiel,
    moral_telefono,
    email1,
    acta_constitutiva,
    cedula_fiscal,
    poder_representante,
    fecha_nacimiento,
    pais_nacimiento,
    cf_1368,
    cf_1372,
    nombre_director,
    jerarquia_inferior,
    moral_consejo,
    ownership,
  } = profile;
  switch (userType) {
    case 'Nacional Física':
      return (
        <div className="p-2 minHe70vh">
          <TabItem title="Razón Social" label={razon_social} />
          <TabItem title="RFC" label={siccode} />
          <TabItem title="Tipo de identificación" label={cf_1374} />
          <TabItem title="Clave de elector" label={cf_1380} />
          <div className="border-bottom pb-1" style={{ width: '90%' }}>
            <div className="font-gray font-size-12">Identificacion oficial</div>
            <ImgThumbnail
              imgSrc={id_representante_legal}
              title="Identificación oficial"
              desc="Identificación única de la persona en cuestión"
              emptyPrimary="Identificación no disponible"
              emptySecondary="Puede que la identificación no haya sido subida aún al sistema, si es así favor de completar su registro."
              enableDownload
            />
          </div>
          <TabItem title="CURP" label={cf_1378} />
        </div>
      );
    case 'Extranjera Física':
      return (
        <div className="p-2">
          <TabItem title="Razón Social" label={razon_social} />
          <TabItem title="RFC" label={siccode} />
          <TabItem title="Tipo de identificación" label={cf_1374} />
          <TabItem title="Clave de elector" label={cf_1380} />
          <div className="border-bottom pb-1" style={{ width: '90%' }}>
            <div className="font-gray font-size-12">Identificacion oficial</div>
            <ImgThumbnail
              imgSrc={id_representante_legal}
              title="Identificación oficial"
              desc="Identificación única de la persona en cuestión"
              emptyPrimary="Identificación no disponible"
              emptySecondary="Puede que la identificación no haya sido subida aún al sistema, si es así favor de completar su registro."
              enableDownload
            />
          </div>
        </div>
      );
    case 'Nacional Moral':
      return (
        <div className="p-2">
          <TabItem title="Razón Social" label={razon_social} />
          <TabItem title="RFC" label={siccode} />
          <TabItem title="Giro mercantil, actividad u objeto social" label={profesion} />
          <TabItem title="Número de serie de la Firma Electrónica Avanzada" label={ns_fiel} />
          <TabItem title="Número telefónico del domicilio" label={moral_telefono} />
          <TabItem title="Correo" label={email1} />
          <div className="border-bottom pb-1">
            <div className="font-gray font-size-12">
              Acta constitutiva Actual
            </div>
            <div className="font-size-16 font-weight-600 pl-1" style={{ width: '90%' }}>
              <ImgThumbnail
                imgSrc={acta_constitutiva}
                title="Acta Constitutiva"
                desc="Acta constitutiva de la empresa en cuestión"
                emptyPrimary="Acta Constitutiva no disponible"
                emptySecondary="Puede que el Acta Constitutiva no haya sido subida aún al sistema, si es así favor de completar su registro."
                enableDownload
              />
            </div>
          </div>
          <div className="border-bottom pb-1">
            <div className="font-gray font-size-12">
              Cédula de identificacion fiscal
            </div>
            <div className="font-size-16 font-weight-600 pl-1" style={{ width: '90%' }}>
              <ImgThumbnail
                imgSrc={cedula_fiscal}
                title="Cédula de Identificación Fiscal"
                desc="Cédula de Identificación Fiscal de la empresa en cuestión"
                emptyPrimary="Cédula de Identificación Fiscal no disponible"
                emptySecondary="Puede que la Cédula de Identificación Fiscal no haya sido subida aún al sistema, si es así favor de completar su registro."
                enableDownload
              />
            </div>
          </div>
          <div className="border-bottom pb-1">
            <div className="font-gray font-size-12">
              Poderes del representante
            </div>
            <div className="font-size-16 font-weight-600 pl-1" style={{ width: '90%' }}>
              <ImgThumbnail
                imgSrc={poder_representante}
                title="Poderes del representante"
                desc="Poderes del representante en cuestión"
                emptyPrimary="Poderes del representante no disponible"
                emptySecondary="Puede que la Poderes del representante no haya sido subida aún al sistema, si es así favor de completar su registro."
                enableDownload
              />
            </div>
          </div>
          <TabItem title="Fecha de constitución" label={fecha_nacimiento} />
          <TabItem title="País de constitución" label={pais_nacimiento} />
          <TabItem title="Lugar de constitución" label={cf_1368} />
          <TabItem title="Nacionalidad" label={cf_1372} />
          <TabItem title="Nombre del director general" label={nombre_director} />
          <TabItem title="Jerarquía inmediata inferior" label={jerarquia_inferior} />
          <TabItem title="Nombres del consejo de Admin." label={moral_consejo} />
          <TabItem title="Nombre del propietario" label={ownership} />
        </div>
      );
    default:
      return null;
  }
};

Tab2.propTypes = {
  userType: PropTypes.string,
  profile: PropTypes.object,
};

Tab2.defaultProps = {
  userType: '',
  profile: {},
};

export default Tab2;

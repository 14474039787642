/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable camelcase */
import React from 'react';
import { Bounce } from 'react-reveal';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonBase } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { toolsHelper } from '../../../helpers';

const PaidInvestmentItem = ({ item, access, downloadRequest }) => {
  if (!item) return null;
  return (
    <Bounce>
      <div className="referral-item flex-row align-items-center w-100 border-bottom">
        <div className="d-flex justify-content-between align-items-center f-1">
          <div className="ml-2 position-relative">
            <div className="position-relative d-flex flex-column align-items-center">
              <div className="font-size-13 font-weight-600 font-purple">
                {`$${item ? toolsHelper.formatMoney(item.amount) : '-'}`}
              </div>
              <div className="font-size-12">
                {item ? item.date : '-'}
              </div>
            </div>
          </div>
          <div className="mr-1">
            <div className="pl-2 font-size-15 font-weight-600 text-right">
              {item ? item.reference : '-'}
            </div>
          </div>
        </div>
        <div className="confirm-icon-bg">
          {isMobile ? (
            <ButtonBase
              className="w-100 h-100"
              href={`pdf://send?token=${access.token}&type=${access.token_type}&id=${item.crmid}&url=${item.pdf}`}
            >
              <FontAwesomeIcon className="font-size-24 font_inver_red" icon="download" />
            </ButtonBase>
          ) : (
            <ButtonBase
              className="w-100 h-100"
              onClick={() => downloadRequest(item.crmid, item.pdf)}
            >
              <FontAwesomeIcon className="font-size-24 font_inver_red" icon="download" />
            </ButtonBase>
          )}
        </div>
      </div>
    </Bounce>
  );
};

PaidInvestmentItem.propTypes = {
  item: PropTypes.object.isRequired,
  access: PropTypes.object.isRequired,
  downloadRequest: PropTypes.func,
};

PaidInvestmentItem.defaultProps = {
  downloadRequest: () => {},
};

export default PaidInvestmentItem;

/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { toast } from 'react-toastify';
import {
  checkPasswordResetKey,
  requestChangePassword,
} from '../../redux/actions';

import ChangePassword from '../../components/ChangePassword';

const ResetPassword = ({
  auth,
  match,
  dispatch,
  loads,
  history,
}) => {
  React.useEffect(() => {
    const { params } = match;
    dispatch(checkPasswordResetKey(params.token));
    // eslint-disable-next-line
  }, []);

  return (
    <div className="root-container login-panel-background py-5">
      <div className="account-panel-width align-center position-absolute text-light">
        <ChangePassword
          auth={auth}
          isLoading={loads.loading}
          onSubmit={(pass) => {
            dispatch(requestChangePassword(pass, history));
          }}
        />
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  auth: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  loads: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

ResetPassword.defaultProps = {};

const mapStateToProps = state => ({
  auth: state.auth,
  loads: state.loads,
});

export default connect(mapStateToProps)(ResetPassword);

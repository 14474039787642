/* eslint-disable camelcase */
export const getRegistrationStep = (profile) => {
  const user = { ...profile };
  const { tipo_de_persona } = user;
  switch (tipo_de_persona) {
    case 'Nacional Física':
      if (user.ownership !== ''
        && user.cf_1130 !== ''
        && user.cf_1132 !== ''
        && user.phone !== ''
        && user.profesion !== ''
        && user.fecha_nacimiento !== ''
        && user.pais_nacimiento !== ''
        && user.entidad_nacimiento !== ''
        && user.cf_1368 !== ''
        && user.cf_1372 !== ''
        && user.cf_1370 !== ''
        && user.cf_1382 !== ''
        && (user.cf_1382 === 'Casado' ? (user.cf_1384 !== '') : true)
      ) {
        if (user.siccode !== ''
          && user.cf_1374 !== ''
          && user.cf_1380 !== ''
          && (user.id_representante_legal !== '' && user.id_representante_legal !== null)
          && user.cf_1378 !== ''
        ) {
          if (user.bill_street !== ''
            && user.bill_num_ext !== ''
            && user.bill_pobox !== ''
            && user.bill_city !== ''
            && user.bill_state !== ''
            && user.bill_country !== ''
            && user.bill_code !== ''
            && (user.comprobante_domicilio !== '' && user.comprobante_domicilio !== null)
          ) {
            if (user.cf_1348 !== ''
              && user.cf_1352 !== ''
              && user.cf_1350 !== ''
              && user.cf_1354 !== ''
            ) {
              if (user.cf_1356 !== ''
                && user.cf_1358 !== ''
                && user.cf_1360 !== ''
                && user.cf_1362 !== ''
                && user.cf_1364 !== ''
              ) {
                return 5;
              }
              return 4;
            }
            return 3;
          }
          return 2;
        }
        return 1;
      }
      return 0;
    case 'Nacional Moral':
      if (user.ownership !== ''
        && user.cf_1130 !== ''
        && user.id_representante_legal !== ''
      ) {
        if (user.razon_social !== ''
          && user.siccode !== ''
          && user.profesion !== ''
          && user.ns_fiel !== ''
          && user.moral_telefono !== ''
          && user.moral_email !== ''
          && user.acta_constitutiva !== ''
          && user.cedula_fiscal !== ''
          && user.poder_representante !== ''
          && user.fecha_nacimiento !== ''
          && user.cf_1368 !== ''
          && user.pais_nacimiento !== ''
          && user.cf_1372 !== ''
          && user.nombre_director !== ''
          && user.moral_jerarquia_inferior !== ''
          && user.moral_consejo !== ''
          && user.moral_propietario !== ''
        ) {
          if (user.bill_street !== ''
            && user.bill_num_ext !== ''
            && user.bill_pobox !== ''
            && user.bill_city !== ''
            && user.bill_state !== ''
            && user.bill_country !== ''
            && user.bill_code !== ''
            && user.comprobante_domicilio !== ''
          ) {
            if (user.cf_1348 !== ''
              && user.cf_1352 !== ''
              && user.cf_1350 !== ''
              && user.cf_1354 !== ''
            ) {
              if (user.cf_1356 !== ''
                && user.cf_1358 !== ''
                && user.cf_1360 !== ''
                && user.cf_1362 !== ''
                && user.cf_1364 !== ''
              ) {
                return 5;
              }
              return 4;
            }
            return 3;
          }
          return 2;
        }
        return 1;
      }
      return 0;
    default:
      break;
  }
};

export default {
  getRegistrationStep,
};

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Bounce } from 'react-reveal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonBase } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { toolsHelper } from '../../../helpers';

export const LiquidationItem = ({ item, access, downloadRequest }) => {
  const {
    balance,
    crmid,
    date,
    payment_status,
    pdf,
    quantity,
    total,
  } = item;
  return (
    <Bounce>
      <div className="referral-item flex-row w-100 border-bottom">
        <div
          className="commission-icon-bg border-bottom"
          style={{
            backgroundColor:
              payment_status === 'paid'
                ? '#662D91'
                : payment_status === 'unpaid'
                  ? ''
                  : '#FBB03B',
          }}
        >
          <div className="referral-item-number">
            <FontAwesomeIcon
              className="font-white font-size-24"
              icon={
                payment_status === 'paid'
                  ? 'check'
                  : payment_status === 'unpaid'
                    ? 'circle'
                    : 'clock'
              }
            />
          </div>
        </div>
        <div className="referral-item-number-bg">
          <div className="referral-item-number">{quantity}</div>
        </div>
        <div className="d-flex justify-content-between f-1">
          <div className="ml-2 position-relative">
            <div className="align-center position-relative">
              <div
                className="font-size-16 font-weight-600"
                style={{ color: payment_status === 'paid' ? '#662D91' : '' }}
              >
                {`$${toolsHelper.formatMoney(total)}`}
              </div>
              <div className="font-size-13">{date}</div>
            </div>
          </div>
          <div className="mr-2 position-relative">
            <div className="align-center position-relative">
              <div className="font-size-14 font-red text-right">
                {`-$ ${parseFloat(balance) * -1}`}
              </div>
              <div className="font-size-13 text-right">
                {payment_status === 'paid' ? ' ' : 'Saldo'}
              </div>
            </div>
          </div>
        </div>
        <div className="confirm-icon-bg border-bottom">
          {isMobile ? (
            <ButtonBase
              className="w-100 h-100"
              href={`pdf://send?token=${access.token}&type=${access.token_type}&id=${crmid}&url=${pdf}`}
            >
              <FontAwesomeIcon className="font-size-24 font_inver_red" icon="download" />
            </ButtonBase>
          ) : (
            <ButtonBase
              className="w-100 h-100"
              onClick={() => downloadRequest(crmid, pdf)}
            >
              <FontAwesomeIcon className="font-size-24 font_inver_red" icon="download" />
            </ButtonBase>
          )}
        </div>
      </div>
    </Bounce>
  );
};

LiquidationItem.propTypes = {
  access: PropTypes.object.isRequired,
  downloadRequest: PropTypes.func,
  item: PropTypes.object.isRequired,
};

LiquidationItem.defaultProps = {
  downloadRequest: () => {},
};

export default LiquidationItem;

/* eslint-disable radix */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ButtonBase } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from 'react-device-detect';
import { toolsHelper as tools } from '../../../helpers';

const ConfirmInvestmentItem = ({
  item,
  access,
  downloadRequest,
}) => {
  const {
    quantity,
    invoiceid,
    invoice,
    invoicedate,
    total,
    pdf,
  } = item;
  const qty = Number.parseInt(quantity);
  const date = moment(invoicedate).fromNow();
  return (
    <div className="referral-item flex-row w-100 border-bottom">
      <div className="referral-item-number-bg">
        <div className="referral-item-number">{qty}</div>
      </div>
      <div className="d-flex justify-content-between f-1">
        <div className="ml-2 position-relative">
          <div className="align-center position-relative">
            <div className="font-size-16 font-weight-600">{invoice}</div>
            <div className="font-size-11">{`${invoicedate} (${date})`}</div>
          </div>
        </div>
        <div className="mr-2 position-relative">
          <div className="align-center position-relative">
            <div className="font-size-14 font-weight-600 text-right">
              {`$ ${tools.formatMoney(total)}`}
            </div>
          </div>
        </div>
      </div>
      <div className="confirm-icon-bg border-bottom">
        {isMobile ? (
          <ButtonBase
            className="w-100 h-100"
            href={`pdf://send?token=${access.token}&type=${access.token_type}&id=${invoiceid}&url=${pdf}`}
          >
            <FontAwesomeIcon className="font-size-24 font_inver_red" icon="download" />
          </ButtonBase>
        ) : (
          <ButtonBase
            className="w-100 h-100"
            onClick={() => downloadRequest(invoiceid, pdf)}
          >
            <FontAwesomeIcon className="font-size-24 font_inver_red" icon="download" />
          </ButtonBase>
        )}
      </div>
    </div>
  );
};

ConfirmInvestmentItem.propTypes = {
  downloadRequest: PropTypes.func,
  item: PropTypes.object.isRequired,
  access: PropTypes.object.isRequired,
};

ConfirmInvestmentItem.defaultProps = {
  downloadRequest: () => {},
};

export default ConfirmInvestmentItem;

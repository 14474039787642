/* eslint-disable camelcase */
import {
  GET_OPPORTUNITY_DETAIL_SUCCESS,
  GET_OPPORTUNITY_SUCCESS,
  GET_FONDEADOS_SUCCESS,
  GET_EXITOS_SUCCESS,
} from '../actionTypes';

const defaultState = () => ({
  opportunities: [],
  fondeos: [],
  exitos: [],
  opportunityDetail: {},
});

const initialState = { ...defaultState() };

export default function (state = initialState, action) {
  const { type } = action;
  switch (type) {
    case GET_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        opportunities: action.payload,
      };
    case GET_FONDEADOS_SUCCESS:
      return {
        ...state,
        fondeos: action.payload,
      };
    case GET_EXITOS_SUCCESS:
      return {
        ...state,
        exitos: action.payload,
      };
    case GET_OPPORTUNITY_DETAIL_SUCCESS:
      return {
        ...state,
        opportunityDetail: action.payload,
      };
    default:
      return state;
  }
}

/* eslint-disable react/destructuring-assignment */
/* eslint-disable radix */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Modal, ModalHeader } from 'reactstrap';
import Avatar from '@material-ui/core/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonBase, Collapse } from '@material-ui/core';
import { toolsHelper } from '../../helpers';

class PutInvestmentDialog extends React.Component {
  static propTypes = {
    fondeo: PropTypes.number,
    open: PropTypes.bool.isRequired,
    onHandleModal: PropTypes.func.isRequired,
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onSubmitForm: PropTypes.func.isRequired,
    unit_price: PropTypes.number,
    total: PropTypes.number,
    faltan: PropTypes.number,
  }

  static defaultProps = {
    fondeo: 0,
    unit_price: 0,
    total: 0,
    faltan: 0,
  }

  state = {
    quantity: 1,
    limit: (this.props.fondeo * 0.05),
  };

  onPlusQuantity = (q) => {
    const { faltan, unit_price, total } = this.props;
    const { limit } = this.state;
    const res = (unit_price * (q + 1));
    if (total - (faltan + q) === 0) return;
    if (res > limit) {
      toast.info(`Una disculpa, no puede invertir más del 5% ($ ${limit}) del valor de fondeo del proyecto. Lo invitamos a diversificar en otro proyectos.`);
      return;
    }
    this.setState({ quantity: q + 1 });
  };

  onMinusQuantity = (q) => {
    if (q === 1) return;
    this.setState({ quantity: q - 1 });
  };

  render() {
    const {
      open,
      onHandleModal,
      image,
      title,
      onSubmitForm,
      unit_price,
      total,
      faltan,
    } = this.props;
    const { quantity } = this.state;
    const logo = `${process.env.PUBLIC_URL}/img/logo-inverspot-white.png`;
    return (
      <Modal toggle={onHandleModal} isOpen={open}>
        <ModalHeader className="modal-box-header bg-purple text-white text-center">
          <div className="w-75 m-auto">
            <img src={logo} alt="" />
          </div>
        </ModalHeader>
        <div className="modal-box-content">
          <div className="w-100">
            <div className="row mb-2 pb-2 border-bottom">
              <Avatar
                className="size-50"
                style={{ backgroundColor: '#d0d0ca' }}
                src={image}
              />
              <div className="d-table ml-2" style={{ height: '50px' }}>
                <div className="d-table-cell vertical-align-center font-size-22 font-weight-600">
                  {title}
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-6 mb-2">
                <div className="font-size-16">Paticipaciones</div>
                <div className="modal-add-div">
                  <ButtonBase
                    className="font-size-16 font-weight-800 font-purple position-relative"
                    onClick={() => this.onMinusQuantity(quantity)}
                  >
                    <FontAwesomeIcon
                      icon="minus"
                      className="position-absolute align-center"
                    />
                  </ButtonBase>
                  <div className="font-size-28 font-weight-600 font-red d-flex justify-content-center align-items-center">
                    {quantity}
                  </div>
                  <ButtonBase
                    className="font-size-16 font-weight-800 font-purple position-relative"
                    onClick={() => this.onPlusQuantity(quantity)}
                  >
                    <FontAwesomeIcon
                      icon="plus"
                      className="position-absolute align-center"
                    />
                  </ButtonBase>
                </div>
                <Collapse in={(faltan - quantity) > 0} className="w-100">
                  <div className="font-size-10">
                    {`Quedan ${total - (faltan + quantity)} de ${total}`}
                  </div>
                </Collapse>
                <Collapse in={total - (faltan + quantity) === 0} className="w-100">
                  <div className="font-size-10">
                    Elegiste todas las participaciones restantes
                  </div>
                </Collapse>
              </div>
              <div className="col-6 mb-2">
                <div className="font-size-16">Precio unitario</div>
                <div className="font-size-26 font-weight-600 font-red">
                  {`$ ${toolsHelper.formatMoney(unit_price, 0)}`}
                </div>
              </div>
              <div className="col-12 mb-2 pb-2 border-bottom">
                <div className="font-size-16">Total</div>
                <div className="font-size-28 font-weight-600 font-red">
                  {toolsHelper.formatMoney(unit_price * quantity, 0)}
                </div>
              </div>
              <div className="col-12">
                <ButtonBase
                  className="origin-button px-2"
                  disabled={total - (faltan + quantity) < 0}
                  onClick={() => setTimeout(() => onSubmitForm(quantity), 500)}
                >
                  <span />
                  Apártalo
                  <FontAwesomeIcon icon="piggy-bank" />
                </ButtonBase>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default PutInvestmentDialog;

/* eslint-disable camelcase */
import {
  RESET_PROFILE,
  GET_PROFILE_SUCCESS,
  SET_PROFILE_MSG_SUCCESS,
  GET_PROFILE_SCHEMA_SUCCESS,
} from '../actionTypes';

const defaultState = () => ({
  scheme: {},
  profile: {},
  redirect: false,
  msg: '',
});

const initialState = { ...defaultState()};

const setMsg = (state, msg) => {
  const profileReducer = { ...state }; 
  profileReducer.msg = msg;
  return profileReducer;
};

export default function (state = initialState, action) {
  const { type } = action;
  switch (type) {
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_PROFILE_SCHEMA_SUCCESS:
      return {
        ...state,
        scheme: action.payload,
      };
    case SET_PROFILE_MSG_SUCCESS:
      return setMsg(state, action.msg);
    case RESET_PROFILE:
      return defaultState();
    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import {
  Menu,
  MenuItem,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SocialDropDownMenu = ({
  open,
  onClose,
  anchorEl,
  productname,
}) => {
  const text = `Hola que tal desde la app de Inverspot, requiero información sobre "${productname}"`;
  const message = `Que tal, requiero información sobre ${productname}`;
  return (
    <>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        disableAutoFocusItem
        className="social-menu"
        MenuListProps={{
          className: 'social_paper',
        }}
      >
        <MenuItem>
          <a href="tel:015580006555">
            <FontAwesomeIcon icon="phone" />
          </a>
        </MenuItem>
        <MenuItem>
          <a href={`whatsapp://send?text=${text}!&phone=+5215582094942`}>
            <FontAwesomeIcon icon={['fab', 'whatsapp']} />
          </a>
        </MenuItem>
        <MenuItem>
          <a
            href={`mailto:hola@inverspot.mx?subject=Información desde la app&body=${message}`}
          >
            <FontAwesomeIcon icon="envelope" />
          </a>
        </MenuItem>
      </Menu>
    </>
  );
};

SocialDropDownMenu.propTypes = {
  open: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
  productname: PropTypes.string,
};

SocialDropDownMenu.defaultProps = {
  productname: '',
};

export default SocialDropDownMenu;

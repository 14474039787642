/* eslint-disable import/prefer-default-export */
/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import { loading, loaded } from './loadActions';
import {
  unauthenticate,
  setProfile_Status,
  getContractContentAction,
} from './authUserActions';
import ProfileAPI from '../../api/ProfileAPI';
import {
  RESET_PROFILE,
  GET_PROFILE_SUCCESS,
  SET_PROFILE_MSG_SUCCESS,
  GET_PROFILE_SCHEMA_SUCCESS,
} from '../actionTypes';

export function fetchProfileDetail() {
  return async (dispatch) => {
    const profileAPI = new ProfileAPI();
    dispatch(loading(''));
    try {
      const response = await profileAPI.getProfile();
      const { success, message, result } = response.data;
      if (success) {
        dispatch({
          type: GET_PROFILE_SUCCESS,
          payload: {
            profile: result,
          },
        });
      } else {
        toast.error(message);
      }
    } catch (e) {
      console.log({ fetchOpportunityListError: e });
      if (e.response && e.response.status === 401) {
        toast.error('Necesitar iniciar sesión de nuevo');
        dispatch(unauthenticate());
        return;
      }
      toast.error('Error en la API');
    } finally {
      dispatch(loaded('authUser'));
    }
  };
}

export function fetchProfileScheme() {
  return async (dispatch) => {
    const profileAPI = new ProfileAPI();
    dispatch(loading(''));
    try {
      const response = await profileAPI.getProfileScheme();
      console.log({ fetchProfileDetail: response });
      const { success, message, result } = response.data;
      if (success) {
        console.log('result: ', result);
        dispatch({
          type: GET_PROFILE_SCHEMA_SUCCESS,
          payload: result,
        });
      } else {
        toast.error(message);
      }
    } catch (e) {
      console.log({ fetchOpportunityListError: e });
      if (e.response && e.response.status === 401) {
        toast.error('Necesitar iniciar sesión de nuevo');
        dispatch(unauthenticate());
        return;
      }
      toast.error('Error en la API');
    } finally {
      dispatch(loaded('authUser'));
    }
  };
}

export function validateAndFinishRegister(params, history = {}, redirectTo = '') {
  return async (dispatch) => {
    const profileAPI = new ProfileAPI();
    dispatch(loading(''));
    try {
      await profileAPI.setProfileParams(params).then((res) => {
        console.log('res: ', res);
        const { success, message, result } = res.data;
        if (success) {
          const redirect = true;
          toast.info('Validando datos');
          dispatch({
            payload: {
              profile: result,
              redirect,
            },
            type: GET_PROFILE_SUCCESS,
          });
          profileAPI.getProfileOk().then((valid) => {
            if (valid.data.success) {
              dispatch(setProfile_Status('verifying'));
              if (redirect && Object.keys(history).length > 0 && redirectTo !== '') {
                toast.success('Datos guardados, confirmación via correo electrónico');
                history.push(redirectTo);
              } else {
                toast.success('Guardado');
              }
            } else {
              toast.error(valid.data.message.error || valid.data.message.warning);
            }
          }).catch(async (err) => {
            if (err.data) {
              if (err.status === 409) {
                await dispatch(setProfile_Status('verifying'));
                history.push('/app/home-page');
                toast.success('Bien hecho - cuando tu perfil sea verificado, Inverspot te notificara via correo electrónico.');
              } else if (err.status === 403) {
                dispatch(setProfile_Status('verifying'));
                toast.info(err.data.message.error || err.data.message.warning);
                history.push('/app/opportunity-investment');
              } else {
                toast.warn(err.data.message.error || err.data.message.warning);
              }
            } else {
              toast.info('Error de la app type: validate - Contacte al Administrador para mas informaciópn, intente salir y volver a entrar a la app');
            }
          });
        } else {
          toast.error(message);
        }
      }).catch((err) => {
        console.log('profileApi - setProfileParams err: ', err);
      });
    } catch (err) {
      console.log({ fetchOpportunityListError: err });
      if (err.response && err.response.status === 401) {
        toast.error('Necesitar iniciar sesión de nuevo');
        dispatch(unauthenticate());
        return;
      }
    } finally {
      dispatch(loaded('authUser'));
    }
  };
}

export function postProfileDetail(params, redirect = false, history = {}, redirectTo = '') {
  return async (dispatch) => {
    const profileAPI = new ProfileAPI();
    dispatch(loading(''));
    try {
      await profileAPI.setProfileParams(params).then((res) => {
        const { success, message, result } = res.data;
        if (success) {
          dispatch({
            payload: {
              profile: result,
              redirect,
            },
            type: GET_PROFILE_SUCCESS,
          });
          if (redirect && Object.keys(history).length > 0 && redirectTo !== '') {
            toast.success('Guardado y enviando');
            history.push(redirectTo);
          } else {
            toast.success('Guardado');
          }
        } else {
          toast.error(message);
        }
      }).catch((err) => {
        console.log('profileApi - setProfileParams err: ', err);
      });
    } catch (err) {
      console.log({ fetchOpportunityListError: err });
      if (err.response && err.response.status === 401) {
        toast.error('Necesitar iniciar sesión de nuevo');
        dispatch(unauthenticate());
        return;
      }
    } finally {
      dispatch(loaded('authUser'));
    }
  };
}

export function setMsg(msg) {
  return async dispatch => (dispatch({
    type: SET_PROFILE_MSG_SUCCESS,
    msg,
  }));
}

export function validateStatus() {
  return async (dispatch, getState) => {
    const profileAPI = new ProfileAPI();
    dispatch(loading(''));
    try {
      await profileAPI.getProfileOk().then(async (result) => {
        const { data } = result;
        if (data.success) {
          const { auth } = getState();
          const { user } = auth;
          const { profile_status } = data;
          if (user.profile_status !== profile_status) {
            toast.info('Su estatus de su perfil a cambiado');
            await dispatch(setProfile_Status(profile_status));
          }
          await dispatch(loaded('authUser'));
        } else {
          toast.error(data.message.error || data.message.warning);
          await dispatch(loaded('authUser'));
        }
      }).catch();
    } catch (err) {
      if (err.status && err.status === 409) {
        await dispatch(setMsg(err.data.message.error || err.data.message.warning));
        await dispatch(setProfile_Status(err.data.profile_status));
        if (err.data.profile_status === 'must_sign_contract') {
          await dispatch(getContractContentAction());
        }
        await dispatch(loaded('authUser'));
        return;
      }
      if (err.status && err.status === 401) {
        toast.error('Necesitar iniciar sesión de nuevo');
        await dispatch(unauthenticate());
        await dispatch(loaded('authUser'));
      }
    }
  };
}

export function changeProfilePassword(obj) {
  return async (dispatch) => {
    const profileAPI = new ProfileAPI();
    dispatch(loading(''));
    try {
      await profileAPI.setChangePassword(obj).then(async (result) => {
        const { data } = result;
        if (data.success) {
          toast.success(data.message.success);
          await dispatch(loaded('authUser'));
        } else {
          toast.error(data.message.error || data.message.warning);
          await dispatch(loaded('authUser'));
        }
      }).catch(async (err) => {
        const { response: { data } } = err;
        toast.error(data.message.error || data.message.warning);
        await dispatch(loaded('authUser'));
      });
    } catch (err) {
      if (err.status && err.status === 409) {
        await dispatch(setMsg(err.data.message.error || err.data.message.warning));
        await dispatch(setProfile_Status(err.data.profile_status));
        if (err.data.profile_status === 'must_sign_contract') {
          await dispatch(getContractContentAction());
        }
        await dispatch(loaded('authUser'));
        return;
      }
      if (err.status && err.status === 401) {
        toast.error('Necesitar iniciar sesión de nuevo');
        await dispatch(unauthenticate());
        await dispatch(loaded('authUser'));
      }
    }
  };
}

export function editProfile(obj) {
  return async (dispatch) => {
    const profileAPI = new ProfileAPI();
    dispatch(loading(''));
    try {
      await profileAPI.setProfileEdit(obj).then(async (result) => {
        const { data } = result;
        if (data.success) {
          toast.success(data.message.success || 'Campo editado correctamente');
          await dispatch(fetchProfileDetail());
          await dispatch(validateStatus());
          await dispatch(loaded('authUser'));
        } else {
          toast.error(data.message.error || data.message.warning);
          await dispatch(loaded('authUser'));
        }
      }).catch(async (err) => {
        const { response: { data } } = err;
        toast.error(data.message.error || data.message.warning);
        await dispatch(loaded('authUser'));
      });
    } catch (err) {
      if (err.status && err.status === 409) {
        await dispatch(setMsg(err.data.message.error || err.data.message.warning));
        await dispatch(setProfile_Status(err.data.profile_status));
        if (err.data.profile_status === 'must_sign_contract') {
          await dispatch(getContractContentAction());
        }
        await dispatch(loaded('authUser'));
        return;
      }
      if (err.status && err.status === 401) {
        toast.error('Necesitar iniciar sesión de nuevo');
        await dispatch(unauthenticate());
        await dispatch(loaded('authUser'));
      }
    }
  };
}

export function restartProfile() {
  return {
    type: RESET_PROFILE,
  };
}

/* eslint-disable camelcase */
/* eslint-disable radix */
import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Divider, ListItem } from '@material-ui/core';
import { faPercentage, faCalendarMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FondeoItem = ({ item, onClick }) => (
  <>
    <ListItem
      button
      onClick={() => onClick(item.crmid)}
      className="d-flex flex-column"
    >
      <div className="flex-row d-flex w-100">
        <Avatar
          className="size-70"
          style={{ backgroundColor: '#d0d0ca' }}
          imgProps={{
            style: {
              transform: 'scale(1.8)',
            },
          }}
          src={item.images && item.images[0] ? item.images[0] : ''}
        />
        <div className="d-flex justify-content-between f-1">
          <div className="ml-2 position-relative">
            <div className="align-center position-relative">
              <div className="opportunity_title">
                {item.productname.toUpperCase()}
              </div>
              <div className="d-flex justify-content-between font-size-14">
                <div className="font-gray mr-2">
                  <FontAwesomeIcon icon={faCalendarMinus} fixedWidth />
                  <span>{`${item.cf_981} meses`}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-end">
            <div
              className="opportunity_unitprice"
              style={{ lineHeight: 'normal' }}
            >
              <span>{item.cf_985.substring(0, 4)}</span>
              <FontAwesomeIcon
                className="font-size-18"
                icon={faPercentage}
                fixedWidth
              />
            </div>
          </div>
        </div>
      </div>
    </ListItem>
    <Divider />
  </>
);

FondeoItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default FondeoItem;

/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
import axios from 'axios';
import ConfigurationAPI from './ConfigurationAPI';

class OpportunityAPI extends ConfigurationAPI {
  getOpportunities = () => new Promise((resolve, reject) => axios.get(`${this.url}/list/proyectos/activos`, this.headers)
    .then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    }));

    getFondeos = () => new Promise((resolve, reject) => axios.get(`${this.url}/list/proyectos/fondeados`, this.headers)
      .then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      }));

    getExitos = () => new Promise((resolve, reject) => axios.get(`${this.url}/list/proyectos/casos-de-exito`, this.headers)
      .then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      }));

  opportunityDetail = id => new Promise((resolve, reject) => axios.get(`${this.url}/proyectos/${id}`, this.headers)
    .then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    }));

  requestApartar = body => new Promise((resolve, reject) => axios.post(`${this.url}/apartar`, body, this.headers)
    .then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    }));
}

export default OpportunityAPI;

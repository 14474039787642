import React from 'react';
import PropTypes from 'prop-types';
import {
  LinearProgress,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { toolsHelper } from '../../helpers';

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: '#CCCCCC',
  },
  bar: {
    backgroundColor: '#60269E',
  },
})(LinearProgress);

const HeaderProgress = ({
  total,
  fondeo,
  faltan,
  vendido,
  withPart,
  small,
}) => {
  const perc = ((vendido / fondeo) * 100);
  return (
    <div className={small ? 'header_progress_s' : 'header_progress'}>
      {withPart && (
        <div className="w-100 font-size-16 px-1 font-weight-700">
          <span className="text-secondary">{faltan}</span>
          {'/'}
          <span>{total}</span>
          <span className="ml-1 text-secondary font-size-12 font-weight-400">
            {'Participaciones'}
          </span>
        </div>
      )}
      <div className="w-100 py-1">
        <BorderLinearProgress
          value={perc}
          variant="determinate"
        />
      </div>
      <div className="w-100 d-flex align-items-center justify-content-between">
        <span className="font-weight-700 font-size-16">{`$${toolsHelper.formatMoney(vendido)}`}</span>
        <span className="font-weight-700 font-size-16">{`$${toolsHelper.formatMoney(fondeo)}`}</span>
      </div>
    </div>
  );
};

HeaderProgress.propTypes = {
  total: PropTypes.number,
  fondeo: PropTypes.number,
  faltan: PropTypes.number,
  vendido: PropTypes.number,
  withPart: PropTypes.bool,
  small: PropTypes.bool,
};

HeaderProgress.defaultProps = {
  total: 0,
  fondeo: 0,
  faltan: 0,
  vendido: 0,
  withPart: true,
  small: false,
};

export default HeaderProgress;

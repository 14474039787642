import React from 'react';
import PropTypes from 'prop-types';
import { ButtonBase } from '@material-ui/core';
import { Collections } from '@material-ui/icons';

const TimelineItem = ({ data, onClick }) => (
  <ButtonBase
    className="timeline-item block-outline"
  >
    <div className="timeline-item-content">
      <div className="timeline-mask" />
      <span className="circle" />
      <div className="d-flex w-100 justify-content-center flex-column">
        <div className="timeline-title">
          {data.label}
          {data.images.length > 0 && (<Collections className="ml-2 font-size-10" />)}
        </div>
        <div className="timeline-description">{data.description ? data.description : 'Sin descripción'}</div>
      </div>
      {data.images.length > 0 && (
        <ButtonBase
          className="timeline_button"
          onClick={() => onClick(data.label, data.description, data.images)}
        >
          <Collections />
        </ButtonBase>
      )}
    </div>
  </ButtonBase>
);

TimelineItem.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

TimelineItem.defaultProps = {
  onClick: () => {},
};

export default TimelineItem;

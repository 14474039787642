import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const InputSelect = ({
  data,
  onChange,
  value,
  name,
  inputId,
  className,
  placeholder,
  singleValueStyle,
  controlStyle,
}) => (
  <Select
    tabIndex="0"
    onChange={onChange}
    value={value}
    options={data}
    placeholder={placeholder}
    inputId={inputId}
    className={className}
    name={name}
    style={{
      singleValue: base => ({
        ...base,
        ...singleValueStyle,
      }),
      control: base => ({
        ...base,
        ...controlStyle,
      }),
    }}
  />
);

InputSelect.propTypes = {
  className: PropTypes.string,
  controlStyle: PropTypes.object,
  data: PropTypes.array.isRequired,
  inputId: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  singleValueStyle: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

InputSelect.defaultProps = {
  className: '',
  controlStyle: {},
  inputId: 'id_search_component',
  name: '',
  onChange: () => {},
  placeholder: '',
  singleValueStyle: {},
  value: '',
};

export default InputSelect;

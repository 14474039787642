/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import LiquidationItem from './components/LiquidationItem';

export const TabLiquidations = ({
  access,
  data,
  downloadRequest,
}) => (
  <div className="">
    <div className="w-100 font-size-24 font-weight-600 text-center">
      {'Liquidaciones'}
    </div>
    <div className="border_bottom_purple" />
    {data.map(item => (
      <LiquidationItem
        key={item.crmid}
        item={item}
        access={access}
        downloadRequest={downloadRequest}
      />
    ))}
  </div>
);

TabLiquidations.propTypes = {
  access: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  downloadRequest: PropTypes.func,
};

TabLiquidations.defaultProps = {
  downloadRequest: () => {},
};

export default TabLiquidations;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Bounce } from 'react-reveal';
import { ButtonBase } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from 'react-device-detect';
import { toolsHelper } from '../../../helpers';

const PaidLiquidationItem = ({ item, access, downloadRequest }) => {
  const cash_flow = { ...item };
  if (!cash_flow) return null;
  const now = moment(cash_flow.date).fromNow();
  const link = `pdf://send?token=${access.token}&type=${access.token_type}&id=${cash_flow.crmid}&url=${cash_flow.pdf}`;
  return (
    <Bounce>
      <div className="referral-item flex-row align-items-center w-100 border-bottom">
        <div className="d-flex justify-content-between f-1">
          <div className="ml-2 position-relative">
            <div className="align-center position-relative">
              <div className="font-size-18 font-weight-600 font-purple">
                {`$ ${cash_flow ? toolsHelper.formatMoney(cash_flow.amount) : '-'}`}
              </div>
              <div className="font-size-14">
                {cash_flow ? `${cash_flow.date} (${now})` : '-'}
              </div>
            </div>
          </div>
          <div className="mr-2">
            <div className="mt-1 font-size-18 text-right">
              {cash_flow ? cash_flow.reference : '-'}
            </div>
          </div>
        </div>
        <div className="confirm-icon-bg">
          {isMobile ? (
            <ButtonBase className="w-100 h-100" href={link}>
              <FontAwesomeIcon className="font-size-24 font_inver_red" icon="download" />
            </ButtonBase>
          ) : (
            <ButtonBase className="w-100 h-100" onClick={() => downloadRequest(cash_flow.crmid, cash_flow.pdf)}>
              <FontAwesomeIcon className="font-size-24 font_inver_red" icon="download" />
            </ButtonBase>
          )}
        </div>
      </div>
    </Bounce>
  );
};

PaidLiquidationItem.propTypes = {
  item: PropTypes.object.isRequired,
  access: PropTypes.object.isRequired,
  downloadRequest: PropTypes.func,
};

PaidLiquidationItem.defaultProps = {
  downloadRequest: () => {},
};

export default PaidLiquidationItem;

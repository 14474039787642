import React from 'react';
import PropTypes from 'prop-types';

const TabItem = ({
  label,
  title,
}) => (
  <div className="border-bottom py-1">
    <div className="font-gray font-size-12">{title}</div>
    <div className="font-size-16 font-weight-600 pl-1">
      {label || '-'}
    </div>
  </div>
);

TabItem.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
};

TabItem.defaultProps = {
  label: '-',
  title: '',
};

export default TabItem;

/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import { loading, loaded } from './loadActions';
import {
  GET_OPPORTUNITY_DETAIL_SUCCESS,
  GET_OPPORTUNITY_SUCCESS,
  GET_FONDEADOS_SUCCESS,
  GET_EXITOS_SUCCESS,
} from '../actionTypes';
import OpportunityAPI from '../../api/OpportunityAPI';
import { unauthenticate } from './authUserActions';
import { opportunityHelper } from '../../helpers';

export function fetchOpportunityList() {
  return async (dispatch) => {
    const opportunityAPI = new OpportunityAPI();
    dispatch(loading(''));
    try {
      const response = await opportunityAPI.getOpportunities();
      const { success, message, result } = response.data;
      const res = opportunityHelper.normalizeOpportunities(result);
      if (success) {
        dispatch({
          type: GET_OPPORTUNITY_SUCCESS,
          payload: res,
        });
      }
      else {
        toast.error(message);
      }
    }

    catch (e) {
      console.log({ fetchOpportunityListError: e });
      if (e.response && e.response.status === 401) {
        toast.error('Una disculpa, necesita ingresar de nuevo');
        dispatch(unauthenticate());
        return;
      }
      toast.error('Error en la API');
    }

    finally {
      dispatch(loaded('authUser'));
    }
  };
}

export function fetchFondeosList() {
  return async (dispatch) => {
    const opportunityAPI = new OpportunityAPI();
    dispatch(loading(''));
    try {
      const response = await opportunityAPI.getFondeos();
      const { success, message, result } = response.data;
      const res = opportunityHelper.normalizeOpportunities(result);
      if (success) {
        dispatch({
          type: GET_FONDEADOS_SUCCESS,
          payload: res,
        });
      }
      else {
        toast.error(message);
      }
    }

    catch (e) {
      console.log({ fetchOpportunityListError: e });
      if (e.response && e.response.status === 401) {
        toast.error('Una disculpa, necesita ingresar de nuevo');
        dispatch(unauthenticate());
        return;
      }
      toast.error('Error en la API');
    }

    finally {
      dispatch(loaded('authUser'));
    }
  };
}

export function fetchExitosList() {
  return async (dispatch) => {
    const opportunityAPI = new OpportunityAPI();
    dispatch(loading(''));
    try {
      const response = await opportunityAPI.getExitos();
      const { success, message, result } = response.data;
      const res = opportunityHelper.normalizeOpportunities(result);
      if (success) {
        dispatch({
          type: GET_EXITOS_SUCCESS,
          payload: res,
        });
      }
      else {
        toast.error(message);
      }
    }

    catch (e) {
      console.log({ fetchOpportunityListError: e });
      if (e.response && e.response.status === 401) {
        toast.error('Una disculpa, necesita ingresar de nuevo');
        dispatch(unauthenticate());
        return;
      }
      toast.error('Error en la API');
    }

    finally {
      dispatch(loaded('authUser'));
    }
  };
}

export function fetchOpportunityDetail(id) {
  return async (dispatch) => {
    const opportunityAPI = new OpportunityAPI();
    dispatch(loading(''));
    try {
      const response = await opportunityAPI.opportunityDetail(id);
      console.log({ response });
      const { success, message, result } = response.data;
      const res = {
        ...result,
        avances_de_obra: result.avances_de_obra.reverse(),
      };
      if (success) {
        dispatch({
          type: GET_OPPORTUNITY_DETAIL_SUCCESS,
          payload: res,
        });
      }
      else {
        toast.error(message);
      }
    }

    catch (e) {
      console.log({ fetchOpportunityDetail: e });
      if (e.response && e.response.status === 401) {
        toast.error('Una disculpa, necesita ingresar de nuevo');
        dispatch(unauthenticate());
        return;
      }
      toast.error('Error en la API');
    }

    finally {
      dispatch(loaded('authUser'));
    }
  };
}
export function submitApartarRequest(productid, quantity, history) {
  return async (dispatch) => {
    const opportunityAPI = new OpportunityAPI();
    dispatch(loading(''));
    try {
      const response = await opportunityAPI.requestApartar({ productid, quantity });
      const { success, message } = response.data;
      if (success) {
        const { result } = response.data;
        const crmid = result[0].productid;
        toast.success(message.success);
        await history.push('/app/apartadas');
        await history.push(`/app/apartadas/${crmid}`);
      } else {
        toast.error(message);
      }
    }

    catch (e) {
      console.log({ fetchOpportunityDetail: e });
      if (e.response && e.response.status === 401) {
        toast.error('Una disculpa, necesita ingresar de nuevo');
        dispatch(unauthenticate());
        return;
      }
      toast.error('Error en la API');
    }

    finally {
      dispatch(loaded('authUser'));
    }
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import { Fab, Zoom } from '@material-ui/core';
import { KeyboardArrowUp } from '@material-ui/icons';

const ScrollTopButton = ({
  color,
}) => {
  const [appear, setApper] = React.useState(false);
  const listenScroll = () => {
    if (appear === false && window.pageYOffset !== 0) {
      setApper(true);
    } else {
      setApper(false);
    }
  };

  const setListener = () => {
    document.addEventListener('scroll', listenScroll);
  };

  React.useEffect(() => {
    setListener();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => () => {
    document.removeEventListener('scroll', listenScroll);
    // eslint-disable-next-line
  }, []);

  const scrollToTop = () => {
    window.scroll(0, 0);
  };

  return (
    <div className="scrollTop_btn">
      <Zoom in={appear}>
        <Fab
          className="scrollTop_btn"
          color={color}
          onClick={() => scrollToTop()}
        >
          <KeyboardArrowUp />
        </Fab>
      </Zoom>
    </div>
  );
};

ScrollTopButton.propTypes = {
  color: PropTypes.string,
};
ScrollTopButton.defaultProps = {
  color: 'primary',
};

export default ScrollTopButton;

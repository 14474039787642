/* eslint-disable radix */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Badge,
  ListItem,
} from '@material-ui/core';
import { toolsHelper } from '../../../helpers';

const ApartadasItem = ({ item, onClick }) => {
  const { cf_1015, unit_price, qtyinstock } = item.proyecto;
  const total = Math.round(cf_1015 / unit_price);
  const faltan = Number.parseInt(total - qtyinstock);
  const grand = toolsHelper.formatMoney(item.total, 0);
  return (
    <ListItem button onClick={() => onClick(item.proyecto.crmid)}>
      <div className="flex-row d-flex w-100">
        <Badge
          color="secondary"
          badgeContent={item.quantity}
        >
          <Avatar
            className="size-70"
            style={{ backgroundColor: '#d0d0ca' }}
            src={
              item.proyecto.images && item.proyecto.images[0]
                ? item.proyecto.images[0]
                : ''
            }
          />
        </Badge>
        <div className="d-flex justify-content-between f-1">
          <div className="ml-2 position-relative">
            <div className="align-center position-relative">
              <div className="opportunity_title">
                {item.proyecto.productname.toUpperCase()}
              </div>
              <div className="d-flex justify-content-between font-size-14">
                <div className="font-gray">
                  <FontAwesomeIcon icon="calendar-minus" fixedWidth />
                  <span>{`${item.proyecto.cf_1400} meses`}</span>
                </div>
                <div className="font-gray ml-2">
                  <span>{`${item.proyecto.cf_1402}`}</span>
                  <FontAwesomeIcon
                    className="font-size-14"
                    icon="percentage"
                    fixedWidth
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-end">
            <div className="opportunity_unitprice">
              <FontAwesomeIcon
                className="font-size-14"
                icon="dollar-sign"
                fixedWidth
              />
              <span>{grand.toString()}</span>
            </div>
            <div className="font-size-14 font-weight-500">
              <span className="text-secondary">{faltan}</span>
/
              <span>{total}</span>
            </div>
          </div>
        </div>
      </div>
    </ListItem>
  );
};

ApartadasItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ApartadasItem;

/* eslint camelcase: 0 */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */

import { numberFormat, wordInString, paginate } from './tools';

export const makeProductsArray = productsById => (
  Object.keys(productsById)
    .map((productId) => {
      const product = { ...productsById[productId] };
      product.Products = Object.keys(product.Products).map(optionId => product.Products[optionId]);
      return product;
    })
);

export const haveStock = (product, user) => {
  const { qtyinstock, sin_existencia } = product;
  return (user.user.config.pos_sininv || sin_existencia) || qtyinstock > 0;
};

export const findProducts = (products, word) => {
  const foundProducts = products.filter(product => (
    wordInString(word, product.nombre)
    || wordInString(word, product.categorias)
    || wordInString(word, product.marca)
  ));
  return foundProducts;
};

export const paginatedProducts = (productsByArray, totalItemsForPage, index = 0) => {
  const _paginatedProducts = paginate(productsByArray, totalItemsForPage, index);
  const { page, indexPage, totalPages } = _paginatedProducts;
  const products = page;
  const actualPage = indexPage + 1;
  return {
    products,
    actualPage,
    totalPages,
  };
};

export const subtractStock = (products, productsById, cartProductsById = {}) => {
  const subtractProducts = products.map((_option) => {
    const option = { ..._option };
    const { parentId, crmid } = option;

    if (crmid in cartProductsById) {
      option.qtyinstock = numberFormat(cartProductsById[crmid].qtyinstock) - numberFormat(cartProductsById[crmid].quantity);
    }

    else if (parentId in productsById && crmid in productsById[parentId].Products) {
      option.qtyinstock = numberFormat(productsById[parentId].Products[crmid].qtyinstock);
    }

    return option;
  });

  return subtractProducts;
};

export const mappingOptions = ({ keyCode, sRow, sCol, column, row, prevState }) => {
  switch (keyCode) {
    case 39:
      if (sCol !== 3 && sRow !== row) {
        return { sCol: prevState.sCol + 1 };
      }
      if (sCol !== column && sRow === row) {
        return { sCol: prevState.sCol + 1 };
      }
      break;
    case 37:
      if (sCol !== 1) {
        return { sCol: prevState.sCol - 1 };
      }
      break;
    case 38:
      if (sRow !== 1) {
        return { sRow: prevState.sRow - 1 };
      }
      break;
    case 40:
      if (sRow !== row) {
        return { sRow: prevState.sRow + 1 };
      }
      if ((sCol === 3 || sCol === 2) && sRow === (row - 1)) {
        return { sCol: column, sRow: row };
      }
      break;
    default:
      return { sCol: 1, sRow: 1 };
  }
}

export default {
  makeProductsArray,
  haveStock,
  findProducts,
  paginatedProducts,
  subtractStock,
  mappingOptions,
};

/*
  const productsByArray = Object.keys(productsById).map((productId) => {
    let product = productsById[productId];
    product = _normalizeProduct(product);

    const productOptionsById = product.Products;
    const { crmid, sin_existencia } = product;
    product.Products = Object.keys(productOptionsById).map((optionId) => {
      let option = productOptionsById[optionId];
      option = _normalizeOption(option, crmid, sin_existencia);
      return option;
    });

    return product;
  });
*/

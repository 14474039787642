import React, { Component } from "react";
import List from "@material-ui/core/List";
import { connect } from "react-redux";
import {
  dispatchInvestmentDetail,
  fetchInvestmentList
} from "../../redux/actions";
import Header from "../../components/Header";
import InvestmentItem from "./components/InvestmentItem";
import SwipeableViews from "react-swipeable-views";
import { Tab, Tabs, withStyles, Divider } from "@material-ui/core";

class InvestmentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      open: false
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchInvestmentList());
  }
  handleChange = (event, value) => {
    this.setState({ value });
  };

  onClickItem = (id, item) => {
    const { dispatch, history } = this.props;
    dispatch(dispatchInvestmentDetail(item));
    history.push(`investment/${id}`);
  };

  render() {
    const { abiertas, cerradas, classes, loading } = this.props;
    const { value } = this.state;
    return (
      <>
        <Header title="Inversiones" />
        <div className="container">
          <Tabs
            value={value}
            onChange={this.handleChange}
            variant="fullWidth"
            classes={{
              indicator: classes.indicator
            }}
            scrollButtons="on"
          >
            <Tab
              className="tab"
              style={{ color: value === 0 ? "#662D91" : "#CCCCCC" }}
              label="Activas"
            />
            <Tab
              className="tab tab-left-border"
              style={{ color: value === 1 ? "#662D91" : "#CCCCCC" }}
              label="Liquidadas"
            />
          </Tabs>
          {!loading && (
            <SwipeableViews
              axis="x"
              resistance
              index={value}
              animateHeight={value > 0}
              enableMouseEvents
              onChangeIndex={index => this.setState({ value: index })}
            >
              {abiertas.length > 0 ? (
                <List className="minHe50vh">
                  {abiertas.map((item, index) => (
                    <div key={"investment" + index}>
                      <InvestmentItem item={item} onClick={this.onClickItem} />
                      <Divider component="li" />
                    </div>
                  ))}
                </List>
              ) : (
                <List className="minHe50vh">
                  <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                    <h4>No cuenta con inversiones activas</h4>
                  </div>
                </List>
              )}
              {cerradas.length > 0 ? (
                <List className="minHe50vh">
                  {cerradas.map((item, index) => (
                    <div key={"investment" + index}>
                      <InvestmentItem
                        item={item}
                        onClick={this.onClickItem}
                      />
                      <Divider component="li" />
                    </div>
                  ))}
                </List>
              ) : (
                <List className="minHe50vh">
                  <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                    <h4>No cuenta con inversiones liquidadas</h4>
                  </div>
                </List>
              )}
            </SwipeableViews>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ investment, loads }) => {
  const { abiertas, cerradas } = investment;
  const { loading } = loads;
  return {
    abiertas,
    cerradas,
    loading
  };
};
const styles = theme => ({
  indicator: {
    backgroundColor: "#662D91"
  }
});
export default connect(mapStateToProps)(withStyles(styles)(InvestmentPage));

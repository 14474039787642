/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import {
  Select,
  Divider,
  Checkbox,
  MenuItem,
  Collapse,
  FormLabel,
  FormControl,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';
import { Form } from 'reactstrap';
import MaskedInput from 'react-text-mask';
import { KeyboardArrowRight, Add } from '@material-ui/icons';
import BootstrapInput from '../../../../../../components/BootstrapInput';
import ImgThumbnail from '../../../../../../components/ImgThumbnail';
import GoogleMapsPlacesSearch from '../../../../../../components/GoogleMapsPlacesSearch';

import actividades from '../../../../../../data/actividades';
import InputSelect from '../../../../../../components/Inputs/InputSelect';

const NationalPhysic1 = ({
  step,
  title,
  scheme,
  finish,
  submit,
  redirect,
  isLoading,
}) => {
  const [bene2, setBene2] = React.useState(false);
  const [bene3, setBene3] = React.useState(false);
  const [bene4, setBene4] = React.useState(false);
  const [form, setForm] = React.useState(scheme);
  const [manifiesto, setManifiesto] = React.useState(false);

  React.useEffect(() => {
    if (Object.keys(scheme).length > 0) {
      setForm(scheme);
    }
  }, [scheme]);

  const onChange = (e) => {
    const { value, name } = e.target;
    const newStep = { ...form[name], value };
    setForm({ ...form, [name]: { ...newStep } });
  };

  const onChangeSelect = (selected, name) => {
    const { value } = selected;
    const newStep = { ...form[name], value };
    setForm({ ...form, [name]: { ...newStep } });
  };

  const onChangeFile = (e) => {
    const { name } = e.target;
    const { target: { files } } = e;
    const newStep = { ...form[name], value: files[0] };
    setForm({ ...form, [name]: { ...newStep } });
  };

  const onChangeSearch = (places) => {
    const initForm = { ...form };
    const lugar = { ...initForm.cf_1368 };
    const pais = { ...initForm.pais_nacimiento };
    const entidad = { ...initForm.entidad_nacimiento };
    if (places.length > 0) {
      const { address_components, formatted_address } = places[0];
      if (formatted_address) {
        lugar.value = formatted_address;
      }
      address_components.forEach((add) => {
        if (add.types.includes('country')) {
          pais.value = add.long_name;
        }
        if (add.types.includes('administrative_area_level_1')) {
          entidad.value = add.long_name;
        }
      });
      setForm(old => ({
        ...old,
        cf_1368: { ...lugar },
        pais_nacimiento: { ...pais },
        entidad_nacimiento: { ...entidad },
      }));
    } else {
      pais.value = '';
      entidad.value = '';
    }
    setForm(old => ({
      ...old,
      cf_1368: { ...lugar },
      pais_nacimiento: { ...pais },
      entidad_nacimiento: { ...entidad },
    }));
  };

  const onChangeSearchLocation = (places) => {
    if (places.length > 0) {
      const initForm = { ...form };
      const bill_street = { ...initForm.bill_street };
      const bill_num_ext = { ...initForm.bill_num_ext };
      const bill_pobox = { ...initForm.bill_pobox };
      const bill_city = { ...initForm.bill_city };
      const bill_state = { ...initForm.bill_state };
      const bill_country = { ...initForm.bill_country };
      const bill_code = { ...initForm.bill_code };
      const { address_components } = places[0];
      address_components.forEach((add) => {
        if (add.types.includes('route')) {
          bill_street.value = add.long_name;
        }
        if (add.types.includes('street_number')) {
          bill_num_ext.value = add.long_name;
        }
        if (add.types.includes('sublocality_level_1')) {
          bill_pobox.value = add.long_name;
        }
        if (add.types.includes('locality')) {
          bill_city.value = add.long_name;
        }
        if (add.types.includes('administrative_area_level_1')) {
          bill_state.value = add.long_name;
        }
        if (add.types.includes('country')) {
          bill_country.value = add.long_name;
        }
        if (add.types.includes('postal_code')) {
          bill_code.value = add.long_name;
        }
      });
      setForm(old => ({
        ...old,
        bill_street: { ...bill_street },
        bill_num_ext: { ...bill_num_ext },
        bill_pobox: { ...bill_pobox },
        bill_city: { ...bill_city },
        bill_state: { ...bill_state },
        bill_country: { ...bill_country },
        bill_code: { ...bill_code },
      }));
    }
  };

  const onKeyPress = (event) => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  };

  const onSubmit = (e, formState) => {
    e.preventDefault();
    if (step === 0) {
      if (formState.cf_1382.value === 'Casado' && form.cf_1384.value === '') {
        toast.warn('Se necesita elegir un regimen conyugal en un estado civil: Casado');
      } else {
        submit(formState);
      }
    } else {
      submit(formState);
    }
  };

  const onSubmitPaymentsForm = (e, formState) => {
    e.preventDefault();
    const {
      cf_1348,
      cf_1350,
    } = formState;
    if (cf_1348.value.length > 14) {
      toast.info('El numero de cuenta para depositos deben ser hasta 14 digitos');
      return null;
    }
    if (cf_1350.value.length > 18) {
      toast.info('La CLABE Interbancaria deben ser hasta 18 digitos');
      return null;
    }
    submit(formState);
    return null;
  };

  const validateManifestation = (stateForm, stateManifiesto) => {
    if (stateManifiesto && (
      stateForm.siccode.value !== ''
    )) {
      return true;
    }
    return false;
  };

  const validatePercentage = (stateForm) => {
    if (Object.keys(stateForm).length > 0 && step === 4) {
      const arr = [
        Number.parseFloat(stateForm.cf_1362.value),
        Number.parseFloat(stateForm.cf_1500.value),
        Number.parseFloat(stateForm.cf_1512.value),
        Number.parseFloat(stateForm.cf_1524.value),
      ];
      const sum = arr.reduce((total, num) => (total + num));
      if (sum !== 100.0) {
        return false;
      }
      return true;
    }
    return false;
  };

  const addBeneficiario = () => {
    if (bene2) {
      setBene3(!bene3);
      return null;
    }
    if (bene3) {
      setBene4(!bene4);
      return null;
    }
    setBene2(!bene2);
    return null;
  };

  const formattSelection = value => ({ id: value, label: value });

  const onSubmitandFinish = (e, stateForm) => {
    e.preventDefault();
    if (validatePercentage) {
      const arr = [
        Number.parseFloat(stateForm.cf_1362.value),
        Number.parseFloat(stateForm.cf_1500.value),
        Number.parseFloat(stateForm.cf_1512.value),
        Number.parseFloat(stateForm.cf_1524.value),
      ];
      if (arr.some(x => x > 0.0)) {
        finish(stateForm);
      } else {
        toast.info('Necesitas al menos un beneficiario con un porcentaje de beneficio');
      }
    } else {
      toast.info('La suma de los porcentajes de tus beneficiarios debe ser 100%');
    }
  };

  const formattedActividades = actividades.map(x => ({
    value: x.value,
    label: x.value,
  }));


  // eslint-disable-next-line consistent-return
  const renderForm = () => {
    if (Object.keys(form).length > 0) {
      if (step === 0) {
        return (
          <Form
            className="w-100"
            onKeyPress={onKeyPress}
            onSubmit={e => onSubmit(e, form)}
          >
            <div className="form-group w-100">
              <input
                autoFocus
                id="input_name"
                name="ownership"
                onChange={onChange}
                value={form.ownership.value || ''}
                required={form.ownership.required}
                readOnly={form.ownership.readonly}
                placeholder={form.ownership.label}
                className="form-control form-control"
              />
            </div>
            <div className="form-group w-100">
              <input
                id="input_last1"
                name="cf_1130"
                onChange={onChange}
                value={form.cf_1130.value || ''}
                required={form.cf_1130.required}
                readOnly={form.cf_1130.readonly}
                placeholder={form.cf_1130.label}
                className="form-control form-control"
              />
            </div>
            <div className="form-group w-100">
              <input
                id="input_last2"
                name="cf_1132"
                onChange={onChange}
                value={form.cf_1132.value || ''}
                required={form.cf_1132.required}
                readOnly={form.cf_1132.readonly}
                placeholder={form.cf_1132.label}
                className="form-control form-control"
              />
            </div>
            <div className="form-group w-100">
              <MaskedInput
                id="input_phone"
                type="tel"
                name="phone"
                guide={false}
                onBlur={() => {}}
                onChange={onChange}
                value={form.phone.value}
                required={form.phone.required}
                readOnly={form.phone.readonly}
                placeholder={form.phone.label}
                className="form-control form-control"
                mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              />
            </div>
            <div className="form-group w-100">
              <InputSelect
                inputId="input_ocupation"
                name="profesion"
                onChange={selected => onChangeSelect(selected, 'profesion')}
                placeholder={form.profesion.label}
                data={formattedActividades}
                value={form.profesion.value === '' ? '' : (formattSelection(form.profesion.value))}
                controlStyle={{ fontSize: '1rem' }}
              />
            </div>
            <div className="form-group w-100">
              <input
                id="input_birthdate"
                type="text"
                name="fecha_nacimiento"
                onFocus={(e) => {
                  e.currentTarget.type = 'date';
                  e.currentTarget.click();
                }}
                onBlur={(e) => {
                  e.currentTarget.type = 'date';
                  e.currentTarget.placeholder = (form.fecha_nacimiento.value || '');
                }}
                onChange={onChange}
                defaultValue="1990-01-01"
                value={form.fecha_nacimiento.value || ''}
                required={form.fecha_nacimiento.required}
                placeholder={form.fecha_nacimiento.label}
                className="form-control form-control"
              />
            </div>
            <div className="form-group w-100">
              <GoogleMapsPlacesSearch
                id="input_country"
                onPlacesChanged={onChangeSearch}
                placeholder={form.cf_1368.label}
                value={form.cf_1368.value}
                required
                apiKey="AIzaSyAeifeBfJxg8gW-xVYwK2Ui6_QMiTBcUuE"
              />
            </div>
            <div className="form-group w-100">
              <input
                id="input_pais"
                name="pais_nacimiento"
                value={form.pais_nacimiento.value || ''}
                required={form.pais_nacimiento.required}
                placeholder={form.pais_nacimiento.label}
                className="form-control form-control"
                onChange={onChange}
              />
            </div>
            <div className="form-group w-100">
              <input
                id="input_entidad"
                name="entidad_nacimiento"
                value={form.entidad_nacimiento.value || ''}
                required={form.entidad_nacimiento.required}
                placeholder={form.entidad_nacimiento.label}
                className="form-control form-control"
                onChange={onChange}
              />
            </div>
            <div className="form-group w-100">
              <InputSelect
                inputId="input_nacionalidad"
                name="cf_1372"
                onChange={selected => onChangeSelect(selected, 'cf_1372')}
                placeholder={form.cf_1372.label}
                data={form.cf_1372.options.map(x => ({ value: x, label: x }))}
                value={form.cf_1372.value === '' ? '' : (formattSelection(form.cf_1372.value))}
              />
            </div>
            <div className="form-group w-100">
              <FormControl
                className="w-100 mb-2"
                required={form.cf_1370.required}
              >
                <Select
                  id="input_genero"
                  displayEmpty
                  name="cf_1370"
                  onChange={onChange}
                  input={<BootstrapInput />}
                  required={form.cf_1370.required}
                  value={form.cf_1370.value || ''}
                >
                  <MenuItem value="" disabled>
                    {form.cf_1370.label}
                  </MenuItem>
                  <MenuItem value="Masculino">
                    {'Masculino'}
                  </MenuItem>
                  <MenuItem value="Femenino">
                    {'Femenino'}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="form-group w-100">
              <FormControl
                className="w-100 mb-2"
                required={form.cf_1382.required}
              >
                <Select
                  id="input_estadoCivil"
                  name="cf_1382"
                  displayEmpty
                  onChange={onChange}
                  input={<BootstrapInput />}
                  value={form.cf_1382.value || ''}
                  required={form.cf_1382.required}
                >
                  <MenuItem value="" disabled>
                    {form.cf_1382.label}
                  </MenuItem>
                  {form.cf_1382.options.map((opt, i) => (
                    <MenuItem
                      key={`key_estadoCivil_opt_${opt}_${i}`}
                      value={opt}
                    >
                      {opt}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Collapse
              mountOnEnter
              in={Boolean(form.cf_1382.value === 'Casado')}
            >
              <div className={(form.cf_1382.value === 'Casado' && form.cf_1384.value === '') ? ('form-group w-100 error_form') : ('form-group w-100')}>
                <FormControl className="w-100 mb-2">
                  <Select
                    id="input_regimen"
                    name="cf_1384"
                    displayEmpty
                    onChange={onChange}
                    input={<BootstrapInput />}
                    value={form.cf_1384.value || ''}
                    required
                  >
                    <MenuItem value="" disabled>
                      {form.cf_1384.label}
                    </MenuItem>
                    {form.cf_1384.options.map((opt, i) => (
                      <MenuItem
                        key={`key_regimen_opt_${opt}_${i}`}
                        value={opt}
                      >
                        {opt}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="form-group w-100">
                <input
                  id="input_conyugeName"
                  name="cf_1386"
                  onChange={onChange}
                  value={form.cf_1386.value || ''}
                  required={form.cf_1386.required}
                  readOnly={form.cf_1386.readonly}
                  placeholder={form.cf_1386.label}
                  className="form-control form-control"
                />
              </div>
            </Collapse>
            <div className="account_panel_step_submit">
              <button
                id="account_panel_step_submit_next"
                type="submit"
              >
                  Siguiente
                <span>
                  <KeyboardArrowRight />
                </span>
              </button>
              <button
                id="account_panel_step_submit_late"
                type="button"
                onClick={() => {
                  redirect(form);
                }}
              >
                Guardar para mas tarde
              </button>
            </div>
          </Form>
        );
      }
      if (step === 1) {
        return (
          <Form
            className="w-100"
            onKeyPress={onKeyPress}
            onSubmit={e => onSubmit(e, form)}
          >
            <div className="form-group w-100">
              <input
                id="input_siccode"
                name="siccode"
                onChange={onChange}
                value={form.siccode.value || ''}
                required={form.siccode.required}
                readOnly={form.siccode.readonly}
                placeholder={form.siccode.label}
                pattern={form.siccode.pattern}
                className="form-control form-control"
              />
            </div>
            <FormControl
              className="w-100 mb-2"
              required={form.cf_1372.required}
            >
              <Select
                id="input_identificacion"
                displayEmpty
                name="cf_1374"
                onChange={onChange}
                required={form.cf_1374.required}
                value={form.cf_1374.value || ''}
                input={<BootstrapInput />}
              >
                <MenuItem value="" disabled>
                  {form.cf_1374.label}
                </MenuItem>
                {form.cf_1374.options.map((opt, i) => (
                  <MenuItem
                    key={`key_nacionalidad_opt_${opt}_${i}`}
                    value={opt}
                  >
                    {opt}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className="form-group w-100">
              <input
                id="input_claveElector"
                name="cf_1380"
                onChange={onChange}
                value={form.cf_1380.value || ''}
                required={form.cf_1380.required}
                readOnly={form.cf_1380.readonly}
                placeholder={form.cf_1374.value
                  ? (
                    form.cf_1374.value === 'INE' ? ('Clave de Elector') : ('Número de pasaporte')
                  ) : ('Seleccione un tipo de identificación primero')}
                // pattern={form.cf_1380.pattern}
                className="form-control form-control"
              />
            </div>
            <div className="form-group w-100">
              <FormLabel className="text-light">
                {form.cf_1374.value
                  ? (
                    form.cf_1374.value === 'INE' ? ('Credencial de Elector (favor de subir ambas caras)')
                      : ('Pasaporte (Página plastificada)')
                  ) : ('Seleccione un tipo de identificación primero')}
              </FormLabel>
              {scheme.id_representante_legal.value === '' ? (
                <>
                  <input
                    id="input_identificacionOficial"
                    type="file"
                    name="id_representante_legal"
                    onChange={onChangeFile}
                    required={form.id_representante_legal.required}
                    readOnly={form.id_representante_legal.readonly}
                    className="account_panel_step_form_file"
                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                  />
                  {form.cf_1374.value === 'INE' && (
                    <div className="mt-2 mb-1 text-light">(segunda cara)</div>
                  )}
                  {form.cf_1374.value === 'INE' && (
                    <input
                      id="id_representante_legal_reverso"
                      type="file"
                      name="id_representante_legal_reverso"
                      onChange={onChangeFile}
                      // required={form.id_representante_legal_reverso.required}
                      // readOnly={form.id_representante_legal_reverso.readonly}
                      className="account_panel_step_form_file"
                      accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                    />
                  )}
                </>
              ) : (
                <ImgThumbnail
                  imgSrc={form.id_representante_legal.value}
                  title="Identificación oficial"
                  desc="Identificación unica de la persona en cuestión"
                  emptyPrimary="Identificación no disponible"
                  emptySecondary="Puede que la identificación no haya sido subida aún al sistema, si es así favor de completar su registro."
                  enableDownload
                />
              )}
            </div>
            <div className="form-group w-100">
              <input
                id="input_curp"
                name="cf_1378"
                onChange={onChange}
                value={form.cf_1378.value || ''}
                required={form.cf_1378.required}
                readOnly={form.cf_1378.readonly}
                placeholder={form.cf_1378.label}
                pattern={form.cf_1378.pattern}
                className="form-control form-control"
              />
            </div>
            <FormControl
              required
              error={!manifiesto}
              className="w-100 mb-2"
            >
              <FormLabel>Manifestación de la persona física en la que señale que actúa por cuenta propia.*</FormLabel>
              <FormControlLabel
                control={<Checkbox color="primary" checked={manifiesto} onChange={() => setManifiesto(!manifiesto)} />}
                label={<span className="text-light">Confirma</span>}
              />
            </FormControl>
            <div className="account_panel_step_submit">
              <Collapse
                className="w-100"
                in={validateManifestation(form, manifiesto)}
              >
                <div className="account_panel_step_submit">
                  <button
                    id="account_panel_step_submit_next"
                    type="submit"
                  >
                      Siguiente
                    <span>
                      <KeyboardArrowRight />
                    </span>
                  </button>
                </div>
              </Collapse>
              <button
                id="account_panel_step_submit_late"
                type="button"
                onClick={() => {
                  redirect(form);
                }}
              >
                Guardar para mas tarde
              </button>
            </div>
          </Form>
        );
      }
      if (step === 2) {
        return (
          <Form
            className="w-100"
            onKeyPress={onKeyPress}
            onSubmit={e => onSubmit(e, form)}
          >
            <div className="form-group w-100">
              <GoogleMapsPlacesSearch
                id="input_location"
                onPlacesChanged={onChangeSearchLocation}
                placeholder="Busca tu dirección"
                apiKey="AIzaSyAeifeBfJxg8gW-xVYwK2Ui6_QMiTBcUuE"
              />
            </div>
            <div className="form-group w-100">
              <input
                id="input_address_bill_street"
                name="bill_street"
                onChange={onChange}
                value={form.bill_street.value || ''}
                required={form.bill_street.required}
                readOnly={form.bill_street.readonly}
                placeholder={form.bill_street.label}
                className="form-control form-control"
              />
            </div>
            <div className="form-group w-100">
              <input
                id="input_address_bill_num_ext"
                name="bill_num_ext"
                onChange={onChange}
                value={form.bill_num_ext.value || ''}
                required={form.bill_num_ext.required}
                readOnly={form.bill_num_ext.readonly}
                placeholder={form.bill_num_ext.label}
                className="form-control form-control"
              />
            </div>
            <div className="form-group w-100">
              <input
                id="input_address_bill_num_int"
                name="bill_num_int"
                onChange={onChange}
                value={form.bill_num_int.value || ''}
                required={form.bill_num_int.required}
                readOnly={form.bill_num_int.readonly}
                placeholder={form.bill_num_int.label}
                className="form-control form-control"
              />
            </div>
            <div className="form-group w-100">
              <input
                id="input_address_bill_pobox"
                name="bill_pobox"
                onChange={onChange}
                value={form.bill_pobox.value || ''}
                required={form.bill_pobox.required}
                readOnly={form.bill_pobox.readonly}
                placeholder={form.bill_pobox.label}
                className="form-control form-control"
              />
            </div>
            <div className="form-group w-100">
              <input
                id="input_address_bill_city"
                name="bill_city"
                onChange={onChange}
                value={form.bill_city.value || ''}
                required={form.bill_city.required}
                placeholder={form.bill_city.label}
                className="form-control form-control"
              />
            </div>
            <div className="form-group w-100">
              <input
                id="input_address_bill_state"
                name="bill_state"
                onChange={onChange}
                value={form.bill_state.value || ''}
                required={form.bill_state.required}
                /* readOnly={form.bill_state.readonly} */
                readOnly={false}
                placeholder={form.bill_state.label}
                className="form-control form-control"
              />
            </div>
            <div className="form-group w-100">
              <input
                id="input_address_bill_country"
                name="bill_country"
                onChange={onChange}
                value={form.bill_country.value || ''}
                required={form.bill_country.required}
                /* readOnly={form.bill_country.readonly} */
                readOnly={false}
                placeholder={form.bill_country.label}
                className="form-control form-control"
              />
            </div>
            <div className="form-group w-100">
              <input
                id="input_address_bill_code"
                name="bill_code"
                onChange={onChange}
                value={form.bill_code.value || ''}
                required={form.bill_code.required}
                readOnly={form.bill_code.readonly}
                placeholder={form.bill_code.label}
                className="form-control form-control"
              />
            </div>
            <div className="form-group w-100">
              <FormLabel className="text-light">{form.comprobante_domicilio.label}</FormLabel>
              {scheme.comprobante_domicilio.value === '' ? (
                <input
                  id="input_comprobante_domicilio"
                  type="file"
                  name="comprobante_domicilio"
                  onChange={onChangeFile}
                  required={form.comprobante_domicilio.required}
                  readOnly={form.comprobante_domicilio.readonly}
                  className="account_panel_step_form_file"
                  accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                />
              ) : (
                <ImgThumbnail
                  imgSrc={form.comprobante_domicilio.value}
                  title="Comprobante de domicilio"
                  desc="Comprobante de domicilio en cuestion"
                  emptyPrimary="Comprobante de domicilio no disponible"
                  emptySecondary="Comprobante de domicilio cuenta con fallos, intente subir el archivo más tarde"
                  enableDownload
                />
              )}

            </div>
            <div className="account_panel_step_submit">
              <button
                id="account_panel_step_submit_next"
                type="submit"
              >
                  Siguiente
                <span>
                  <KeyboardArrowRight />
                </span>
              </button>
              <button
                id="account_panel_step_submit_late"
                type="button"
                onClick={() => {
                  redirect(form);
                }}
              >
                Guardar para mas tarde
              </button>
            </div>
          </Form>
        );
      }
      if (step === 3) {
        return (
          <Form
            className="w-100"
            onKeyPress={onKeyPress}
            onSubmit={e => onSubmitPaymentsForm(e, form)}
          >
            <div className="form-group w-100">
              <input
                id="input_cf_1348"
                name="cf_1348"
                maxLength={14}
                type="number"
                pattern="\d{14}"
                onChange={onChange}
                value={form.cf_1348.value || ''}
                required={form.cf_1348.required}
                readOnly={form.cf_1348.readonly}
                placeholder={form.cf_1348.label}
                onKeyUp={(e) => {
                  // @ts-ignore
                  e.target.value = e.target.value.substring(0, 14);
                }}
                className="form-control form-control"
              />
            </div>
            <div className="form-group w-100">
              <input
                id="input_cf_1352"
                name="cf_1352"
                onChange={onChange}
                value={form.cf_1352.value || ''}
                required={form.cf_1352.required}
                readOnly={form.cf_1352.readonly}
                placeholder={form.cf_1352.label}
                className="form-control form-control"
              />
            </div>
            <div className="form-group w-100">
              <input
                id="input_cf_1354"
                name="cf_1354"
                onChange={onChange}
                value={form.cf_1354.value || ''}
                required={form.cf_1354.required}
                readOnly={form.cf_1354.readonly}
                placeholder={form.cf_1354.label}
                className="form-control form-control"
              />
            </div>
            <div className="form-group w-100">
              <input
                id="input_cf_1350"
                name="cf_1350"
                maxLength={18}
                pattern="\w{18}"
                onChange={onChange}
                value={form.cf_1350.value || ''}
                required={form.cf_1350.required}
                readOnly={form.cf_1350.readonly}
                placeholder={form.cf_1350.label}
                className="form-control form-control"
                onKeyUp={(e) => {
                  // @ts-ignore
                  e.target.value = e.target.value.substring(0, 18);
                }}
              />
            </div>
            <div className="account_panel_step_submit">
              <button
                id="account_panel_step_submit_next"
                type="submit"
              >
                  Siguiente
                <span>
                  <KeyboardArrowRight />
                </span>
              </button>
              <button
                id="account_panel_step_submit_late"
                type="button"
                onClick={() => {
                  redirect(form);
                }}
              >
                Guardar para mas tarde
              </button>
            </div>
          </Form>
        );
      }
      if (step >= 4) {
        return (
          <Form
            className="w-100"
            onKeyPress={onKeyPress}
            onSubmit={e => onSubmitandFinish(e, form)}
          >
            <div className="form-group w-100">
              <input
                name="cf_1356"
                onChange={onChange}
                value={form.cf_1356.value || ''}
                required={form.cf_1356.required}
                readOnly={form.cf_1356.readonly}
                placeholder={form.cf_1356.label}
                className="form-control form-control"
              />
            </div>
            <div className="form-group w-100">
              <input
                name="cf_1358"
                onChange={onChange}
                value={form.cf_1358.value || ''}
                required={form.cf_1358.required}
                readOnly={form.cf_1358.readonly}
                placeholder={form.cf_1358.label}
                className="form-control form-control"
              />
            </div>
            <div className="form-group w-100">
              <input
                name="cf_1360"
                onChange={onChange}
                value={form.cf_1360.value || ''}
                required={form.cf_1360.required}
                readOnly={form.cf_1360.readonly}
                placeholder={form.cf_1360.label}
                className="form-control form-control"
              />
            </div>
            <div className="form-group w-100">
              <input
                name="cf_1362"
                onChange={onChange}
                type="number"
                required={form.cf_1362.required}
                readOnly={form.cf_1362.readonly}
                placeholder={form.cf_1362.label}
                className="form-control form-control"
                defaultValue={form.cf_1362.value !== 0.0 ? '' : form.cf_1362.value}
              />
            </div>
            <div className="form-group w-100">
              <MaskedInput
                type="tel"
                name="cf_1364"
                guide={false}
                onBlur={() => {}}
                onChange={onChange}
                value={form.cf_1364.value}
                required={form.cf_1364.required}
                readOnly={form.cf_1364.readonly}
                placeholder={form.cf_1364.label}
                className="form-control form-control"
                mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              />
            </div>
            <div className="form-group w-100">
              <MaskedInput
                type="tel"
                name="cf_1366"
                guide={false}
                onBlur={() => {}}
                onChange={onChange}
                value={form.cf_1366.value}
                required={form.cf_1366.required}
                readOnly={form.cf_1366.readonly}
                placeholder={form.cf_1366.label}
                className="form-control form-control"
                mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              />
            </div>
            <Divider />
            <Collapse
              in={bene2}
              unmountOnExit
            >
              <div className="form-group w-100 mt-3">
                <input
                  name="cf_1494"
                  onChange={onChange}
                  value={form.cf_1494.value || ''}
                  required={form.cf_1494.required}
                  readOnly={form.cf_1494.readonly}
                  placeholder={form.cf_1494.label}
                  className="form-control form-control"
                />
              </div>
              <div className="form-group w-100">
                <input
                  name="cf_1496"
                  onChange={onChange}
                  value={form.cf_1496.value || ''}
                  required={form.cf_1496.required}
                  readOnly={form.cf_1496.readonly}
                  placeholder={form.cf_1496.label}
                  className="form-control form-control"
                />
              </div>
              <div className="form-group w-100">
                <input
                  name="cf_1498"
                  onChange={onChange}
                  value={form.cf_1498.value || ''}
                  required={form.cf_1498.required}
                  readOnly={form.cf_1498.readonly}
                  placeholder={form.cf_1498.label}
                  className="form-control form-control"
                />
              </div>
              <div className="form-group w-100">
                <input
                  name="cf_1500"
                  onChange={onChange}
                  type="number"
                  required={form.cf_1500.required}
                  readOnly={form.cf_1500.readonly}
                  placeholder={form.cf_1500.label}
                  className="form-control form-control"
                  defaultValue={form.cf_1500.value !== 0.0 ? '' : form.cf_1500.value}
                />
              </div>
              <div className="form-group w-100">
                <MaskedInput
                  type="tel"
                  name="cf_1502"
                  guide={false}
                  onBlur={() => {}}
                  onChange={onChange}
                  value={form.cf_1502.value}
                  required={form.cf_1502.required}
                  readOnly={form.cf_1502.readonly}
                  placeholder={form.cf_1502.label}
                  className="form-control form-control"
                  mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                />
              </div>
              <div className="form-group w-100">
                <MaskedInput
                  type="tel"
                  name="cf_1504"
                  guide={false}
                  onBlur={() => {}}
                  onChange={onChange}
                  value={form.cf_1504.value}
                  required={form.cf_1504.required}
                  readOnly={form.cf_1504.readonly}
                  placeholder={form.cf_1504.label}
                  className="form-control form-control"
                  mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                />
              </div>
              <Divider />
            </Collapse>
            <Collapse
              in={bene3}
              unmountOnExit
            >
              <div className="form-group w-100 mt-3">
                <input
                  name="cf_1506"
                  onChange={onChange}
                  value={form.cf_1506.value || ''}
                  required={form.cf_1506.required}
                  readOnly={form.cf_1506.readonly}
                  placeholder={form.cf_1506.label}
                  className="form-control form-control"
                />
              </div>
              <div className="form-group w-100">
                <input
                  name="cf_1508"
                  onChange={onChange}
                  value={form.cf_1508.value || ''}
                  required={form.cf_1508.required}
                  readOnly={form.cf_1508.readonly}
                  placeholder={form.cf_1508.label}
                  className="form-control form-control"
                />
              </div>
              <div className="form-group w-100">
                <input
                  name="cf_1510"
                  onChange={onChange}
                  value={form.cf_1510.value || ''}
                  required={form.cf_1510.required}
                  readOnly={form.cf_1510.readonly}
                  placeholder={form.cf_1510.label}
                  className="form-control form-control"
                />
              </div>
              <div className="form-group w-100">
                <input
                  name="cf_1512"
                  onChange={onChange}
                  type="number"
                  required={form.cf_1512.required}
                  readOnly={form.cf_1512.readonly}
                  placeholder={form.cf_1512.label}
                  className="form-control form-control"
                  defaultValue={form.cf_1512.value !== 0.0 ? '' : form.cf_1512.value}
                />
              </div>
              <div className="form-group w-100">
                <MaskedInput
                  type="tel"
                  name="cf_1514"
                  guide={false}
                  onBlur={() => {}}
                  onChange={onChange}
                  value={form.cf_1514.value}
                  required={form.cf_1514.required}
                  readOnly={form.cf_1514.readonly}
                  placeholder={form.cf_1514.label}
                  className="form-control form-control"
                  mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                />
              </div>
              <div className="form-group w-100">
                <MaskedInput
                  type="tel"
                  name="cf_1516"
                  guide={false}
                  onBlur={() => {}}
                  onChange={onChange}
                  value={form.cf_1516.value}
                  required={form.cf_1516.required}
                  readOnly={form.cf_1516.readonly}
                  placeholder={form.cf_1516.label}
                  className="form-control form-control"
                  mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                />
              </div>
              <Divider />
            </Collapse>
            <Collapse
              in={bene4}
              unmountOnExit
            >
              <div className="form-group w-100 mt-3">
                <input
                  name="cf_1518"
                  onChange={onChange}
                  value={form.cf_1518.value || ''}
                  required={form.cf_1518.required}
                  readOnly={form.cf_1518.readonly}
                  placeholder={form.cf_1518.label}
                  className="form-control form-control"
                />
              </div>
              <div className="form-group w-100">
                <input
                  name="cf_1520"
                  onChange={onChange}
                  value={form.cf_1520.value || ''}
                  required={form.cf_1520.required}
                  readOnly={form.cf_1520.readonly}
                  placeholder={form.cf_1520.label}
                  className="form-control form-control"
                />
              </div>
              <div className="form-group w-100">
                <input
                  name="cf_1522"
                  onChange={onChange}
                  value={form.cf_1522.value || ''}
                  required={form.cf_1522.required}
                  readOnly={form.cf_1522.readonly}
                  placeholder={form.cf_1522.label}
                  className="form-control form-control"
                />
              </div>
              <div className="form-group w-100">
                <input
                  name="cf_1524"
                  onChange={onChange}
                  type="number"
                  required={form.cf_1524.required}
                  readOnly={form.cf_1524.readonly}
                  placeholder={form.cf_1524.label}
                  className="form-control form-control"
                  defaultValue={form.cf_1524.value !== 0.0 ? '' : form.cf_1524.value}
                />
              </div>
              <div className="form-group w-100">
                <MaskedInput
                  type="tel"
                  name="cf_1526"
                  guide={false}
                  onBlur={() => {}}
                  onChange={onChange}
                  value={form.cf_1526.value}
                  required={form.cf_1526.required}
                  readOnly={form.cf_1526.readonly}
                  placeholder={form.cf_1526.label}
                  className="form-control form-control"
                  mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                />
              </div>
              <div className="form-group w-100">
                <MaskedInput
                  type="tel"
                  name="cf_1528"
                  guide={false}
                  onBlur={() => {}}
                  onChange={onChange}
                  value={form.cf_1528.value}
                  required={form.cf_1528.required}
                  readOnly={form.cf_1528.readonly}
                  placeholder={form.cf_1528.label}
                  className="form-control form-control"
                  mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                />
              </div>
            </Collapse>
            <div className="account_panel_step_add">
              <button
                id="account_panel_step_submit_next"
                type="button"
                onClick={() => addBeneficiario()}
              >
                Añade Beneficiario
                <span>
                  <Add />
                </span>
              </button>
            </div>
            <div
              className="account_panel_step_submit_abs"
            >
              <button
                id="account_panel_step_submit_next"
                type="submit"
              >
                Terminar
              </button>
            </div>
          </Form>
        );
      }
      return null;
    }
  };
  return (
    <div className="account_panel_step_form">
      <div className="w-100 text-center register-title mt-4 mb-2">
        {title}
      </div>
      <div className="w-100">
        {!isLoading ? (
          renderForm()
        ) : (
          <div className="w-100 h-100 d-flex align-items-center justify-content-center">
            <CircularProgress />
          </div>
        )}
      </div>

    </div>
  );
};

NationalPhysic1.propTypes = {
  step: PropTypes.number,
  finish: PropTypes.func,
  submit: PropTypes.func,
  title: PropTypes.string,
  redirect: PropTypes.func,
  scheme: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
};
NationalPhysic1.defaultProps = {
  step: 0,
  scheme: {},
  finish: () => {},
  submit: () => {},
  redirect: () => {},
  title: 'Datos Personales',
};

export default NationalPhysic1;

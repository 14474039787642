import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader } from 'reactstrap';
import { Button } from '@material-ui/core';

const ResetPassModal = ({
  logo,
  open,
  submit,
  toggle,
}) => {
  const inputRef = React.useRef(null);
  const [email, setEmail] = React.useState('');
  const setPass = (e) => {
    const { value } = e.target;
    setEmail(value);
  };
  const onKeyInput = (e) => {
    const { keyCode } = e;
    if (keyCode && email.length > 0 && keyCode === 13) {
      submit(email);
    }
  };

  React.useEffect(() => {
    if (open) {
      if (inputRef) inputRef.current.focus();
    }
  }, [open]);

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader className="modal-box-header bg-purple text-white text-center">
        <div className="w-75 m-auto">
          <img src={logo} alt="" />
        </div>
      </ModalHeader>
      <div className="modal-box-content">
        <div className="w-100 h-auto">
          <div className="w-100 text-center font-size-28">
            ¿Perdiste tu constraseña?
          </div>
          <div className="w-100 text-center font-size-18 mt-1">
            Por favor escribe tu correo electrónico. Recibirás un enlace para crear una contraseña nueva.
          </div>
          <div className="w-100 my-3">
            <input
              className="form-control form-control-lg"
              onChange={setPass}
              onKeyDown={onKeyInput}
              placeholder="Correo electronico"
              ref={inputRef}
              type="email"
              value={email}
            />
          </div>
          <div className="w-100 login_buttons">
            <Button
              className="login_submit block-outline"
              disabled={email.length === 0}
              onClick={() => submit(email)}
              variant="contained"
            >
              Enviar
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ResetPassModal.propTypes = {
  logo: PropTypes.string,
  open: PropTypes.bool,
  toggle: PropTypes.func,
  submit: PropTypes.func,
};

ResetPassModal.defaultProps = {
  open: false,
  logo: `${process.env.PUBLIC_URL}/img/logo-inverspot-white.png`,
  toggle: () => {},
  submit: () => {},
};

export default ResetPassModal;

export const normalizeOpportunities = (opportunities) => {
  const arr = [...opportunities];
  arr.sort((a, b) => {
    if (a.productname < b.productname) {
      return -1;
    }
    if (a.productname > b.productname) {
      return 1;
    }
    return 0;
  });
  return arr;
};

export default {
  normalizeOpportunities,
};

/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import TabItemButton from '../TabItemButton';
import ChangePasswordDialog from '../../../../components/ChangePasswordDialog';


const Tab6 = ({ changePassword }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <div className="p-2 minHe70vh">
      <TabItemButton
        title="Contraseña"
        label="Cambiar Contraseña"
        onSubmit={() => setOpen(!open)}
      />
      <ChangePasswordDialog
        isOpen={open}
        toggle={() => setOpen(!open)}
        onSubmit={(data) => {
          setOpen(false);
          changePassword(data);
        }}
      />
    </div>
  );
};

Tab6.propTypes = {
  changePassword: PropTypes.func,
};

Tab6.defaultProps = {
  changePassword: () => {},
};

export default Tab6;

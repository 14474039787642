/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import {
  Button,
  Checkbox,
  Collapse,
} from '@material-ui/core';
import Select from 'react-select';
import { connect } from 'react-redux';
import {
  getAsesoresAction,
  getPrivacyPolicyContentAction,
  getTermsConditionContentAction,
  registerWithEmailPassAction,
} from '../../redux/actions';
import TermsPrivacyDialog from '../../components/TermsPrivacyDialog';

class Register extends Component {
  static propTypes = {
    asesores: PropTypes.array,
    dispatch: PropTypes.func,
    history: PropTypes.object.isRequired,
    privacy: PropTypes.string,
    terms: PropTypes.string,
    user: PropTypes.object.isRequired,
  }

  static defaultProps = {
    asesores: [],
    dispatch: () => {},
    privacy: '',
    terms: '',
  }

  state = {
    accept: false,
    advisor: false,
    confirmPassword: '',
    email: '',
    name: '',
    password: '',
    phone: '',
    preferredAdvisor: '',
    referrer: '',
    errors: {
      accept: false,
      advisor: false,
      confirmPassword: false,
      email: false,
      name: false,
      password: false,
      preferredAdvisor: false,
    },
    open: false,
    content: '',

    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_term: '',
    gclid: '',
  };

  componentDidMount() {
    const { dispatch, location } = this.props;
    dispatch(getAsesoresAction());
    dispatch(getTermsConditionContentAction());
    dispatch(getPrivacyPolicyContentAction());
    this.setReferrerInURL();
    this.setCampainValues();

    /**
     * Append pixel script sitescout
     */
    var ssaUrl = "https://" + "clickserv.sitescout.com/conv/1dfc949ebb31f87c";
    new Image().src = ssaUrl;
    var syncUrl = "https://" + "pixel.sitescout.com/dmp/asyncPixelSync";
    var iframe = document.createElement("iframe");
    iframe.setAttribute('id', 'pixel');
    (iframe.frameElement || iframe).style.cssText =
      "width: 0; height: 0; border: 0;";
    iframe.src = "javascript:false";
    document.body.appendChild(iframe);
    var doc = iframe.contentWindow.document;
    doc.open().write("<body onload=\"window.location.href='" + syncUrl + "'\">");
    doc.close();
  }

  componentWillUnmount() {
    /**
     * Delete pixel script sitescout
     */
    let elem = document.querySelector('#pixel');
    elem.parentNode.removeChild(elem);
  }

  setCampainValues(){
    const { location } = this.props;
    const searchQuery = location.search.replace('?', '');
    const searchQueryArray = searchQuery.split('&');
    const searchArray = [];
    searchQueryArray.map(element => {
      const splitElement = element.split('=');
      const object = {
        index: splitElement[0],
        value: splitElement[1],
      }
      searchArray.push(object);
    });
    searchArray.map(element => {
      if (element.index === 'utm_source') { this.setState({utm_source: element.value}) }
      if (element.index === 'utm_medium') { this.setState({utm_medium: element.value})  }
      if (element.index === 'utm_campaign') { this.setState({utm_campaign: element.value})  }
      if (element.index === 'utm_term') { this.setState({utm_term: element.value})  }
      if (element.index === 'gclid') { this.setState({gclid: element.value})  }
    })
  }

  componentDidUpdate() {
    const { history, user } = this.props;
    if (user.token !== null && user.token !== undefined) {
      history.push('/');
    }
  }

  setReferrerInURL = () => {
    const referrer = this.getReferrerInURL();
    this.setState(() => ({ referrer }));
  };

  getReferrerInURL = () => {
    const url = new URL(window.location.href);
    const referrer = url.searchParams.get('spot_refiere');
    return referrer;
  };

  handleChangeCheck = name => (event, checked) => {
    const errors = {};
    if (name === 'accept') {
      errors[name] = !checked;
    }
    this.setState({ [name]: checked, errors });
  };

  handleChange = name => (event) => {
    const errors = {};
    const { password } = this.state;
    if (name === 'confirmPassword') {
      errors[name] = event.target.value === '' || event.target.value !== password;
    }

    this.setState({ [name]: event.target.value, errors });
  };

  handleChangeSelect = (selectedOption) => {
    this.setState({ preferredAdvisor: selectedOption });
  };

  handleSubmit = () => {
    const { dispatch } = this.props;
    if (!this.validForm()) {
      return;
    }
    const {
      email,
      password,
      advisor,
      preferredAdvisor,
      referrer,
      name,
      phone,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      gclid,
    } = this.state;
    const body = {
      email,
      password,
      name,
      phone,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      gclid,
    };
    if (advisor && preferredAdvisor !== null) {
      body.assigned_user_id = preferredAdvisor.value;
    }
    if (referrer) {
      body.referrer = referrer;
    }
    dispatch(registerWithEmailPassAction(body));
  };

  validForm = () => {
    const {
      accept,
      confirmPassword,
      email,
      name,
      password,
    } = this.state;
    const errors = {};
    if (
      email === ''
      || password === '' || password.length <= 7
      || confirmPassword === ''
      || !accept
      || password !== confirmPassword
      || name === ''
    ) {
      errors.email = email === '';
      errors.password = password === '' || password.length <= 7;
      errors.confirmPassword = confirmPassword === '' || confirmPassword !== password;
      errors.accept = !accept;
      errors.name = name === '';
      this.setState({ errors });
      return false;
    }
    return true;
  };

  onViewTermsPrivacy = (content) => {
    this.setState(state => ({
      open: !state.open,
      content,
    }));
  };

  onHandleModal = () => {
    this.setState(state => ({
      open: !state.open,
    }));
  };

  render() {
    const {
      accept,
      advisor,
      confirmPassword,
      content,
      name,
      email,
      errors,
      phone,
      open,
      password,
      preferredAdvisor,
      referrer,
    } = this.state;
    const { asesores, terms, privacy } = this.props;
    const formattedAsesores = asesores.map(a => ({ label: a.name, value: a.id }));
    const logo = `${process.env.PUBLIC_URL}/img/logo-inverspot-white.png`;

    return (
      <div className="root-container login-panel-background py-5">
        <div className="account-panel-width align-center position-absolute">
          <form noValidate autoComplete="off">
            <div className="form-group w-75 text-center m-auto">
              <img src={logo} alt="" />
            </div>
            <div className="form-group w-100 mt-4">
              <input
                type="text"
                autoFocus
                className="form-control form-control-lg"
                value={name}
                placeholder="Nombre completo"
                onChange={this.handleChange('name')}
              />
              <Collapse in={errors.name}>
                <div className="invalid-text">
                  {'* Nombre requerido'}
                </div>
              </Collapse>
            </div>
            <div className="form-group w-100 mt-4">
              <input
                type="email"
                className="form-control form-control-lg"
                value={email}
                placeholder="Correo electrónico"
                onChange={this.handleChange('email')}
              />
              <div
                className={`invalid-text ${
                  errors.email ? '' : 'invalid-text-invisible'
                }`}
              >
                {'* Correo electrónico es obligatorio'}
              </div>
            </div>
            <div className="form-group w-100">
              <input
                className="form-control  form-control-lg"
                value={password}
                type="password"
                placeholder="Contraseña"
                onChange={this.handleChange('password')}
              />
              <div
                className={`invalid-text ${
                  errors.password ? '' : 'invalid-text-invisible'
                }`}
              >
                {'* La contraseña es obligatoria o no es segura'}
              </div>
            </div>
            <div className="form-group w-100">
              <input
                className="form-control  form-control-lg"
                value={confirmPassword}
                type="password"
                placeholder="Confirmar contraseña"
                onChange={this.handleChange('confirmPassword')}
              />
              <div
                className={`invalid-text ${
                  errors.confirmPassword ? '' : 'invalid-text-invisible'
                }`}
              >
                {'* Su contraseña no coincide'}
              </div>
            </div>
            <div className="form-group w-100 px-1 register-note">
              Nota: La contraseña debe ser de por lo menos 7 caracteres de
              largo. Para hacerla más segura, utiliza mayúsculas, minúsculas,
              numeros y simbolos ! “ ? $ % * &
            </div>
            <div className="form-group w-100 mt-4">
              <MaskedInput
                id="input_phone"
                type="tel"
                name="phone"
                guide={false}
                onBlur={() => {}}
                onChange={this.handleChange('phone')}
                value={phone}
                placeholder="Teléfono / Celular"
                className="form-control  form-control-lg"
                mask={[/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              />
              <Collapse in={errors.phone}>
                <div className="invalid-text">
                  {'* Teléfono mínimo 10 digitos'}
                </div>
              </Collapse>
            </div>
            <div className="form-group w-100 mb-0">
              <input
                className="form-control form-control-lg"
                value={referrer}
                type="text"
                placeholder="Código de referido"
                onChange={this.handleChange('referrer')}
              />
            </div>
            <div className="form-group w-100 px-1 register-note mt-1">
              Opcional
            </div>
            <div className="form-group d-flex align-items-center mb-0">
              <Checkbox
                color="primary"
                checked={advisor}
                onChange={this.handleChangeCheck('advisor')}
              />
              <span className="text-light">Cuento con un asesor</span>
            </div>
            <Collapse in={advisor} className="w-100">
              <div className="form-group w-100 mb-0">
                <Select
                  value={preferredAdvisor}
                  onChange={this.handleChangeSelect}
                  options={formattedAsesores}
                  placeholder="Selecciona tu asesor"
                  isSearchable
                />
              </div>
            </Collapse>
            <div className="form-group d-flex align-items-center my-2">
              <Checkbox
                color="primary"
                checked={accept}
                onChange={this.handleChangeCheck('accept')}
              />
              <div>
                <span className="text-light">Acepto los </span>
                <span
                  className="register-check-label"
                  onClick={() => this.onViewTermsPrivacy(terms)}
                >
                  {'Términos, Condiciones'}
                </span>
                <span className="text-light">
                  {' y '}
                </span>
                <span
                  className="register-check-label"
                  onClick={() => this.onViewTermsPrivacy(privacy)}
                >
                  {'Políticas de privacidad'}
                </span>
              </div>
            </div>
            <div
              className={`invalid-text ${
                errors.accept ? '' : 'invalid-text-invisible'
              }`}
            >
              {
                '* Debes aceptar la politica de privacidad y terminos y condiciones'
              }
            </div>
            <div className="form-group d-flex flex-column justify-content-center align-items-center">
              <div className="w-100">
                <Button
                  variant="contained"
                  className="w-100 px-2 py-1 mb-2 btn_register"
                  onClick={() => this.handleSubmit()}
                >
                  Registrar
                </Button>
              </div>
              <div>
                <Link to="/sign-in" title="Register">
                  <span className="register-login-text text-light">¿Ya registrado?</span>
                </Link>
              </div>
            </div>
          </form>
        </div>
        {open && (
          <TermsPrivacyDialog
            open={open}
            onHandleModal={this.onHandleModal}
            content={content}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  asesores: state.auth.asesores,
  user: state.auth.user,
  terms: state.auth.terms,
  privacy: state.auth.privacy,
});

export default withRouter(connect(mapStateToProps)(Register));

/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import FileSaver from 'file-saver';
import UserAPI from '../../api/UserAPI';
import { loading, loaded } from './loadActions';
import {
  AUTHENTICATE_USER, GET_ASESORES_SUCCESS, GET_PRIVACY_POLICY_SUCCESS, GET_TERMS_CONDITION_SUCCESS,
  INIT_URL, REGISTER_EMAIL_PASS_SUCCESS,
  UNAUTHENTICATE_USER,
  GET_CONTRACT_CONTENT_SUCCESS,
  SET_PROFILE_STATUS_SUCCESS,
  SET_RESET_PASSWORD_KEY,
} from '../actionTypes';
import DownloadAPI from '../../api/DownloadAPI';


export function authenticate(token, isRemember, user_id) {
  return async (dispatch) => {
    const userApi = new UserAPI();
    dispatch(loading('authUser'));
    try {
      const response = await userApi.logIn(token);
      const {
        success,
        message,
        access_token,
        token_type,
        profile_status,
        last_login,
        ownership,
        cf_1130,
        cf_1132,
        cf_1811,
      } = response.data;
      if (success) {
        dispatch({
          type: AUTHENTICATE_USER,
          user: {
            token: access_token,
            token_type,
            isRemember,
            isAuth: true,
            profile_status,
            user_id,
            last_login,
            ownership,
            cf_1130,
            cf_1132,
            cf_1811,
          },
        });
      }
      else {
        toast.error(message.warning || message.error);
      }
    }

    catch (e) {
      console.log({ LoginError: e });
      toast.error(e.data.message.error || e.data.message.warning);
    }

    finally {
      dispatch(loaded('authUser'));
    }
  };
}

export function unauthenticate() {
  return async (dispatch) => {
    const userApi = new UserAPI();
    dispatch(loading('authUser'));
    try {
      await dispatch({
        type: UNAUTHENTICATE_USER,
      });
      await userApi.logOut().then(() => {
        toast.info('Hasta pronto');
      }).catch((err) => {
        toast.error(err.data.message.warning || err.data.message.error);
      });
    } catch (e) {
      console.log({ LogOutError: e });
      toast.error(e.data.message.error || e.data.message.warning);
    } finally {
      dispatch(loaded('authUser'));
    }
  };
}

export function setResetPasswordKey(key) {
  return async (dispatch) => {
    dispatch({
      type: SET_RESET_PASSWORD_KEY,
      key,
    });
  };
}

export function setProfile_Status(status) {
  return async (dispatch) => {
    dispatch({
      type: SET_PROFILE_STATUS_SUCCESS,
      status,
    });
  };
}

export const setInitUrl = url => ({
  type: INIT_URL,
  payload: url,
});

export function getAsesoresAction() {
  return async (dispatch) => {
    const userApi = new UserAPI();
    dispatch(loading('authUser'));
    try {
      const response = await userApi.getAsesorItems();
      console.log({ response });
      const { success, message, result } = response.data;
      if (success) {
        dispatch({
          type: GET_ASESORES_SUCCESS,
          payload: result,
        });
      }
      else {
        toast.error(message.error);
      }
    }

    catch (e) {
      console.log({ LoginError: e });
      toast.error('Error en la API');
    }

    finally {
      dispatch(loaded('authUser'));
    }
  };
}
export function registerWithEmailPassAction(body) {
  return async (dispatch) => {
    const userApi = new UserAPI();
    dispatch(loading('authUser'));
    try {
      await userApi.registerWithEmailPass(body).then((response) => {
        const {
          success,
          message,
          access_token,
          token_type,
          profile_status,
        } = response.data;
        if (success) {
          dispatch({
            type: REGISTER_EMAIL_PASS_SUCCESS,
            user: {
              token: access_token,
              token_type,
              isAuth: true,
              profile_status,
            },
          });
        }
        else {
          toast.error(message.error || message.warning);
        }
      }).catch((err) => {
        const { status, data } = err;
        const { message } = data;
        switch (status) {
          case 409:
            toast.warn(message.error || message.warning);
            break;
          default:
            console.log({ LoginError: message });
            toast.error(`Ups...${message.error || message.warning}`);
            break;
        }
      });
    }

    catch (e) {
      console.log({ LoginError: e });
      toast.error(`Ups...${e}`);
    }

    finally {
      dispatch(loaded('authUser'));
    }
  };
}

export function getTermsConditionContentAction() {
  return async (dispatch) => {
    const userAPI = new UserAPI();
    dispatch(loading('authUser'));
    try {
      const response = await userAPI.getTermsConditionContent();
      const { result, success, message } = response.data;
      if (success) {
        dispatch({
          type: GET_TERMS_CONDITION_SUCCESS,
          payload: result,
        });
      } else {
        toast.error(message.error);
      }
    } catch (error) {
      console.log({ getTermsConditionContentError: error });
      toast.error('Error en la API');
    }
    finally {
      dispatch(loaded('authUser'));
    }
  };
}

export function getPrivacyPolicyContentAction() {
  return async (dispatch) => {
    const userAPI = new UserAPI();
    dispatch(loading(''));
    try {
      const res = await userAPI.getPrivacyPolicyContent();
      const { result, message, success } = res.data;
      if (success) {
        dispatch({
          type: GET_PRIVACY_POLICY_SUCCESS,
          payload: result,
        });
      } else {
        toast.error(message.error);
      }
    } catch (error) {
      console.log({ getPrivacyPolicyContentActionError: error });
      toast.error('Error en la API');
    }
    finally {
      dispatch(loaded(''));
    }
  };
}

export function getContractContentAction() {
  return async (dispatch) => {
    const userAPI = new UserAPI();
    dispatch(loading(''));
    try {
      const res = await userAPI.getContractContent();
      const { success, result, message } = res.data;
      if (success) {
        dispatch({
          type: GET_CONTRACT_CONTENT_SUCCESS,
          payload: result,
        });
      } else {
        toast.error(message.error);
      }
    } catch (error) {
      console.log({ getContractContentError: error });
      if (error.response && error.response.status === 401) {
        toast.error('Una disculpa, necesita ingresar de nuevo');
        dispatch(unauthenticate());
        return;
      }
      toast.error('Error en la API');
    }
    finally {
      dispatch(loaded(''));
    }
  };
}

export function downloadContractAction() {
  return async (dispatch) => {
    const downloadAPI = new DownloadAPI();
    dispatch(loading(''));
    try {
      const url = 'https://crm.inverspot.mx/api/profile/contract/pdf';
      const response = await downloadAPI.downloadPDF(url);
      const { status, data } = response;
      if (status === 200) {
        FileSaver.saveAs(data, 'contract.pdf');
      }
      else {
        toast.error('Descarga fallida, intentelo más tarde');
      }
    }

    catch (e) {
      console.log({ downloadContractAction: e });
      if (e.response && e.response.status === 401) {
        toast.error('Una disculpa, necesita ingresar de nuevo');
        dispatch(unauthenticate());
        return;
      }
      toast.error('Error en la API');
    }

    finally {
      dispatch(loaded(''));
    }
  };
}

export function submitContractAction(password) {
  return async (dispatch) => {
    const userApi = new UserAPI();
    const body = { password };
    dispatch(loading(''));
    try {
      const response = await userApi.submitContract(body);
      const { success, message } = response.data;
      if (!success) {
        toast.error(message.error);
      } else {
        toast.success(message.success);
        dispatch(setProfile_Status('approved'));
      }
    } catch (e) {
      console.log({ submitContractAction: e });
      if (e.response && e.response.status === 401) {
        toast.error('Una disculpa, necesita ingresar de nuevo');
        dispatch(unauthenticate());
        return;
      }
      toast.error('Error en la API');
    }
    finally {
      dispatch(loaded(''));
    }
  };
}

export function requestPasswordReset(username) {
  return async (dispatch) => {
    const userApi = new UserAPI();
    const body = { username };
    dispatch(loading(''));
    try {
      const response = await userApi.requestPassword(body);
      const { success, message } = response.data;
      if (!success) {
        toast.error(message.error);
      } else {
        toast.success(message.success);
      }
    } catch (e) {
      console.log({ requestPassword: e });
      toast.error('Una disculpa no se pudo enviar el correo, intentelo más tarde');
    }
    finally {
      dispatch(loaded(''));
    }
  };
}

export function checkPasswordResetKey(key) {
  return async (dispatch) => {
    const userApi = new UserAPI();
    dispatch(loading(''));
    try {
      const response = await userApi.validatePassword(key);
      const { success } = response.data;
      if (success) {
        toast.success('Link validado');
        await dispatch(setResetPasswordKey(key));
      }
    } catch (e) {
      console.log({ checkPasswordResetKey: e });
      if (e.response && e.response.status === 400) {
        toast.error('Una disculpa, ', e.data.error);
        return;
      }
      if (e.response && e.response.status === 429) {
        toast.error('Una disculpa, se ha excedido el número permitido de intentos de restablecer la contraseña.');
        return;
      }
    }
    finally {
      await dispatch(loaded(''));
    }
  };
}

export function requestChangePassword(password, history = {}) {
  return async (dispatch, getState) => {
    const userApi = new UserAPI();
    await dispatch(loading(''));
    try {
      const { auth: { reset_password_key } } = getState();
      if (reset_password_key.length > 0) {
        const body = { password, key: reset_password_key };
        const response = await userApi.changePassword(body);
        const { success, message } = response.data;
        if (success) {
          toast.success(message.success);
          await history.push('/sign-in');
          await window.location.reload();
        }
      } else {
        toast.info('URL no es válida, coloque nuevamente la url del correo ue se le haya enviado.');
      }
    } catch (e) {
      console.log({ requestChangePassword: e });
      if (e.response && e.response.status === 400) {
        toast.error('Una disculpa, ', e.data.error);
        return;
      }
    }
    finally {
      await dispatch(loaded(''));
    }
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import Animated from 'react-dom-animated';
import { MobileStepper, Button } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

const styles = {
  root: {
    width: '100%',
  },
  slide: {
    color: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
  },
  img: {
    width: '100%',
    display: 'block',
    opacity: '1',
    borderRadius: '1em',
  },
};

class DemoCoverflow extends React.Component {
  static propTypes = {
    images: PropTypes.array,
  };

  static defaultProps = {
    images: [],
  };

  state = {
    index: 0,
    position: new Animated.Value(0),
  };

  handleChangeIndex = (index) => {
    this.setState({ index });
  };

  handleSwitch = (index, type) => {
    const { position } = this.state;
    if (type === 'end') {
      Animated.spring(position, { toValue: index }).start();
      return;
    }
    position.setValue(index);
  };

  render() {
    const { index, position } = this.state;
    const { images } = this.props;

    return (
      <span>
        <SwipeableViews
          index={index}
          onChangeIndex={this.handleChangeIndex}
          onSwitching={this.handleSwitch}
          resistance
        >
          {images.map((album, currentIndex) => {
            const inputRange = images.map((_, i) => i);
            const scale = position.interpolate({
              inputRange,
              outputRange: inputRange.map(i => (currentIndex === i ? 1 : 0.7)),
            });
            const opacity = position.interpolate({
              inputRange,
              outputRange: inputRange.map(i => (currentIndex === i ? 1 : 0.3)),
            });
            const translateX = position.interpolate({
              inputRange,
              outputRange: inputRange.map(i => (100 / 2) * (i - currentIndex)),
            });

            return (
              <Animated.div
                key={String(currentIndex)}
                style={Object.assign(
                  {
                    opacity,
                    transform: [{ scale }, { translateX }],
                  },
                  styles.slide,
                )}
              >
                <img style={styles.img} src={album} alt="cover" />
              </Animated.div>
            );
          })}
        </SwipeableViews>
        <MobileStepper
          className="imageview_stepper"
          variant="dots"
          steps={images.length}
          position="static"
          activeStep={index}
          nextButton={(
            <Button
              size="small"
              onClick={() => {
                this.handleChangeIndex(index + 1);
                this.handleSwitch((index + 1), 'end');
              }}
              disabled={index === images.length - 1}
            >
            Siguiente
              <KeyboardArrowRight />
            </Button>
)}
          backButton={(
            <Button
              size="small"
              onClick={() => {
                this.handleChangeIndex(index - 1);
                this.handleSwitch((index - 1), 'start');
              }}
              disabled={index === 0}
            >
              <KeyboardArrowLeft />
            Atras
            </Button>
          )}
        />
      </span>
    );
  }
}

export default DemoCoverflow;

/* eslint-disable radix */
/* eslint-disable no-case-declarations */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import { withStyles } from '@material-ui/core/styles';
import StepLabel from '@material-ui/core/StepLabel';
import { StepButton } from '@material-ui/core';
import { profileHelper } from '../../../helpers';

import SelectUserType from './component/SelectUserType';
import NationalPhysic1 from './component/NationalPhysic/NationalPhysic1';
import PhysicalForeigner1 from './component/PhysicalForeigner/PhysicalForeigner1';
import NationalMoral1 from './component/NationalMoral/NationalMoral1';
import MoralForeigner1 from './component/MoralForeigner/MoralForeigner1';

const getSteps = () => [1, 2, 3, 4, 5, 6, 7];

class RegisterStep extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    dispatchFetchProfileDetail: PropTypes.func.isRequired,
    dispatchFetchProfileScheme: PropTypes.func.isRequired,
    dispatchPostProfileDetail: PropTypes.func.isRequired,
    dispatchValidateAndFinishRegister: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    loads: PropTypes.object.isRequired,
    profileReducer: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeStep: 1,
    };
  }

  componentWillMount() {
    const { auth, history } = this.props;
    const { user } = auth;
    if (user.isAuth) {
      if (user.profile_status !== 'must_fill_profile') {
        history.push('/app/opportunity-investment');
      }
    }
  }

  componentDidMount() {
    const {
      auth,
      history,
      dispatchFetchProfileDetail,
      dispatchFetchProfileScheme,
    } = this.props;
    const { user } = auth;
    if (user && Object.keys(user).length > 0) {
      dispatchFetchProfileDetail();
      dispatchFetchProfileScheme();
    } else {
      history.push('sign-in');
    }
  }

  componentWillReceiveProps(next) {
    const { profileReducer } = this.props;
    if (profileReducer !== next.profileReducer) {
      console.log('profileReducer cambio');
      const { profile } = next.profileReducer;
      this.setState({
        activeStep: profileHelper.getRegistrationStep(profile),
      });
    }
  }

  // eslint-disable-next-line consistent-return
  renderStepContent = () => {
    const {
      activeStep,
    } = this.state;
    const {
      loads,
      history,
      profileReducer,
    } = this.props;
    const isLoading = Boolean(loads.loading);
    const { profile, scheme } = profileReducer;
    if (profile.tipo_de_persona === '') {
      return (
        <SelectUserType
          title="Tipo de persona *"
          onClick={this.onClickUserType}
        />
      );
    }
    const { tipo_de_persona } = profile;
    let obj = {};
    if (scheme[tipo_de_persona]) {
      Object.entries(scheme[tipo_de_persona]).forEach((attr) => {
        const valueToSet = (profile[attr[0]] || '');
        // console.log('valueToSet: ', valueToSet);
        const attrValues = attr[1];
        obj = { ...obj, [attr[0]]: { ...attrValues, value: valueToSet } };
      });
    } else {
      Object.entries(scheme).forEach((attr) => {
        const valueToSet = (profile[attr[0]] || '');
        // console.log('valueToSet: ', valueToSet);
        const attrValues = attr[1];
        obj = { ...obj, [attr[0]]: { ...attrValues, value: valueToSet } };
      });
    }
    // console.log('obj: ', obj);
    const step = activeStep;
    switch (tipo_de_persona) {
      case 'Nacional Física':
        return (
          <NationalPhysic1
            title={(step === 0
              && ('Datos personales'))
              || (step === 1 && 'Documentos y datos')
              || (step === 2 && 'Dirección')
              || (step === 3 && 'Cuenta Bancaria')
              || (step >= 4 && 'Beneficiarios')
            }
            step={step}
            scheme={obj}
            history={history}
            submit={this.submit}
            isLoading={isLoading}
            redirect={this.saveToRedirect}
            finish={this.validateAndFinish}
          />
        );
      case 'Extranjera Física':
        return (
          <PhysicalForeigner1
            title="Datos personales"
            skipStep={this.skipStep}
          />
        );
      case 'Nacional Moral':
        return (
          <NationalMoral1
            title={(step === 0
              && ('Datos personales'))
              || (step === 1 && 'Documentos y datos')
              || (step === 2 && 'Dirección')
              || (step === 3 && 'Cuenta Bancaria')
              || (step >= 4 && 'Beneficiarios')
            }
            step={step}
            scheme={obj}
            history={history}
            submit={this.submit}
            isLoading={isLoading}
            redirect={this.saveToRedirect}
            finish={this.validateAndFinish}
          />
        );
      case 'Extranjera Moral':
        return (
          <MoralForeigner1
            title="Datos personales"
            skipStep={this.skipStep}
          />
        );
      default:
        break;
    }
  };

  onClickUserType = (type) => {
    if (type) {
      const {
        profileReducer,
        dispatchPostProfileDetail,
      } = this.props;
      const { profile } = profileReducer;
      const params = { ...profile, tipo_de_persona: type };
      dispatchPostProfileDetail(params);
    }
  };

  getFormStateToSend = (form) => {
    const { profileReducer } = this.props;
    const { profile } = profileReducer;
    const state = { ...form };
    let formObject = {};
    Object.entries(state).forEach((field) => {
      const element = field[1].value;
      formObject = { ...formObject, [field[0]]: (element || '') };
    });
    // console.log('formObject: ', formObject);
    const fetchObjects = { ...profile, ...formObject };
    return fetchObjects;
  }

  submit = (form) => {
    const { dispatchPostProfileDetail } = this.props;
    const actuaForm = this.getFormStateToSend(form);
    dispatchPostProfileDetail(actuaForm);
  };

  saveToRedirect = (form) => {
    const {
      history,
      dispatchPostProfileDetail,
    } = this.props;
    const actuaForm = this.getFormStateToSend(form);
    // console.log('actualForm: ', actuaForm);
    dispatchPostProfileDetail(actuaForm, true, history, '/app/opportunity-investment');
  }

  validateAndFinish = (form) => {
    const {
      history,
      dispatchValidateAndFinishRegister,
    } = this.props;
    const actuaForm = this.getFormStateToSend(form);
    // console.log('actualForm: ', actuaForm);
    dispatchValidateAndFinishRegister(actuaForm, history, '/app/opportunity-investment');
  }

  finishStep = () => {
    const { history } = this.props;
    history.push('sign-in');
  };

  render() {
    const steps = getSteps();
    const { classes, loads, profileReducer } = this.props;
    const { profile } = profileReducer;
    const isLoading = Boolean(loads.loading);
    const step = profileHelper.getRegistrationStep(profile);
    const actualStep = (Number.parseInt(step) + 3);
    return (
      <div className="account_panel_step">
        <div className="w-100 h-auto d-flex flex-column os-y">
          <div className="account_panel_step_children">
            {!isLoading && (this.renderStepContent())}
          </div>
        </div>
        <div className="account_panel_step_steeper">
          <Stepper
            alternativeLabel
            activeStep={profile.tipo_de_persona === '' ? (1) : (Number.parseInt(step) + 2)}
            connector={<QontoConnector />}
          >
            {steps.map(stepMap => (
              <Step key={stepMap}>
                {(stepMap <= actualStep && stepMap > 2) ? (
                  <StepButton
                    onClick={() => this.setState({ activeStep: (stepMap - 3) })}
                    StepIconProps={{
                      classes: {
                        active: 'step_active',
                        completed: classes.completed,
                        root: 'step_icon',
                      },
                    }}
                  />
                ) : (
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        active: 'step_active',
                        completed: classes.completed,
                        root: 'step_icon',
                      },
                    }}
                  />
                )}
              </Step>
            ))}
          </Stepper>
        </div>
      </div>
    );
  }
}

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#CCCCCC',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#CCCCCC',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);
// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  active: {},
  completed: {
    color: '#FFFFFF !important',
  },
  stepIcon: {
    color: '#CCCCCC',
  },
});

export default withStyles(styles)(RegisterStep);

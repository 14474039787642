import React, { Component } from "react";
import { List, Divider } from "@material-ui/core";
import ApartadasItem from "./components/ApartadasItem";
import { connect } from "react-redux";
import { fetchParticipationList } from "../../redux/actions";
import Header from "../../components/Header";

class ApartadasPage extends Component {
  componentDidMount() {
    this.props.dispatch(fetchParticipationList());
  }

  onClickItem = id => {
    this.props.history.push(`apartadas/${id}`);
  };

  render() {
    const { participations } = this.props;
    return (
      <>
        <Header title={"Apartadas"} />
        <div className="container">
          {participations.length > 0 ? (
            <List className="minHe50vh">
              {participations.map((participation, index) => (
                <div key={"participation" + index}>
                  <ApartadasItem
                    item={participation}
                    onClick={this.onClickItem}
                  />
                  <Divider component="li" />
                </div>
              ))}
            </List>
          ) : (
            <List className="minHe50vh">
              <div className="p-4 w-100 h-100 d-flex align-items-center justify-content-center">
                <h4>Parece que aún no tiene oportunidades de inversión apartadas.</h4>
              </div>
            </List>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ participation }) => {
  const { participations } = participation;
  return {
    participations
  };
};
export default connect(mapStateToProps)(ApartadasPage);

const actividades = [
  {
    value: 'Actividades comerciales',
  },
  {
    value: 'Economía informal',
  },
  {
    value: 'Empleado del sector Público (especificar área)',
  },
  {
    value: 'Empleado del sector Privado (especificar área)',
  },
  {
    value: 'Jubilado',
  },
  {
    value: 'Abogados o Contadores o Profesionistas relacionados con manejo de activos (valores, dinero, bienes, etc)',
  },
  {
    value: 'Profesionistas',
  },
  {
    value: 'Consultorios de psicología del sector privado',
  },
  {
    value: 'Consultorios dentales del sector privado',
  },
  {
    value: 'Relación con derechos personales de uso o goce de Bienes Inmuebles',
  },
  {
    value: 'Fé pública (Notario - Corredor - Servidor Público)',
  },
  {
    value: 'APNFD (ONG o Donativos)',
  },
  {
    value: 'Restaurantes',
  },
  {
    value: 'Tiendas minoristas',
  },
  {
    value: 'Licorerías',
  },
  {
    value: 'Distribuidoras de cigarrillos',
  },
  {
    value: 'Operadores de máquinas expendedoras',
  },
  {
    value: 'Gasolinerias',
  },
  {
    value: 'Estacionamientos públicos de vehículos',
  },
  {
    value: 'Juegos con apuestas, concursos o sorteos',
  },
  {
    value: 'Emisión o comercialización de tarjetas de servicios, crédito, prepagadas o similares',
  },
  {
    value: 'Emisión y comercialización de cheques de viajero',
  },
  {
    value: 'Operaciones de mutuo o garantía, otorgamiento de préstamos o créditos; con o sin garantía',
  },
  {
    value: 'Bienes inmuebles (o relacionado)',
  },
  {
    value: 'Metales o Piedras Preciosas, joyas o relojes',
  },
  {
    value: 'Obras de arte subastadas o comercializadas',
  },
  {
    value: 'Comercialización o distribución de vehículos, nuevos o usados (aéreos, marítimos o terrestres)',
  },
  {
    value: 'Servicios de blindaje de vehículos terrestres, nuevos o usados y bienes inmuebles',
  },
  {
    value: 'Servicios de traslado o custodia de dinero o valores',
  },
  {
    value: 'Agente o apoderado aduanal',
  },
  {
    value: 'Intercambio de activos virtuales',
  },
  {
    value: 'Acabado de productos textiles',
  },
  {
    value: 'Comercio al por mayor de fibras, hilos y telas',
  },
  {
    value: 'Fabricación de calcetines y medias de tejido de punto',
  },
  {
    value: 'Fabricación de productos textiles reciclados',
  },
  {
    value: 'Administración de cajas de pensión y de seguros independientes',
  },
  {
    value: 'Agencias de anuncios publicitarios',
  },
  {
    value: 'Agencias de publicidad',
  },
  {
    value: 'Agencias de viajes',
  },
  {
    value: 'Alineación y balanceo de automóviles y camiones',
  },
  {
    value: 'Almacenamiento con refrigeración',
  },
  {
    value: 'Almacenamiento de productos agrícolas que no requieren refrigeración',
  },
  {
    value: 'Alquiler de autobuses con chofer',
  },
  {
    value: 'Alquiler de automóviles con chofer',
  },
  {
    value: 'Apicultura',
  },
  {
    value: 'Aserraderos integrados',
  },
  {
    value: 'Aserrado de tablas y tablones',
  },
  {
    value: 'Autotransporte foráneo con refrigeración',
  },
  {
    value: 'Autotransporte foráneo de madera',
  },
  {
    value: 'Autotransporte foráneo de materiales para la construcción',
  },
  {
    value: 'Autotransporte local con refrigeración',
  },
  {
    value: 'Autotransporte local de madera',
  },
  {
    value: 'Autotransporte local de materiales para la construcción',
  },
  {
    value: 'Baños públicos',
  },
  {
    value: 'Beneficio de productos agrícolas',
  },
  {
    value: 'Beneficio del arroz',
  },
  {
    value: 'Beneficio del café',
  },
  {
    value: 'Beneficio del tabaco',
  },
  {
    value: 'Bibliotecas y archivos del sector privado',
  },
  {
    value: 'Cabañas, villas y similares',
  },
  {
    value: 'Cafeterías, fuentes de sodas, neverías, refresquerías y similares',
  },
  {
    value: 'Cajas de ahorro popular',
  },
  {
    value: 'Camaronicultura',
  },
  {
    value: 'Campamentos y albergues recreativos',
  },
  {
    value: 'Cantantes y grupos musicales del sector privado',
  },
  {
    value: 'Centros de acondicionamiento físico del sector privado',
  },
  {
    value: 'Cerrajerías',
  },
  {
    value: 'Colocación de muros falsos y aislamiento',
  },
  {
    value: 'Colocación de pisos cerámicos y azulejos',
  },
  {
    value: 'Colocación de pisos flexibles y de madera',
  },
  {
    value: 'Comercio al por mayor de abarrotes',
  },
  {
    value: 'Comercio al por mayor de artículos de joyería y relojes',
  },
  {
    value: 'Comercio al por mayor de artículos de papelería',
  },
  {
    value: 'Comercio al por mayor de artículos desechables',
  },
  {
    value: 'Comercio al por mayor de artículos y accesorios para diseño y pintura artística',
  },
  {
    value: 'Comercio al por mayor de artículos y aparatos deportivos',
  },
  {
    value: 'Comercio al por mayor de bebidas no alcohólicas y hielo',
  },
  {
    value: 'Comercio al por mayor de blancos',
  },
  {
    value: 'Comercio al por mayor de botanas y frituras',
  },
  {
    value: 'Comercio al por mayor de calzado',
  },
  {
    value: 'Comercio al por mayor de carne de aves',
  },
  {
    value: 'Comercio al por mayor de carnes rojas',
  },
  {
    value: 'Comercio al por mayor de cemento, tabique y grava',
  },
  {
    value: 'Comercio al por mayor de conservas alimenticias',
  },
  {
    value: 'Comercio al por mayor de cueros y pieles',
  },
  {
    value: 'Comercio al por mayor de desechos de papel y de cartón',
  },
  {
    value: 'Comercio al por mayor de desechos de plástico',
  },
  {
    value: 'Comercio al por mayor de desechos de vidrio',
  },
  {
    value: 'Comercio al por mayor de desechos metálicos',
  },
  {
    value: 'Comercio al por mayor de dulces y materias primas para repostería',
  },
  {
    value: 'Comercio al por mayor de electrodomésticos menores y aparatos de línea blanca',
  },
  {
    value: 'Comercio al por mayor de embutidos',
  },
  {
    value: 'Comercio al por mayor de envases en general, papel y cartón para la industria',
  },
  {
    value: 'Comercio al por mayor de equipo de telecomunicaciones, fotografía y cinematografía',
  },
  {
    value: 'Comercio al por mayor de equipo y material eléctrico',
  },
  {
    value: 'Comercio al por mayor de fertilizantes, plaguicidas y semillas para siembra',
  },
  {
    value: 'Comercio al por mayor de frutas y verduras frescas',
  },
  {
    value: 'Comercio al por mayor de ganado y aves en pie',
  },
  {
    value: 'Comercio al por mayor de huevo',
  },
  {
    value: 'Comercio al por mayor de juguetes y bicicletas',
  },
  {
    value: 'Comercio al por mayor de leche y otros productos lácteos',
  },
  {
    value: 'Comercio al por mayor de libros',
  },
  {
    value: 'Comercio al por mayor de madera para la construcción y la industria',
  },
  {
    value: 'Comercio al por mayor de maquinaria y equipo agropecuario, forestal y para la pesca',
  },
  {
    value: 'Comercio al por mayor de maquinaria y equipo para la construcción y la minería',
  },
  {
    value: 'Comercio al por mayor de maquinaria y equipo para la industria manufacturera',
  },
  {
    value: 'Comercio al por mayor de maquinaria y equipo para otros servicios y para actividades comerciales',
  },
  {
    value: 'Comercio al por mayor de materiales metálicos para la construcción y la manufactura',
  },
  {
    value: 'Comercio al por mayor de medicamentos veterinarios y alimentos para animales, excepto mascotas',
  },
  {
    value: 'Comercio al por mayor de miel',
  },
  {
    value: 'Comercio al por mayor de mobiliario y equipo de oficina',
  },
  {
    value: 'Comercio al por mayor de mobiliario, equipo e instrumental médico y de laboratorio',
  },
  {
    value: 'Comercio al por mayor de mobiliario, equipo, y accesorios de cómputo',
  },
  {
    value: 'Comercio al por mayor de otra maquinaria y equipo de uso general',
  },
  {
    value: 'Comercio al por mayor de otras materias primas para otras industrias',
  },
  {
    value: 'Comercio al por mayor de otros alimentos',
  },
  {
    value: 'Comercio al por mayor de otros materiales de desecho',
  },
  {
    value: 'Comercio al por mayor de otros materiales para la construcción, excepto de madera y metálicos',
  },
  {
    value: 'Comercio al por mayor de otros productos textiles',
  },
  {
    value: 'Comercio al por mayor de pan y pasteles',
  },
  {
    value: 'Comercio al por mayor de pescados y mariscos',
  },
  {
    value: 'Comercio al por mayor de pintura',
  },
  {
    value: 'Comercio al por mayor de productos farmacéuticos',
  },
  {
    value: 'Comercio al por mayor de revistas y periódicos',
  },
  {
    value: 'Comercio al por mayor de ropa, bisutería y accesorios de vestir',
  },
  {
    value: 'Comercio al por mayor de semillas y granos alimenticios, especias y chiles secos',
  },
  {
    value: 'Comercio al por mayor de vidrios y espejos',
  },
  {
    value: 'Comercio al por menor de aceites y grasas lubricantes, aditivos y similares para vehículos de motor',
  },
  {
    value: 'Comercio al por menor de alfombras, cortinas, tapices y similares',
  },
  {
    value: 'Comercio al por menor de antigüedades y obras de arte',
  },
  {
    value: 'Comercio al por menor de artículos de joyería y relojes',
  },
  {
    value: 'Comercio al por menor de artículos de mercería y bonetería',
  },
  {
    value: 'Comercio al por menor de artículos de papelería',
  },
  {
    value: 'Comercio al por menor de artículos de perfumería y cosméticos',
  },
  {
    value: 'Comercio al por menor de artículos desechables',
  },
  {
    value: 'Comercio al por menor de artículos ortopédicos',
  },
  {
    value: 'Comercio al por menor de artículos para albercas y otros artículos',
  },
  {
    value: 'Comercio al por menor de artículos para la limpieza',
  },
  {
    value: 'Comercio al por menor de artículos religiosos',
  },
  {
    value: 'Comercio al por menor de artículos usados',
  },
  {
    value: 'Comercio al por menor de artículos y aparatos deportivos',
  },
  {
    value: 'Comercio al por menor de bebidas no alcohólicas y hielo',
  },
  {
    value: 'Comercio al por menor de bicicletas',
  },
  {
    value: 'Comercio al por menor de bisutería y accesorios de vestir',
  },
  {
    value: 'Comercio al por menor de blancos',
  },
  {
    value: 'Comercio al por menor de calzado',
  },
  {
    value: 'Comercio al por menor de carne de aves',
  },
  {
    value: 'Comercio al por menor de carnes rojas',
  },
  {
    value: 'Comercio al por menor de cristalería, loza y utensilios de cocina',
  },
  {
    value: 'Comercio al por menor de dulces y materias primas para repostería',
  },
  {
    value: 'Comercio al por menor de electrodomésticos menores y aparatos de línea blanca',
  },
  {
    value: 'Comercio al por menor de equipo y material fotográfico',
  },
  {
    value: 'Comercio al por menor de frutas y verduras frescas',
  },
  {
    value: 'Comercio al por menor de instrumentos musicales',
  },
  {
    value: 'Comercio al por menor de juguetes',
  },
  {
    value: 'Comercio al por menor de lámparas ornamentales y candiles',
  },
  {
    value: 'Comercio al por menor de leche, otros productos lácteos y embutidos',
  },
  {
    value: 'Comercio al por menor de lencería',
  },
  {
    value: 'Comercio al por menor de lentes',
  },
  {
    value: 'Comercio al por menor de libros',
  },
  {
    value: 'Comercio al por menor de llantas y cámaras para automóviles, camionetas y camiones',
  },
  {
    value: 'Comercio al por menor de mascotas',
  },
  {
    value: 'Comercio al por menor de materiales para la construcción en tiendas de autoservicio especializadas',
  },
  {
    value: 'Comercio al por menor de mobiliario, equipo y accesorios de cómputo',
  },
  {
    value: 'Comercio al por menor de motocicletas',
  },
  {
    value: 'Comercio al por menor de muebles para el hogar',
  },
  {
    value: 'Comercio al por menor de muebles para jardín',
  },
  {
    value: 'Comercio al por menor de otros alimentos',
  },
  {
    value: 'Comercio al por menor de otros artículos de uso personal',
  },
  {
    value: 'Comercio al por menor de otros artículos para la decoración de interiores',
  },
  {
    value: 'Comercio al por menor de otros combustibles',
  },
  {
    value: 'Comercio al por menor de paletas de hielo y helados',
  },
  {
    value: 'Comercio al por menor de pañales desechables',
  },
  {
    value: 'Comercio al por menor de partes y refacciones nuevas para automóviles, camionetas y camiones',
  },
  {
    value: 'Comercio al por menor de partes y refacciones usadas para automóviles, camionetas y camiones',
  },
  {
    value: 'Comercio al por menor de pescados y mariscos',
  },
  {
    value: 'Comercio al por menor de pintura',
  },
  {
    value: 'Comercio al por menor de pisos y recubrimientos cerámicos',
  },
  {
    value: 'Comercio al por menor de plantas y flores naturales',
  },
  {
    value: 'Comercio al por menor de productos naturistas, medicamentos homeopáticos y de complementos alimenticios',
  },
  {
    value: 'Comercio al por menor de regalos',
  },
  {
    value: 'Comercio al por menor de revistas y periódicos',
  },
  {
    value: 'Comercio al por menor de ropa de bebé',
  },
  {
    value: 'Comercio al por menor de ropa de cuero y piel y de otros artículos de estos materiales',
  },
  {
    value: 'Comercio al por menor de ropa, excepto de bebé y lencería',
  },
  {
    value: 'Comercio al por menor de semillas y granos alimenticios, especias y chiles secos',
  },
  {
    value: 'Comercio al por menor de sombreros',
  },
  {
    value: 'Comercio al por menor de telas',
  },
  {
    value: 'Comercio al por menor de teléfonos y otros aparatos de comunicación',
  },
  {
    value: 'Comercio al por menor de vidrios y espejos',
  },
  {
    value: 'Comercio al por menor en ferreterías y tlapalerías',
  },
  {
    value: 'Comercio al por menor en minisupers',
  },
  {
    value: 'Comercio al por menor en supermercados',
  },
  {
    value: 'Comercio al por menor en tiendas de abarrotes, ultramarinos y misceláneas',
  },
  {
    value: 'Comercio al por menor en tiendas de artesanías',
  },
  {
    value: 'Compañías de danza del sector privado',
  },
  {
    value: 'Compañías de factoraje financiero',
  },
  {
    value: 'Compañías de teatro del sector privado',
  },
  {
    value: 'Confección de cortinas, blancos y similares',
  },
  {
    value: 'Confección de costales',
  },
  {
    value: 'Confección de otros accesorios y prendas de vestir no clasificados en otra parte',
  },
  {
    value: 'Confección de prendas de vestir de cuero, piel y de materiales sucedáneos',
  },
  {
    value: 'Confección de prendas de vestir sobre medida',
  },
  {
    value: 'Confección de productos de textiles recubiertos y de materiales sucedáneos',
  },
  {
    value: 'Confección de sombreros y gorras',
  },
  {
    value: 'Confección en serie de camisas',
  },
  {
    value: 'Confección en serie de disfraces y trajes típicos',
  },
  {
    value: 'Confección en serie de otra ropa exterior de materiales textiles',
  },
  {
    value: 'Confección en serie de ropa interior y de dormir',
  },
  {
    value: 'Confección en serie de uniformes',
  },
  {
    value: 'Confección, bordado y deshilado de productos textiles',
  },
  {
    value: 'Congelación de frutas y verduras',
  },
  {
    value: 'Congelación de guisos y otros alimentos preparados',
  },
  {
    value: 'Conservación de frutas y verduras por procesos distintos a la congelación y la deshidratación',
  },
  {
    value: 'Conservación de guisos y otros alimentos preparados por procesos distintos a la congelación',
  },
  {
    value: 'Construcción de carreteras, puentes y similares',
  },
  {
    value: 'Construcción de obras de urbanización',
  },
  {
    value: 'Construcción de presas y represas',
  },
  {
    value: 'Construcción de sistemas de riego agrícola',
  },
  {
    value: 'Corte y empacado de carne de ganado, aves y otros animales comestibles',
  },
  {
    value: 'Cultivo anual de otras semillas oleaginosas',
  },
  {
    value: 'Cultivo de agaves alcoholeros',
  },
  {
    value: 'Cultivo de aguacate',
  },
  {
    value: 'Cultivo de alfalfa',
  },
  {
    value: 'Cultivo de algodón',
  },
  {
    value: 'Cultivo de árboles de ciclo productivo de 10 años o menos',
  },
  {
    value: 'Cultivo de arroz',
  },
  {
    value: 'Cultivo de avena forrajera',
  },
  {
    value: 'Cultivo de avena grano',
  },
  {
    value: 'Cultivo de cacahuate',
  },
  {
    value: 'Cultivo de cacao',
  },
  {
    value: 'Cultivo de café',
  },
  {
    value: 'Cultivo de calabaza',
  },
  {
    value: 'Cultivo de caña de azúcar',
  },
  {
    value: 'Cultivo de cártamo',
  },
  {
    value: 'Cultivo de cebada grano',
  },
  {
    value: 'Cultivo de cebolla',
  },
  {
    value: 'Cultivo de chile',
  },
  {
    value: 'Cultivo de coco',
  },
  {
    value: 'Cultivo de frijol grano',
  },
  {
    value: 'Cultivo de garbanzo grano',
  },
  {
    value: 'Cultivo de girasol',
  },
  {
    value: 'Cultivo de jitomate o tomate rojo',
  },
  {
    value: 'Cultivo de limón',
  },
  {
    value: 'Cultivo de maíz forrajero',
  },
  {
    value: 'Cultivo de maíz grano',
  },
  {
    value: 'Cultivo de mango',
  },
  {
    value: 'Cultivo de manzana',
  },
  {
    value: 'Cultivo de melón',
  },
  {
    value: 'Cultivo de naranja',
  },
  {
    value: 'Cultivo de otras hortalizas',
  },
  {
    value: 'Cultivo de otras leguminosas',
  },
  {
    value: 'Cultivo de otros cereales',
  },
  {
    value: 'Cultivo de otros cítricos',
  },
  {
    value: 'Cultivo de otros frutales no cítricos y de nueces',
  },
  {
    value: 'Cultivo de papa',
  },
  {
    value: 'Cultivo de pastos',
  },
  {
    value: 'Cultivo de plátano',
  },
  {
    value: 'Cultivo de productos alimenticios en invernaderos',
  },
  {
    value: 'Cultivo de sandía',
  },
  {
    value: 'Cultivo de sorgo forrajero',
  },
  {
    value: 'Cultivo de sorgo grano',
  },
  {
    value: 'Cultivo de soya',
  },
  {
    value: 'Cultivo de tabaco',
  },
  {
    value: 'Cultivo de tomate verde',
  },
  {
    value: 'Cultivo de trigo',
  },
  {
    value: 'Cultivo de uva',
  },
  {
    value: 'Cunicultura y explotación de animales con pelaje fino',
  },
  {
    value: 'Curtido y acabado de cuero y piel',
  },
  {
    value: 'Deshidratación de frutas y verduras',
  },
  {
    value: 'Despepite de algodón',
  },
  {
    value: 'Diseño de modas y otros diseños especializados',
  },
  {
    value: 'Diseño gráfico',
  },
  {
    value: 'Diseño industrial',
  },
  {
    value: 'Diseño y decoración de interiores',
  },
  {
    value: 'Distribución de material publicitario',
  },
  {
    value: 'División de terrenos',
  },
  {
    value: 'Edición de directorios y de listas de correo',
  },
  {
    value: 'Edición de directorios y de listas de correo integrada con la impresión',
  },
  {
    value: 'Edición de libros',
  },
  {
    value: 'Edición de libros integrada con la impresión',
  },
  {
    value: 'Edición de otros materiales',
  },
  {
    value: 'Edición de otros materiales integrada con la impresión',
  },
  {
    value: 'Edición de periódicos',
  },
  {
    value: 'Edición de periódicos integrada con la impresión',
  },
  {
    value: 'Edición de revistas y otras publicaciones periódicas',
  },
  {
    value: 'Edición de revistas y otras publicaciones periódicas integrada con la impresión',
  },
  {
    value: 'Edición de software y edición de software integrada con la reproducción',
  },
  {
    value: 'Edición y difusión de contenido exclusivamente a través de Internet y servicios de búsqueda en la red',
  },
  {
    value: 'Edificación de inmuebles comerciales y de servicios, excepto la supervisión',
  },
  {
    value: 'Edificación de naves y plantas industriales, excepto la supervisión',
  },
  {
    value: 'Editoras de música',
  },
  {
    value: 'Elaboración de aceites y grasas vegetales comestibles',
  },
  {
    value: 'Elaboración de alimentos frescos para consumo inmediato',
  },
  {
    value: 'Elaboración de alimentos para animales',
  },
  {
    value: 'Elaboración de azúcar de caña',
  },
  {
    value: 'Elaboración de bebidas destiladas de agave',
  },
  {
    value: 'Elaboración de botanas',
  },
  {
    value: 'Elaboración de café instantáneo',
  },
  {
    value: 'Elaboración de café tostado y molido',
  },
  {
    value: 'Elaboración de cereales para el desayuno',
  },
  {
    value: 'Elaboración de chocolate y productos de chocolate',
  },
  {
    value: 'Elaboración de concentrados, polvos, jarabes y esencias de sabor para bebidas',
  },
  {
    value: 'Elaboración de condimentos y aderezos',
  },
  {
    value: 'Elaboración de derivados y fermentos lácteos',
  },
  {
    value: 'Elaboración de dulces, chicles y productos de confitería que no sean de chocolate',
  },
  {
    value: 'Elaboración de féculas y otros almidones y sus derivados',
  },
  {
    value: 'Elaboración de galletas y pastas para sopa',
  },
  {
    value: 'Elaboración de gelatinas y otros postres en polvo',
  },
  {
    value: 'Elaboración de harina de maíz',
  },
  {
    value: 'Elaboración de harina de otros productos agrícolas',
  },
  {
    value: 'Elaboración de harina de trigo',
  },
  {
    value: 'Elaboración de helados y paletas',
  },
  {
    value: 'Elaboración de hielo',
  },
  {
    value: 'Elaboración de leche en polvo, condensada y evaporada',
  },
  {
    value: 'Elaboración de leche líquida',
  },
  {
    value: 'Elaboración de levadura',
  },
  {
    value: 'Elaboración de malta',
  },
  {
    value: 'Elaboración de manteca y otras grasas animales comestibles',
  },
  {
    value: 'Elaboración de otros alimentos',
  },
  {
    value: 'Elaboración de otros azúcares',
  },
  {
    value: 'Elaboración de refrescos y otras bebidas no alcohólicas',
  },
  {
    value: 'Elaboración de ron y otras bebidas destiladas de caña',
  },
  {
    value: 'Elaboración de tortillas de maíz y molienda de nixtamal',
  },
  {
    value: 'Escuelas de arte del sector privado',
  },
  {
    value: 'Escuelas de deporte del sector privado',
  },
  {
    value: 'Escuelas del sector privado dedicadas a la enseñanza de oficios',
  },
  {
    value: 'Explotación de animales combinada con aprovechamiento forestal',
  },
  {
    value: 'Explotación de bovinos para la producción conjunta de leche y carne',
  },
  {
    value: 'Explotación de bovinos para la producción de carne',
  },
  {
    value: 'Explotación de bovinos para la producción de leche',
  },
  {
    value: 'Explotación de caprinos',
  },
  {
    value: 'Explotación de équidos',
  },
  {
    value: 'Explotación de gallinas para la producción de huevo fértil',
  },
  {
    value: 'Explotación de gallinas para la producción de huevo para plato',
  },
  {
    value: 'Explotación de guajolotes o pavos',
  },
  {
    value: 'Explotación de otras aves para producción de carne y huevo',
  },
  {
    value: 'Explotación de ovinos',
  },
  {
    value: 'Explotación de pollos para la producción de carne',
  },
  {
    value: 'Explotación de porcinos en granja',
  },
  {
    value: 'Explotación de porcinos en traspatio',
  },
  {
    value: 'Fabricación de acumuladores y pilas',
  },
  {
    value: 'Fabricación de alambre, productos de alambre y resortes',
  },
  {
    value: 'Fabricación de alfombras y tapetes',
  },
  {
    value: 'Fabricación de anuncios y señalamientos',
  },
  {
    value: 'Fabricación de aparatos de línea blanca',
  },
  {
    value: 'Fabricación de aparatos e instrumentos para pesar',
  },
  {
    value: 'Fabricación de aparatos fotográficos',
  },
  {
    value: 'Fabricación de artículos de alfarería, porcelana y loza',
  },
  {
    value: 'Fabricación de artículos de vidrio de uso doméstico',
  },
  {
    value: 'Fabricación de artículos de vidrio de uso industrial y comercial',
  },
  {
    value: 'Fabricación de artículos deportivos',
  },
  {
    value: 'Fabricación de artículos oftálmicos',
  },
  {
    value: 'Fabricación de artículos y accesorios para escritura, pintura, dibujo y actividades de oficina',
  },
  {
    value: 'Fabricación de artículos y utensilios de madera para el hogar',
  },
  {
    value: 'Fabricación de ataúdes',
  },
  {
    value: 'Fabricación de autopartes de plástico con y sin reforzamiento',
  },
  {
    value: 'Fabricación de azulejos y losetas no refractarias',
  },
  {
    value: 'Fabricación de baleros y rodamientos',
  },
  {
    value: 'Fabricación de bandas y mangueras de hule y de plástico',
  },
  {
    value: 'Fabricación de banderas y otros productos textiles no clasificados en otra parte',
  },
  {
    value: 'Fabricación de bicicletas y triciclos',
  },
  {
    value: 'Fabricación de bolsas de papel y productos celulósicos recubiertos y tratados',
  },
  {
    value: 'Fabricación de bolsas y películas de plástico flexible',
  },
  {
    value: 'Fabricación de bolsos de mano, maletas y similares',
  },
  {
    value: 'Fabricación de bombas y sistemas de bombeo',
  },
  {
    value: 'Fabricación de botellas de plástico',
  },
  {
    value: 'Fabricación de cables de conducción eléctrica',
  },
  {
    value: 'Fabricación de cal',
  },
  {
    value: 'Fabricación de calzado con corte de piel y cuero',
  },
  {
    value: 'Fabricación de calzado con corte de tela',
  },
  {
    value: 'Fabricación de calzado de hule',
  },
  {
    value: 'Fabricación de calzado de plástico',
  },
  {
    value: 'Fabricación de cartón en plantas integradas',
  },
  {
    value: 'Fabricación de cartón y cartoncillo a partir de pulpa',
  },
  {
    value: 'Fabricación de cierres, botones y agujas',
  },
  {
    value: 'Fabricación de cocinas integrales y muebles modulares de baño',
  },
  {
    value: 'Fabricación de colchones',
  },
  {
    value: 'Fabricación de componentes electrónicos',
  },
  {
    value: 'Fabricación de cosméticos, perfumes y otras preparaciones de tocador',
  },
  {
    value: 'Fabricación de enchufes, contactos, fusibles y otros accesorios para instalaciones eléctricas',
  },
  {
    value: 'Fabricación de enseres electrodomésticos menores',
  },
  {
    value: 'Fabricación de envases de cartón',
  },
  {
    value: 'Fabricación de envases metálicos de calibre ligero',
  },
  {
    value: 'Fabricación de envases y ampolletas de vidrio',
  },
  {
    value: 'Fabricación de envases y contenedores de plástico para embalaje con y sin reforzamiento',
  },
  {
    value: 'Fabricación de equipo de aire acondicionado y calefacción',
  },
  {
    value: 'Fabricación de equipo de refrigeración industrial y comercial',
  },
  {
    value: 'Fabricación de equipo no electrónico para uso médico, dental y para laboratorio',
  },
  {
    value: 'Fabricación de equipo para soldar y soldaduras',
  },
  {
    value: 'Fabricación de equipo y aparatos de distribución de energía eléctrica',
  },
  {
    value: 'Fabricación de escobas, cepillos y similares',
  },
  {
    value: 'Fabricación de escobas, cepillos y similares',
  },
  {
    value: 'Fabricación de espejos',
  },
  {
    value: 'Fabricación de espumas y productos de poliestireno',
  },
  {
    value: 'Fabricación de espumas y productos de uretano',
  },
  {
    value: 'Fabricación de estructuras metálicas',
  },
  {
    value: 'Fabricación de fibra de vidrio',
  },
  {
    value: 'Fabricación de fibras químicas',
  },
  {
    value: 'Fabricación de focos',
  },
  {
    value: 'Fabricación de herrajes y cerraduras',
  },
  {
    value: 'Fabricación de herramientas de mano metálicas sin motor',
  },
  {
    value: 'Fabricación de hilos para coser y bordar',
  },
  {
    value: 'Fabricación de huaraches y calzado de otro tipo de materiales',
  },
  {
    value: 'Fabricación de hules sintéticos',
  },
  {
    value: 'Fabricación de instrumentos musicales',
  },
  {
    value: 'Fabricación de jabones, limpiadores y dentífricos',
  },
  {
    value: 'Fabricación de juguetes',
  },
  {
    value: 'Fabricación de ladrillos no refractarios',
  },
  {
    value: 'Fabricación de laminados de plástico rígido',
  },
  {
    value: 'Fabricación de laminados y aglutinados de madera',
  },
  {
    value: 'Fabricación de lámparas ornamentales',
  },
  {
    value: 'Fabricación de llantas y cámaras',
  },
  {
    value: 'Fabricación de maquinaria y equipo agrícola',
  },
  {
    value: 'Fabricación de maquinaria y equipo para envasar y empacar',
  },
  {
    value: 'Fabricación de maquinaria y equipo para la construcción',
  },
  {
    value: 'Fabricación de maquinaria y equipo para la industria alimentaria y de las bebidas',
  },
  {
    value: 'Fabricación de maquinaria y equipo para la industria de la impresión',
  },
  {
    value: 'Fabricación de maquinaria y equipo para la industria de la madera',
  },
  {
    value: 'Fabricación de maquinaria y equipo para la industria del hule y del plástico',
  },
  {
    value: 'Fabricación de maquinaria y equipo para la industria del vidrio y otros minerales no metálicos',
  },
  {
    value: 'Fabricación de maquinaria y equipo para la industria extractiva',
  },
  {
    value: 'Fabricación de maquinaria y equipo para la industria metalmecánica',
  },
  {
    value: 'Fabricación de maquinaria y equipo para la industria textil',
  },
  {
    value: 'Fabricación de maquinaria y equipo para levantar y trasladar',
  },
  {
    value: 'Fabricación de maquinaria y equipo para otras industrias manufactureras',
  },
  {
    value: 'Fabricación de maquinaria y equipo pecuario',
  },
  {
    value: 'Fabricación de máquinas fotocopiadoras',
  },
  {
    value: 'Fabricación de material desechable de uso médico',
  },
  {
    value: 'Fabricación de motores de combustión interna, turbinas y transmisiones',
  },
  {
    value: 'Fabricación de motores y generadores eléctricos',
  },
  {
    value: 'Fabricación de muebles de baño',
  },
  {
    value: 'Fabricación de muebles de oficina y estantería',
  },
  {
    value: 'Fabricación de muebles, excepto cocinas integrales, muebles modulares de baño y muebles de oficina y estantería',
  },
  {
    value: 'Fabricación de otra maquinaria y equipo para el comercio y los servicios',
  },
  {
    value: 'Fabricación de otra maquinaria y equipo para la industria en general',
  },
  {
    value: 'Fabricación de otros equipos de comunicación',
  },
  {
    value: 'Fabricación de otros instrumentos de medición, control, navegación, y equipo médico electrónico',
  },
  {
    value: 'Fabricación de otros productos a base de minerales no metálicos',
  },
  {
    value: 'Fabricación de otros productos de cartón y papel',
  },
  {
    value: 'Fabricación de otros productos de cuero, piel y materiales sucedáneos',
  },
  {
    value: 'Fabricación de otros productos de hule',
  },
  {
    value: 'Fabricación de otros productos de madera',
  },
  {
    value: 'Fabricación de otros productos de plástico con reforzamiento',
  },
  {
    value: 'Fabricación de otros productos de plástico de uso industrial sin reforzamiento',
  },
  {
    value: 'Fabricación de otros productos de plástico sin reforzamiento',
  },
  {
    value: 'Fabricación de otros productos de vidrio',
  },
  {
    value: 'Fabricación de otros productos eléctricos',
  },
  {
    value: 'Fabricación de otros productos metálicos',
  },
  {
    value: 'Fabricación de otros productos químicos',
  },
  {
    value: 'Fabricación de pañales desechables y productos sanitarios',
  },
  {
    value: 'Fabricación de papel a partir de pulpa',
  },
  {
    value: 'Fabricación de papel en plantas integradas',
  },
  {
    value: 'Fabricación de persianas y cortineros',
  },
  {
    value: 'Fabricación de pigmentos y colorantes sintéticos',
  },
  {
    value: 'Fabricación de productos a base de piedras de cantera',
  },
  {
    value: 'Fabricación de productos abrasivos',
  },
  {
    value: 'Fabricación de productos de herrería',
  },
  {
    value: 'Fabricación de productos de madera de uso industrial',
  },
  {
    value: 'Fabricación de productos de madera para la construcción',
  },
  {
    value: 'Fabricación de productos de materiales trenzables, excepto palma',
  },
  {
    value: 'Fabricación de productos de papelería',
  },
  {
    value: 'Fabricación de productos de plástico para el hogar con y sin reforzamiento',
  },
  {
    value: 'Fabricación de productos eléctricos de carbón y grafito',
  },
  {
    value: 'Fabricación de productos metálicos forjados y troquelados',
  },
  {
    value: 'Fabricación de productos para embalaje y envases de madera',
  },
  {
    value: 'Fabricación de productos refractarios',
  },
  {
    value: 'Fabricación de pulpa',
  },
  {
    value: 'Fabricación de redes y otros productos de cordelería',
  },
  {
    value: 'Fabricación de relojes',
  },
  {
    value: 'Fabricación de resinas sintéticas',
  },
  {
    value: 'Fabricación de ropa exterior de tejido de punto',
  },
  {
    value: 'Fabricación de ropa interior de tejido de punto',
  },
  {
    value: 'Fabricación de telas anchas de tejido de trama',
  },
  {
    value: 'Fabricación de telas angostas de tejido de trama y pasamanería',
  },
  {
    value: 'Fabricación de telas de tejido de punto',
  },
  {
    value: 'Fabricación de telas no tejidas (comprimidas)',
  },
  {
    value: 'Fabricación de telas recubiertas',
  },
  {
    value: 'Fabricación de tintas para impresión',
  },
  {
    value: 'Fabricación de tornillos, tuercas, remaches y similares',
  },
  {
    value: 'Fabricación de tubería y conexiones, y tubos para embalaje',
  },
  {
    value: 'Fabricación de tubos y bloques de cemento y concreto',
  },
  {
    value: 'Fabricación de utensilios de cocina metálicos',
  },
  {
    value: 'Fabricación de válvulas metálicas',
  },
  {
    value: 'Fabricación de velas y veladoras',
  },
  {
    value: 'Fabricación de vidrio',
  },
  {
    value: 'Fabricación de yeso y productos de yeso',
  },
  {
    value: 'Fabricación y reproducción de medios magnéticos y ópticos',
  },
  {
    value: 'Farmacias con minisúper',
  },
  {
    value: 'Farmacias sin minisúper',
  },
  {
    value: 'Floricultura a cielo abierto',
  },
  {
    value: 'Floricultura en invernadero',
  },
  {
    value: 'Fondos de aseguramiento campesino',
  },
  {
    value: 'Fundición y refinación de cobre',
  },
  {
    value: 'Fundición y refinación de metales preciosos',
  },
  {
    value: 'Fundición y refinación de otros metales no ferrosos',
  },
  {
    value: 'Grabación de discos compactos (CD) y de video digital (DVD) o casetes musicales',
  },
  {
    value: 'Guarderías del sector privado',
  },
  {
    value: 'Hojalatería y pintura de automóviles y camiones',
  },
  {
    value: 'Hoteles con otros servicios integrados',
  },
  {
    value: 'Hoteles sin otros servicios integrados',
  },
  {
    value: 'Impresión de formas continuas y otros impresos',
  },
  {
    value: 'Impresión de libros, periódicos y revistas',
  },
  {
    value: 'Industria básica del aluminio',
  },
  {
    value: 'Industrias conexas a la impresión',
  },
  {
    value: 'Instalación de cristales y otras reparaciones a la carrocería de automóviles y camiones',
  },
  {
    value: 'Instalación de señalamientos y protecciones en obras viales',
  },
  {
    value: 'Instalaciones de sistemas centrales de aire acondicionado y calefacción',
  },
  {
    value: 'Instalaciones eléctricas en construcciones',
  },
  {
    value: 'Instalaciones hidrosanitarias y de gas',
  },
  {
    value: 'Jardines botánicos y zoológicos del sector privado',
  },
  {
    value: 'Joyería de metales y piedras no preciosos y de otros materiales',
  },
  {
    value: 'Laboratorios médicos y de diagnóstico del sector privado',
  },
  {
    value: 'Laminación secundaria de cobre',
  },
  {
    value: 'Laminación secundaria de otros metales no ferrosos',
  },
  {
    value: 'Lavado y lubricado de automóviles y camiones',
  },
  {
    value: 'Lavanderías y tintorerías',
  },
  {
    value: 'Manejo de desechos no peligrosos y servicios de remediación a zonas dañadas por desechos no peligrosos',
  },
  {
    value: 'Maquinado de piezas metálicas para maquinaria y equipo en general',
  },
  {
    value: 'Matanza de ganado, aves y otros animales comestibles',
  },
  {
    value: 'Metalistería de metales no preciosos',
  },
  {
    value: 'Minería de arena y grava para la construcción',
  },
  {
    value: 'Minería de azufre',
  },
  {
    value: 'Minería de barita',
  },
  {
    value: 'Minería de caolín',
  },
  {
    value: 'Minería de carbón mineral',
  },
  {
    value: 'Minería de cobre',
  },
  {
    value: 'Minería de feldespato',
  },
  {
    value: 'Minería de fluorita',
  },
  {
    value: 'Minería de grafito',
  },
  {
    value: 'Minería de hierro',
  },
  {
    value: 'Minería de manganeso',
  },
  {
    value: 'Minería de mármol',
  },
  {
    value: 'Minería de mercurio y antimonio',
  },
  {
    value: 'Minería de minerales no metálicos para productos químicos',
  },
  {
    value: 'Minería de oro',
  },
  {
    value: 'Minería de otras arcillas y de otros minerales refractarios',
  },
  {
    value: 'Minería de otras piedras dimensionadas',
  },
  {
    value: 'Minería de otros minerales metálicos',
  },
  {
    value: 'Minería de otros minerales no metálicos',
  },
  {
    value: 'Minería de piedra caliza',
  },
  {
    value: 'Minería de piedra de yeso',
  },
  {
    value: 'Minería de plata',
  },
  {
    value: 'Minería de plomo y zinc',
  },
  {
    value: 'Minería de roca fosfórica',
  },
  {
    value: 'Minería de sal',
  },
  {
    value: 'Minería de sílice',
  },
  {
    value: 'Minería de tezontle y tepetate',
  },
  {
    value: 'Moldeo por fundición de piezas de hierro y acero',
  },
  {
    value: 'Moldeo por fundición de piezas metálicas no ferrosas',
  },
  {
    value: 'Montaje de estructuras de acero prefabricadas',
  },
  {
    value: 'Montaje de estructuras de concreto prefabricadas',
  },
  {
    value: 'Museos del sector privado',
  },
  {
    value: 'Orfebrería y joyería de metales y piedras preciosos',
  },
  {
    value: 'Organización de excursiones y paquetes turísticos para agencias de viajes',
  },
  {
    value: 'Otras compañías y grupos de espectáculos artísticos del sector privado',
  },
  {
    value: 'Otras construcciones de ingeniería civil',
  },
  {
    value: 'Otras industrias manufactureras',
  },
  {
    value: 'Otras instalaciones y equipamiento en construcciones',
  },
  {
    value: 'Otras reparaciones mecánicas de automóviles y camiones',
  },
  {
    value: 'Otro autotransporte foráneo de carga especializado',
  },
  {
    value: 'Otro autotransporte local de carga especializado',
  },
  {
    value: 'Otro transporte terrestre de pasajeros',
  },
  {
    value: 'Otro transporte turístico',
  },
  {
    value: 'Otros cultivos',
  },
  {
    value: 'Otros cultivos no alimenticios en invernaderos y viveros',
  },
  {
    value: 'Otros servicios de almacenamiento con instalaciones especializadas',
  },
  {
    value: 'Otros servicios de consultoría científica y técnica',
  },
  {
    value: 'Otros servicios de grabación del sonido',
  },
  {
    value: 'Otros servicios de limpieza',
  },
  {
    value: 'Otros servicios de reparación y mantenimiento de automóviles y camiones',
  },
  {
    value: 'Otros servicios personales',
  },
  {
    value: 'Otros servicios recreativos prestados por el sector privado',
  },
  {
    value: 'Otros servicios relacionados con el transporte por agua',
  },
  {
    value: 'Otros servicios relacionados con la agricultura',
  },
  {
    value: 'Otros trabajos de acabados en edificaciones',
  },
  {
    value: 'Otros trabajos en exteriores',
  },
  {
    value: 'Otros trabajos especializados para la construcción',
  },
  {
    value: 'Panificación industrial',
  },
  {
    value: 'Panificación tradicional',
  },
  {
    value: 'Parques acuáticos y balnearios del sector privado',
  },
  {
    value: 'Parques de diversiones y temáticos del sector privado',
  },
  {
    value: 'Pesca de camarón',
  },
  {
    value: 'Pesca de sardina y anchoveta',
  },
  {
    value: 'Pesca de túnidos',
  },
  {
    value: 'Pesca y captura de otros peces, crustáceos, moluscos y otras especies',
  },
  {
    value: 'Piscicultura y otra acuicultura, excepto camaronicultura',
  },
  {
    value: 'Preparación de embutidos y otras conservas de carne de ganado, aves y otros animales comestibles',
  },
  {
    value: 'Preparación de terrenos para la construcción',
  },
  {
    value: 'Preparación e hilado de fibras blandas naturales',
  },
  {
    value: 'Preparación e hilado de fibras duras naturales',
  },
  {
    value: 'Preparación y envasado de pescados y mariscos',
  },
  {
    value: 'Preparación y envasado de té',
  },
  {
    value: 'Procesamiento electrónico de información, hospedaje y otros servicios relacionados',
  },
  {
    value: 'Producción de aves en incubadora',
  },
  {
    value: 'Producción de material discográfico integrada con su reproducción y distribución',
  },
  {
    value: 'Producción de películas',
  },
  {
    value: 'Producción de programas para la televisión',
  },
  {
    value: 'Producción de videoclips, comerciales y otros materiales audiovisuales',
  },
  {
    value: 'Productoras discográficas',
  },
  {
    value: 'Purificación y embotellado de agua',
  },
  {
    value: 'Realización de trabajos de carpintería en el lugar de la construcción',
  },
  {
    value: 'Rectificación de partes de motor de automóviles y camiones',
  },
  {
    value: 'Recubrimientos y terminados metálicos',
  },
  {
    value: 'Refugios temporales comunitarios del sector privado',
  },
  {
    value: 'Reparación de calzado y otros artículos de piel y cuero',
  },
  {
    value: 'Reparación de suspensiones de automóviles y camiones',
  },
  {
    value: 'Reparación de tapicería de muebles para el hogar',
  },
  {
    value: 'Reparación de transmisiones de automóviles y camiones',
  },
  {
    value: 'Reparación del sistema eléctrico de automóviles y camiones',
  },
  {
    value: 'Reparación mecánica en general de automóviles y camiones',
  },
  {
    value: 'Reparación menor de llantas',
  },
  {
    value: 'Reparación y mantenimiento de aparatos eléctricos para el hogar y personales',
  },
  {
    value: 'Reparación y mantenimiento de bicicletas',
  },
  {
    value: 'Reparación y mantenimiento de equipo electrónico de uso doméstico',
  },
  {
    value: 'Reparación y mantenimiento de maquinaria y equipo agropecuario y forestal',
  },
  {
    value: 'Reparación y mantenimiento de maquinaria y equipo comercial y de servicios',
  },
  {
    value: 'Reparación y mantenimiento de maquinaria y equipo industrial',
  },
  {
    value: 'Reparación y mantenimiento de maquinaria y equipo para mover, levantar y acomodar materiales',
  },
  {
    value: 'Reparación y mantenimiento de motocicletas',
  },
  {
    value: 'Reparación y mantenimiento de otro equipo electrónico y de equipo de precisión',
  },
  {
    value: 'Reparación y mantenimiento de otros artículos para el hogar y personales',
  },
  {
    value: 'Restaurantes con servicio de preparación de alimentos a la carta o de comida corrida',
  },
  {
    value: 'Restaurantes con servicio de preparación de antojitos',
  },
  {
    value: 'Restaurantes con servicio de preparación de pescados y mariscos',
  },
  {
    value: 'Restaurantes con servicio de preparación de pizzas, hamburguesas, hot dogs y pollos rostizados para llevar',
  },
  {
    value: 'Restaurantes con servicio de preparación de tacos y tortas',
  },
  {
    value: 'Restaurantes de autoservicio',
  },
  {
    value: 'Restaurantes que preparan otro tipo de alimentos para llevar',
  },
  {
    value: 'Revitalización de llantas',
  },
  {
    value: 'Salones y clínicas de belleza y peluquerías',
  },
  {
    value: 'Sanitarios públicos y bolerías',
  },
  {
    value: 'Servicios de acceso a computadoras',
  },
  {
    value: 'Servicios de alimentación comunitarios prestados por el sector privado',
  },
  {
    value: 'Servicios de arquitectura',
  },
  {
    value: 'Servicios de arquitectura de paisaje y urbanismo',
  },
  {
    value: 'Servicios de capacitación para el trabajo prestados por el sector privado para personas desempleadas, subempleadas o discapacitadas',
  },
  {
    value: 'Servicios de comedor para empresas e instituciones',
  },
  {
    value: 'Servicios de consultoría en medio ambiente',
  },
  {
    value: 'Servicios de dibujo',
  },
  {
    value: 'Servicios de diseño de sistemas de cómputo y servicios relacionados',
  },
  {
    value: 'Servicios de emergencia comunitarios prestados por el sector privado',
  },
  {
    value: 'Servicios de empacado y etiquetado',
  },
  {
    value: 'Servicios de fotocopiado, fax y afines',
  },
  {
    value: 'Servicios de fotografía y videograbación',
  },
  {
    value: 'Servicios de fumigación agrícola',
  },
  {
    value: 'Servicios de ingeniería',
  },
  {
    value: 'Servicios de instalación y mantenimiento de áreas verdes',
  },
  {
    value: 'Servicios de investigación científica y desarrollo en ciencias sociales y humanidades, prestados por el sector privado',
  },
  {
    value: 'Servicios de limpieza de tapicería, alfombras y muebles',
  },
  {
    value: 'Servicios de mensajería y paquetería foránea',
  },
  {
    value: 'Servicios de mensajería y paquetería local',
  },
  {
    value: 'Servicios de mudanzas',
  },
  {
    value: 'Servicios de postproducción y otros servicios para la industria fílmica y del video',
  },
  {
    value: 'Servicios de preparación de alimentos en unidades móviles',
  },
  {
    value: 'Servicios de preparación de alimentos para ocasiones especiales',
  },
  {
    value: 'Servicios de preparación de documentos',
  },
  {
    value: 'Servicios de preparación de otros alimentos para consumo inmediato',
  },
  {
    value: 'Servicios de revelado e impresión de fotografías',
  },
  {
    value: 'Servicios de rotulación y otros servicios de publicidad',
  },
  {
    value: 'Servicios de traducción e interpretación',
  },
  {
    value: 'Servicios funerarios',
  },
  {
    value: 'Servicios relacionados con el aprovechamiento forestal',
  },
  {
    value: 'Servicios relacionados con la cría y explotación de animales',
  },
  {
    value: 'Servicios veterinarios para la ganadería prestados por el sector privado',
  },
  {
    value: 'Servicios veterinarios para mascotas prestados por el sector privado',
  },
  {
    value: 'Silvicultura',
  },
  {
    value: 'Sociedades financieras de objeto limitado',
  },
  {
    value: 'Sociedades financieras de objeto múltiple',
  },
  {
    value: 'Supervisión de división de terrenos y de construcción de obras de urbanización',
  },
  {
    value: 'Supervisión de edificación de inmuebles comerciales y de servicios',
  },
  {
    value: 'Supervisión de edificación de naves y plantas industriales',
  },
  {
    value: 'Supervisión de edificación residencial',
  },
  {
    value: 'Tala de árboles',
  },
  {
    value: 'Tapicería de automóviles y camiones',
  },
  {
    value: 'Trabajos de albañilería',
  },
  {
    value: 'Trabajos de cimentaciones',
  },
  {
    value: 'Trabajos de enyesado, empastado y tiroleado',
  },
  {
    value: 'Trabajos de pintura y otros cubrimientos de paredes',
  },
  {
    value: 'Transporte escolar y de personal',
  },
  {
    value: 'Transporte turístico por agua',
  },
  {
    value: 'Transporte turístico por tierra',
  },
  {
    value: 'Tratamiento de la madera y fabricación de postes y durmientes',
  },
  {
    value: 'Viveros forestales',
  },
];

export default actividades;

import React from "react";
import { CommissionItem } from "./CommissionItem";

export const Tab2 = ({ data }) => {
  return (
    <div className="minHe50vh">
      {data.map(item => (
        <CommissionItem key={item.icon} item={item} />
      ))}
    </div>
  );
};
